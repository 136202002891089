import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as FlagPL} from 'assets/flags/pl.svg';
import {ReactComponent as FlagGB} from 'assets/flags/gb.svg';
import {ReactComponent as FlagUA} from 'assets/flags/ua.svg';
// import {ReactComponent as FlagDE} from 'assets/flags/de.svg';
// import {ReactComponent as FlagUK} from 'assets/flags/uk.svg';
import {ReactComponent as ExpandIcon} from 'assets/expandIcon.svg';
import {ReactComponent as ConfirmIcon} from 'assets/checkIcon.svg';
import S from './SelectLanguage.module.scss';
import {useWindowResize} from 'common/hooks/screen';

interface Props {
  setExpandLanguage: (e: boolean) => void;
  expandLanguage: boolean;
}

const SelectLanguage: FC<Props> = ({setExpandLanguage, expandLanguage}) => {
  const {i18n, t} = useTranslation();
  const {isMobile} = useWindowResize();
  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang, () => {
      setExpandLanguage(false);
    });
  };
  //TODO refactor to be rendered from array of objects,
  //TODO aria-label translations say change language for polish and ukrainian and change to english for english
  return (
    <div className={isMobile ? `${S.menu_lang_box}` : S.menu_lang_box}>
      <button onClick={() => setExpandLanguage(!expandLanguage)} className={S.menu_langBtn} aria-label={t('29224')}>
        {i18n.resolvedLanguage === 'pl' && (
          <div className={S.menu_lang_Iconbox}>
            <FlagPL />
            {!isMobile && <span className={S.menu_text}>PL</span>}
          </div>
        )}
        {i18n.resolvedLanguage === 'en' && (
          <div className={S.menu_lang_Iconbox}>
            <FlagGB />
            {!isMobile && <span className={S.menu_text}>EN</span>}
          </div>
        )}
        {i18n.resolvedLanguage === 'ua' && (
          <div className={S.menu_lang_Iconbox}>
            <FlagUA />
            {!isMobile && <span className={S.menu_text}>UA</span>}
          </div>
        )}
        <ExpandIcon
          fill="rgba(20, 36, 88, 1)"
          className={`${S.menu_langBtnIcon} ${expandLanguage ? S.menu_langBtnIcon_open : ''}`}
        />
      </button>
      <ul
        className={`
          ${S.menu_lang_select} 
          ${isMobile ? S.mobileDropdown : ''} 
          ${expandLanguage ? S.menu_lang_selectOpen : ''}
        `}
      >
        <li>
          <button
            className={`${S.menu_button} ${S.menu_button__inactive} ${S.flex_box} ${S.small_gap}`}
            onClick={() => handleLanguageChange('pl')}
            aria-label={t('10005')}
          >
            {isMobile && i18n.resolvedLanguage === 'pl' ? <ConfirmIcon /> : null}
            <FlagPL />
            <span className={S.langSelector__text}>{isMobile ? 'Polski' : 'PL'}</span>
          </button>
        </li>
        <li>
          <button
            className={`${S.menu_button} ${S.menu_button__inactive} ${S.flex_box} ${S.small_gap}`}
            onClick={() => {
              handleLanguageChange('en');
            }}
            aria-label={t('29225')}
          >
            {isMobile && i18n.resolvedLanguage === 'en' ? <ConfirmIcon /> : null}
            <FlagGB />
            <span className={S.langSelector__text}>{isMobile ? 'English' : 'EN'}</span>
          </button>
        </li>
        <li>
          <button
            className={`${S.menu_button} ${S.menu_button__inactive} ${S.flex_box} ${S.small_gap}`}
            onClick={() => {
              handleLanguageChange('ua');
            }}
            aria-label={t('10005')}
          >
            {isMobile && i18n.resolvedLanguage === 'ua' ? <ConfirmIcon /> : null}
            <FlagUA />
            <span className={S.langSelector__text}>{isMobile ? 'Українська' : 'UA'}</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SelectLanguage;
