import {IAmenities} from 'api/pociagi/interfaces';

export enum AmenitiesActions {
  SET_AMENITIES = 'SET_AMENITIES',
  SET_SHOW_MINIMUM_TIME_FILTER = 'SET_SHOW_MINIMUM_TIME_FILTER',
  SET_MINIMUM_TRANSFER_TIME = 'SET_MINIMUM_TRANSFER_TIME',
}

export type Action =
  | {type: AmenitiesActions.SET_AMENITIES; payload: IAmenities | null}
  | {type: AmenitiesActions.SET_SHOW_MINIMUM_TIME_FILTER; payload: boolean}
  | {type: AmenitiesActions.SET_MINIMUM_TRANSFER_TIME; payload: number};

export interface AmenitiesState {
  contextAmenities: IAmenities | null;
  showMinimumTimeFilter: boolean;
  minimumTransferTotalMinutes: number;
}
