import {env} from 'env';
import api from '../axios-middleware';

import {IreturnTicketResponse, ISezonalTicketRoute, ISezonalTicketRouteResponse} from './interfaces';

import {getToken} from 'Utils/auth';

const methods = {
  sezonalTicketRoute: async (data: ISezonalTicketRoute) => {
    const token = await getToken();
    return await api.postExt<ISezonalTicketRouteResponse>(
      '/server/public/endpoint/Pociagi',
      {
        ...data,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzTrasePrzejazduDlaBiletuOkresowego',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  returnSezonalTicket: async (transakcjaNr: number) => {
    const token = await getToken();
    return await api.postExt<IreturnTicketResponse>(
      '/server/private/endpoint/Sprzedaz',
      {
        transakcjaNr,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'zwrocBiletOkresowy',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
};

export default methods;
