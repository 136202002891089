import {FC, useState, useContext, useEffect} from 'react';

import {useStyledThemeState} from 'common/theme';
import {UserTicketsContext} from 'Utils/UserTicketsContext';
import ConfirmButton from 'components/common/ConfirmButton/ConfirmButton';
import ReturnTicket from '../MyTickets/ReturnTicket/ReturnTicket';

import S from './ReturnTicketMobileFlow.module.scss';

import Doggo from 'assets/AdditionalTickets/pet.svg';
import Luggage from 'assets/AdditionalTickets/luggage.svg';
import Bicycle from 'assets/AdditionalTickets/bicycle.svg';
import DoggoContrast from 'assets/AdditionalTickets/petContrast.svg';
import LuggageContrast from 'assets/AdditionalTickets/luggageContrast.svg';
import BicycleContrast from 'assets/AdditionalTickets/bicycleContrast.svg';
import ticketIcon from 'assets/ticket.svg';
import ticketContrasIcon from 'assets/contrastTicket.svg';
import CloseX from 'assets/orangeX.svg';
import ContrastCloseX from 'assets/contrastX.svg';
import {IDownloadTicketResponseSingleTicket, ISingleId} from 'api/sprzedaz/interfaces';
import {useTranslation} from 'react-i18next';
import {isAdditionalTicketKind, isPrimaryTicketKind} from 'common/pages/UserProfile/MyTickets';

interface Props {
  tickets: IDownloadTicketResponseSingleTicket[];
  mainTicketIdentifier: ISingleId;
  isSeasonal: boolean | undefined;
  closeFn: () => void;
}

interface SingleTicketProps {
  index: number;
  isActive: boolean;
  descUpdateFn: (e: string) => void;
  selectIndexFn: (e: number) => void;
  ticketData: {
    image: string;
    text: string;
    actText: string;
  };
}

const SingleTicketFrame: FC<SingleTicketProps> = ({index, isActive, descUpdateFn, selectIndexFn, ticketData}) => {
  return (
    <button
      className={`${S.SingleTicketFrame} ${isActive ? S.SingleTicketFrame_active : ''}`}
      onClick={() => {
        descUpdateFn(ticketData.actText);
        selectIndexFn(index);
      }}
    >
      <img src={ticketData.image} className={S.SingleTicketFrame_image} alt={ticketData.text} />
      <span>{ticketData.text}</span>
    </button>
  );
};

const ReturnTicketMobileFlow: FC<Props> = ({tickets, mainTicketIdentifier, isSeasonal, closeFn}) => {
  const {t} = useTranslation();
  const {returnedTicketData, setReturnedTicketData} = useContext(UserTicketsContext);
  const {isContrastTheme} = useStyledThemeState();

  const primaryTickets = tickets?.filter((el) => isPrimaryTicketKind(el.biletRodzaj)) ?? [];
  const additionalTickets = tickets?.filter((el) => isAdditionalTicketKind(el.biletRodzaj)) ?? [];
  const firstPrimaryAndAdditionalTickets = [primaryTickets[0], ...additionalTickets];

  const returnTicketTypeData = (type: number) => {
    if (type === 21 || type === 22) {
      return {
        image: isContrastTheme ? DoggoContrast : Doggo,
        text: `${type === 22 ? t('29431') : t('29430')}`,
        actText: `${type === 22 ? t('29433') : t('29431')}`,
      };
    } else if (type === 23) {
      return {
        image: isContrastTheme ? LuggageContrast : Luggage,
        text: t('29424'),
        actText: t('29425'),
      };
    } else if (isPrimaryTicketKind(type)) {
      return {
        image: isContrastTheme ? ticketContrasIcon : ticketIcon,
        text: t('29426'),
        actText: t('29427'),
      };
    } else if (type === 20) {
      return {
        image: isContrastTheme ? BicycleContrast : Bicycle,
        text: t('29428'),
        actText: t('29429'),
      };
    }
  };

  const [actDescription, setActDescription] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  useEffect(() => {
    if (additionalTickets.length === 0) {
      setReturnedTicketData({
        ticketSr: tickets[0].biletSeria,
        ticket: {
          bilety: tickets,
          bledy: [],
        },
        mainTicketIdentifier: mainTicketIdentifier,
        seazonalTicket: isSeasonal,
      });
      closeFn();
    }
  }, []);
  return (
    <dialog className={S.ReturnTicketMobileFlow} aria-labelledby="modal-heading">
      <div className={S.ReturnTicketMobileFlow_wrapper}>
        <span className={S.hidden} aria-hidden="true">
          Modal wyboru rodzaju biletu do zwrotu
        </span>
        <span className={S.hidden} role="alert">
          {actDescription}
        </span>
        <div className={S.ReturnTicketMobileFlow_header}>
          <h1 className={S.ReturnTicketMobileFlow_title}>{t('13098')}</h1>
          <button onClick={closeFn} aria-label={t('11036')} className={S.ReturnTicketMobileFlow_closeButton}>
            <img src={isContrastTheme ? ContrastCloseX : CloseX} alt={t('24006')} />
          </button>
        </div>
        {firstPrimaryAndAdditionalTickets
          .filter((el) => !el.zwrotOpis)
          .map((el, index) => {
            return (
              <SingleTicketFrame
                index={index}
                isActive={index === selectedIndex}
                selectIndexFn={() => {
                  setSelectedIndex(index);
                }}
                descUpdateFn={setActDescription}
                key={el.biletNr.toString()}
                ticketData={returnTicketTypeData(el.biletRodzaj)!}
              />
            );
          })}
      </div>
      <ConfirmButton
        text={t('29177')}
        helperText={t('29434')}
        disabled={typeof selectedIndex !== 'number'}
        execute={() => {
          if (typeof selectedIndex === 'number') {
            const filteredTickets = tickets.filter((ticket) => !ticket.zwrotData);
            setReturnedTicketData({
              ticketSr: tickets[selectedIndex].biletSeria,
              ticket: {
                bilety: selectedIndex !== 0 ? [filteredTickets[selectedIndex]] : [...tickets],
                bledy: [],
              },
              mainTicketIdentifier: mainTicketIdentifier,
              seazonalTicket: isSeasonal,
            });
          }
        }}
      />

      {returnedTicketData && window.innerWidth < 800 && <ReturnTicket isMobile />}
    </dialog>
  );
};

export default ReturnTicketMobileFlow;
