declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any;
  }
}

type EnvType = {
  REACT_APP_MAIN_API_URL: string;
  REACT_APP_SSO_SERVER: string;
  REACT_APP_DEVICE_NUMBER: number;
  REACT_APP_NEW_TZB_DATE: string;
  REACT_APP_TZB_REQUIRED_PRE_SALE_DAYS: string;
  REACT_APP_GENERATE_SOURCEMAP: boolean;
  REACT_APP_GTM_ID: string;
  REACT_APP_BIKE_TICKET_PRICE: number;
  REACT_APP_DOG_TICKET_PRICE: number;
  REACT_APP_LUGGAGE_TICKET_PRICE: number;
  REACT_APP_TRANSLATIONS_API: string;
  REACT_APP_ECO_OFFSET_START: string;
  REACT_APP_ENVIRONMENT_CLIENT_ID: string;
  REACT_APP_ENVIRONMENT_GUEST_CLIENT_ID: string;
};

export const env: EnvType = {...process.env, ...window.env};
