import {isForeignStationByCodeEVA} from './station';

export const isForeignConnection = (
  fromEVACode: string | number,
  destinationEVACode: string | number,
  viaStations: (string | number)[] = [],
): boolean =>
  isForeignStationByCodeEVA(fromEVACode) ||
  isForeignStationByCodeEVA(destinationEVACode) ||
  viaStations.some((code): boolean => isForeignStationByCodeEVA(code));
