import {useState, useEffect} from 'react';

interface UseMinimumTransferTimeProps {
  initialMinutes: number;
  onChange: (minutes: number) => void;
}

export const useMinimumTransferTime = ({initialMinutes, onChange}: UseMinimumTransferTimeProps) => {
  const SECONDS = {
    MAXIMUM: 1439,
    MINIMUM: 3,
  };
  const [totalMinutes, setTotalMinutes] = useState<number>(initialMinutes || SECONDS.MINIMUM);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(SECONDS.MINIMUM);

  useEffect(() => {
    onChange(totalMinutes);
  }, [totalMinutes, onChange]);

  useEffect(() => {
    if (totalMinutes > SECONDS.MAXIMUM) setTotalMinutes(SECONDS.MAXIMUM);
    if (totalMinutes < SECONDS.MINIMUM) setTotalMinutes(SECONDS.MINIMUM);

    setHours(Math.floor(totalMinutes / 60));
    setMinutes(totalMinutes % 60);
  }, [totalMinutes]);

  const handleMinutesInput = (minutes: number) => {
    setMinutes(minutes);
    setTotalMinutes(hours * 60 + minutes);
  };

  const handleHoursInput = (hours: number) => {
    setHours(hours);
    setTotalMinutes(hours * 60 + minutes);
  };

  const subtractTime = () => {
    switch (true) {
      case totalMinutes <= 5:
        setTotalMinutes(SECONDS.MINIMUM);
        break;

      case totalMinutes <= 10:
        setTotalMinutes(5);
        break;

      case totalMinutes % 0 === 0:
        setTotalMinutes(totalMinutes - 10);
        break;

      default:
        setTotalMinutes(Math.floor(totalMinutes / 10) * 10);
        break;
    }
  };

  const addTime = () => {
    switch (true) {
      case totalMinutes < 5:
        setTotalMinutes(5);
        break;

      case totalMinutes % 10 === 0:
        setTotalMinutes(totalMinutes + 10);
        break;

      default:
        setTotalMinutes(Math.ceil(totalMinutes / 10) * 10);
        break;
    }
  };
  return {
    totalMinutes,
    hours,
    minutes,
    handleMinutesInput,
    handleHoursInput,
    subtractTime,
    addTime,
    isAddButtonDisabled: totalMinutes === SECONDS.MAXIMUM,
    isSubtractButtonDisabled: totalMinutes === SECONDS.MINIMUM,
  };
};
