import {z} from 'zod';

// the first two characters of the EVA code indicate country of the station
const EVACountriesSchema = z
  .record(
    z
      .string()
      .regex(/^([0-9]*)$/)
      .length(2),
    z
      .string()
      .regex(/^([^0-9 ]*)$/)
      .min(1)
      .max(3),
  )
  .readonly();

type EVACountries = z.infer<typeof EVACountriesSchema>;

export const EVA_COUNTRIES: EVACountries = {
  10: 'FIN',
  20: 'RUS',
  21: 'BY',
  22: 'UA',
  23: 'MD',
  24: 'LT',
  25: 'LV',
  26: 'EST',
  27: 'KZ',
  43: 'H',
  44: 'BIH',
  51: 'PL',
  52: 'BG',
  53: 'RO',
  54: 'CZ',
  55: 'H',
  56: 'SK',
  60: 'IRL',
  62: 'MNE',
  65: 'MK',
  70: 'GB',
  72: 'SRB',
  74: 'S',
  75: 'TR',
  76: 'N',
  78: 'HR',
  79: 'SLO',
  80: 'D',
  81: 'A',
  82: 'L',
  83: 'I',
  84: 'NL',
  85: 'CH',
  86: 'DK',
  87: 'F',
  88: 'B',
  94: 'P',
};

const EVACountriesCodesSchema = EVACountriesSchema.transform((codes) =>
  Object.keys(codes).map((keyCode: string) => keyCode),
);
type EVACountriesCodes = z.infer<typeof EVACountriesCodesSchema>;

export const EVACountriesCodesList: EVACountriesCodes = EVACountriesCodesSchema.parse(EVA_COUNTRIES);
