export class PaymentStatusArgs {
  paymentId: number;
  userName: string;
  requestStatusCounter: number;
  basketMode: boolean | undefined;

  constructor(paymentId: number, userName: string, requestStatusCounter: number, basketMode: boolean | undefined) {
    this.paymentId = paymentId;
    this.userName = userName;
    this.requestStatusCounter = requestStatusCounter;
    this.basketMode = basketMode;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPaymentStatusArgs = (value: any): value is PaymentStatusArgs => {
  const unknownObjectKeys = typeof value === 'object' && Object.keys(value);
  return (
    unknownObjectKeys &&
    unknownObjectKeys.includes('paymentId') &&
    unknownObjectKeys.includes('userName') &&
    unknownObjectKeys.includes('requestStatusCounter')
  );
};
