import {styled} from '@mui/material';
export const InputWrapper = styled('div')<{isMobile?: boolean}>`
  display: flex;
  align-items: center;
  margin-top: ${({isMobile}) => (isMobile ? '3.2rem' : '1.6rem')};
  justify-content: ${({isMobile}) => (isMobile ? 'center' : 'flex-start')};
`;
export const TimeInput = styled('input')`
  text-align: center;
  outline: none;
  border: none;
  color: ${({theme}) => theme.color.text.primary};
  width: 2.5rem;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  background: none;
  padding: 0;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Separator = styled('span')`
  margin: 0 0.8rem 0;
  color: ${({theme}) => theme.color.main.default};
`;
export const TimeManipulationButton = styled('button')<{isDisabled?: boolean}>`
  cursor: pointer;
  width: 5.6rem;
  height: 5.6rem;
  background: none;
  outline: none;
  border: ${({theme}) => `1px solid ${theme.color.border.primary}`};
  border-radius: 0.4rem;
  font-size: 2rem;
  color: ${({theme, isDisabled}) => (isDisabled ? theme.color.inactive : theme.color.accent.light)};
  font-weight: 700;
  &:first-of-type {
    margin-right: 1.6rem;
  }
  &:last-of-type {
    margin-left: 1.6rem;
  }
`;
