import {useTranslation} from 'react-i18next';

import {XButton} from 'common/components/feature/Buttons';
import ConfirmButton from 'components/common/ConfirmButton';
import {Text} from 'common/components/base/Text';
import {TicketComponent} from 'common/pages/UserProfile/MyTickets/components/Ticket/TicketComponent';
import {EmptyInformation} from 'common/components/feature/EmptyInformation';
import {TicketsListPageLoader} from 'common/pages/UserProfile/MyTickets/components/MyTicketsListPageLoader/TicketsListPageLoader';
import {
  MyTicketsReservationListParams,
  useMyTicketsPageData,
  useMyTicketsReservationListData,
} from 'common/api/myTickets';
import {useFilterTickets} from 'common/pages/UserProfile/MyTickets/hooks/useFilterTickets';
import {ModalContainer} from 'common/components/base/Modal';
import {SearchInput} from 'common/components/feature/SearchInput';
import {ModalHeader, ModalWrapper, TicketList} from 'common/components/feature/Modal';

interface ReservationListModalProps {
  onXClick: () => void;
  buttonText: string;
  onButtonClick: () => void;
  header?: string;
  ticketSerial: string;
  ticketNumber: number;
}

export const ReservationListModal = ({
  onXClick,
  buttonText,
  onButtonClick,
  header,
  ticketSerial,
  ticketNumber,
}: ReservationListModalProps) => {
  const {t} = useTranslation();

  const TICKETS_IN_REQUEST = 1000; // imposed by BE
  const {data: dataIdentifiers, isFetching: isFetchingIdentifiers} = useMyTicketsReservationListData(
    new MyTicketsReservationListParams(3, 5, 0, 0, TICKETS_IN_REQUEST, 1, ticketSerial, ticketNumber),
  );
  const {identyfikatory} = dataIdentifiers ?? {};
  const {data: dataSingleTickets, isFetching: isFetchingSingleTicket} = useMyTicketsPageData(identyfikatory);

  const {filteredList, isFilteredResultsEmpty, handleSearchChange, searchTerm} = useFilterTickets(
    dataSingleTickets,
    isFetchingSingleTicket,
  );

  const isEmptyList = identyfikatory?.length === 0 || isFilteredResultsEmpty;

  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalHeader>
          {header && <Text variant="H2">{header}</Text>}
          <XButton onClick={onXClick} />
        </ModalHeader>
        <SearchInput handleSearchChange={handleSearchChange} searchTerm={searchTerm} />
        <TicketList>
          {isFetchingIdentifiers || isFetchingSingleTicket ? (
            <TicketsListPageLoader isMobile={false} elementsLength={1} ticketVariant="SINGLE" />
          ) : isEmptyList ? (
            <EmptyInformation message={isFilteredResultsEmpty ? t('29414') : t('29413')} />
          ) : (
            filteredList?.map((ticket) => {
              const identifier = identyfikatory?.find((identifierTicket) =>
                ticket?.bilety.find(
                  ({biletNr, biletSeria}) =>
                    `${identifierTicket.biletSeria + identifierTicket.biletNr}` === `${biletSeria + biletNr}`,
                ),
              );
              return identifier && ticket ? (
                <TicketComponent
                  key={`${identifier.transakcjaNr}_${identifier.biletSeria}_${identifier.biletNr}`}
                  identifier={identifier}
                  tickets={ticket.bilety}
                  isHistoryVariant={0}
                  isMobile={false}
                  isReturned={identifier.zwrocony === 1}
                  isSeasonal={false}
                  variant="reservationListModal"
                />
              ) : null;
            })
          )}
        </TicketList>

        <ConfirmButton text={buttonText} execute={onButtonClick} />
      </ModalWrapper>
    </ModalContainer>
  );
};
