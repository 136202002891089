import {ticketSourceCodes} from 'common/api/myTickets/consts';
import {
  OFFER_CODES,
  SEASONAL_OFFER_CODES,
  SEASONAL_NON_SPECIFIED_ROUTE_OFFER_CODES,
  SEASONAL_ONE_WAY_OFFER_CODES,
  SEASONAL_PERIODIC_OFFER_CODES,
  SEASONAL_THERE_AND_BACK_OFFER_CODES,
  LIMITED_CONNECTION_DISCOUNT_CODES,
  UNAVAILABLE_TICKET_MESSAGE_CODES,
} from 'common/consts';
import {
  SeasonalOfferCode,
  SeasonalNonSpecifiedRouteOfferCode,
  SeasonalOneWayOfferCode,
  SeasonalPeriodicOfferCode,
  SeasonalThereAndBackOfferCode,
  LimitedConnectionsDiscountOfferCode,
  UnavailableTicketMessageCode,
} from 'common/types';

export const exhaustiveCheck = (param: never, type: string): never => {
  throw new TypeError(`${param} is not a proper ${type}`);
};

export const isZeroPriceOfferCode = (codeOffer: number) => codeOffer === OFFER_CODES.INTERCITY_CARD_RESERVATION;

export const isSeasonalOfferCode = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_OFFER_CODES).includes(codeOffer as SeasonalOfferCode);

export const isOneWaySeasonalOfferCode = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_ONE_WAY_OFFER_CODES).includes(codeOffer as SeasonalOneWayOfferCode);

export const isThereAndBackSeasonalOfferCode = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_THERE_AND_BACK_OFFER_CODES).includes(codeOffer as SeasonalThereAndBackOfferCode);

export const isNonSpecifiedRouteSeasonalOfferCode = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_NON_SPECIFIED_ROUTE_OFFER_CODES).includes(codeOffer as SeasonalNonSpecifiedRouteOfferCode);

export const isPeriodicSeasonalOfferCode = (codeOffer: number | undefined) =>
  Object.values(SEASONAL_PERIODIC_OFFER_CODES).includes(codeOffer as SeasonalPeriodicOfferCode);

export const isTicketSourceEIC1 = (ticketSerial: string | undefined) => ticketSerial === 'WE';

export const isTicketManuallyAdded = (ticketSourceCode: number | undefined, ticketSerial: string | undefined) =>
  ticketSourceCode === ticketSourceCodes.EIC && !isTicketSourceEIC1(ticketSerial); // solution imposed by IC https://jsoft.intercity.pl/browse/EIC2-1715

export const isLimitedConnectionsDiscountCode = (
  discountCode: number | undefined | null,
): discountCode is LimitedConnectionsDiscountOfferCode =>
  LIMITED_CONNECTION_DISCOUNT_CODES.includes(discountCode as LimitedConnectionsDiscountOfferCode);

export const isTicketAvailableDueToMessageCode = (messageCode: number) =>
  !UNAVAILABLE_TICKET_MESSAGE_CODES.includes(messageCode as UnavailableTicketMessageCode);
