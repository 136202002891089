import {ChangeEvent, FC, useState, useEffect} from 'react';
import BlueHeader from 'components/common/BlueHeader';
import IconHeader from 'components/common/IconHeader';
import UniversalHeader from 'components/common/UniversalHeader';
import ConfirmButton from 'components/common/ConfirmButton';

import {ReactComponent as Profile} from 'assets/profile.svg';
import {ReactComponent as ProfileIconContrast} from 'assets/profileContrastDesktop.svg';
import {ReactComponent as Magnifier} from 'assets/magnifier.svg';
import {ReactComponent as MagnifierContrast} from 'assets/magnifierContrast.svg';

import S from './DiscountDetailsModal.module.scss';
import {INewTicket} from 'api/sprzedaz/interfaces';
import {ActiveTicket, InitialCount} from '../../../../PurchasePathDesktop/SetDiscounts/SetDiscounts';
import DiscountList from '../../../../PurchasePathDesktop/SetDiscounts/DiscountList';
import {ShopDiscount} from 'common/api/localData';
import {getShopDiscountFromList} from 'Utils/commonFunctions';
import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';
import {
  filterDiscounts,
  handleCancelDiscounts,
  handleConfirmDiscounts,
} from '../../../../PurchasePathDesktop/SetDiscounts/SetDiscountsLogic';
import BlueInputText from 'components/common/BlueInputText/BlueInputText';
import {checkRefreshAndReturnJwt} from 'Utils/auth';
import {useTranslation} from 'react-i18next';

interface Props {
  setOpen: (e: boolean) => void;
  adultCount: INewTicket[];
  childrenCount: INewTicket[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeAdult: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeChildren: any;
  activeTicket: ActiveTicket;
  filteredDiscs: ShopDiscount[];
  mostPopularDiscs: ShopDiscount[];
  selectedDiscount: ShopDiscount | undefined;
  onSelectedDiscountChange(discount: ShopDiscount | null): void;
}

const DiscountDetailsModal: FC<Props> = ({
  setOpen,
  activeTicket,
  childrenCount,
  adultCount,
  changeAdult,
  changeChildren,
  filteredDiscs,
  mostPopularDiscs,
  selectedDiscount,
  onSelectedDiscountChange,
}) => {
  const {t} = useTranslation();

  const {isContrastTheme} = useStyledThemeState();
  const [initialCount, setInitialCount] = useState<InitialCount>({adultCount, childrenCount});
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<ShopDiscount[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {shopDiscounts} = useData();

  useEffect(() => {
    const checkToken = async () => {
      const token = await checkRefreshAndReturnJwt();
      if (token) {
        !isLoggedIn && setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };
    checkToken();
  }, []);

  const manageDiscounts = () => {
    let prevDiscount: ShopDiscount | undefined;
    if (activeTicket.isAdult) {
      prevDiscount = getShopDiscountFromList(initialCount.adultCount[activeTicket.index].discountCode, shopDiscounts);
    } else {
      prevDiscount = getShopDiscountFromList(
        initialCount.childrenCount[activeTicket.index].discountCode,
        shopDiscounts,
      );
    }
    prevDiscount && onSelectedDiscountChange(prevDiscount);
    setOpen(false);
  };

  const manageDiscountsConfirm = () => {
    setInitialCount({adultCount, childrenCount});
    setOpen(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchValue(value);
    if (value.length > 1) {
      const filtered = filterDiscounts(value, mostPopularDiscs);
      setSearchResults(filtered);
    }
    if (value.length === 0) {
      setSearchResults([]);
    }
  };

  return (
    <div className={S.curtain}>
      <div className={S.wrapper}>
        <UniversalHeader
          title={t('13058')}
          goBack={() => handleCancelDiscounts(initialCount, changeAdult, changeChildren, manageDiscounts)}
        />
        <IconHeader
          title={activeTicket.isAdult ? t('13015') : t('13016')}
          description=""
          icon={isContrastTheme ? <ProfileIconContrast /> : <Profile />}
        />
        <div className={S.popularsHeader}>
          <BlueHeader title="Najpopularniejsze" />
        </div>
        <div className={S.graySeparatorMobile} />
        <DiscountList
          list={filteredDiscs.length === 0 ? mostPopularDiscs.slice(0, 3) : filteredDiscs.slice(0, 3)}
          selectedDiscount={selectedDiscount}
          onDiscountChange={onSelectedDiscountChange}
          isUserLoggedIn={isLoggedIn}
        />
        <div className={S.allDiscountsHeader}>
          <BlueHeader title={t('29274')} />
          {isContrastTheme ? <MagnifierContrast /> : <Magnifier />}
        </div>
        <BlueInputText
          label={t('29173')}
          id="searchDiscountsMb"
          name="searchDiscountsMb"
          value={searchValue}
          onChange={handleInputChange}
        />
        <div className={S.allDiscList}>
          <div className={S.graySeparatorMobile} />
          {searchValue.length < 2 ? (
            <DiscountList
              list={filteredDiscs.length === 0 ? mostPopularDiscs.slice(3) : filteredDiscs.slice(3)}
              selectedDiscount={selectedDiscount}
              onDiscountChange={onSelectedDiscountChange}
              isUserLoggedIn={isLoggedIn}
            />
          ) : (
            <DiscountList
              list={searchResults}
              selectedDiscount={selectedDiscount}
              onDiscountChange={onSelectedDiscountChange}
              isUserLoggedIn={isLoggedIn}
            />
          )}
          {searchResults.length === 0 && searchValue.length > 1 && (
            <div className={S.blueAlertResults}> {t('13070')}</div>
          )}
        </div>
        <div className={S.confirmBtnWrapper}>
          <ConfirmButton text={t('29177')} execute={() => handleConfirmDiscounts(manageDiscountsConfirm)} />
        </div>
      </div>
    </div>
  );
};

export default DiscountDetailsModal;
