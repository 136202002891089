import {FC, useState} from 'react';

import {IGetTickePart, IGenerateTicketResponse, IDownloadTicketResponseSingleTicket} from 'api/sprzedaz/interfaces';
import {
  getFullTimeFromDate,
  getDayOfTheWeek,
  getFullDate,
  makeTimeOfTravel,
  returnCarrierLogo,
  returnStationByCode,
} from 'Utils/commonFunctions';
import {useStyledThemeState} from 'common/theme';
import {useData} from 'Utils/DataContex';

import style from './MyTicketsSingleTicketOpenMobile.module.scss';

import ArrowBottom from 'assets/orangeArrowBottomDesktop.svg';
import OrangeCircleSmall from 'assets/orangeCircleSmall.svg';
import OrangePinSmall from 'assets/orangePinSmall.svg';
import ArrowBottomContrast from 'assets/contrastArrowBottomDesktop.svg';
import ContrastCircleSmall from 'assets/contrastCircleSmall.svg';
import ContrastPinSmall from 'assets/contrastPinSmall.svg';
import {useTranslation} from 'react-i18next';
import {Text} from 'common/components/base/Text';
import Icon from 'common/components/base/Icon';
import {getPlaceTypeIcon} from 'Utils/commonFunctions';

interface Props {
  ticket: IGetTickePart;
  details?: IDownloadTicketResponseSingleTicket[];
  generateTicketDetails?: IGenerateTicketResponse;
  index: number;
  onCheckRouteClick: () => void;
}

const MyTicketsSingleTicketOpenMobileTripPart: FC<Props> = ({ticket, details, generateTicketDetails, index}) => {
  const {t} = useTranslation();

  const {theme, isContrastTheme} = useStyledThemeState();
  const [showTrip, setShowTrip] = useState<boolean>(false);
  const {stationsList} = useData();

  return (
    <>
      <div className={`${style.mobile_ticket__box}`}>
        <div className={`${style.mobile_ticket__box_textbox}`}>
          <div>
            <p className={style.text_blue} style={{marginBottom: '5px'}}>
              {t('11060')}
            </p>
            <p className={style.text_big}>{ticket.wyjazdData ? getFullTimeFromDate(ticket.wyjazdData) : '-'}</p>
          </div>
          <div>
            {ticket.wyjazdData && (
              <p className={style.text_small}>
                {getDayOfTheWeek(new Date(ticket.wyjazdData).getDay())}, {getFullDate(ticket.wyjazdData)}
              </p>
            )}
            <p className={style.text_medium}>{returnStationByCode(ticket.stacjaOdKod as number, stationsList)}</p>
          </div>
        </div>
        <div className={`${style.mobile_ticket__box_textbox}`}>
          <div>
            <p className={style.text_blue} style={{marginBottom: '5px'}}>
              {t('11061')}
            </p>
            <p className={style.text_big}>{ticket.przyjazdData ? getFullTimeFromDate(ticket.przyjazdData) : '-'}</p>
          </div>
          <div>
            {ticket.przyjazdData && (
              <p className={style.text_small}>
                {getDayOfTheWeek(new Date(ticket.przyjazdData).getDay())}, {getFullDate(ticket.przyjazdData)}
              </p>
            )}
            <p className={style.text_medium}>{returnStationByCode(ticket.stacjaDoKod as number, stationsList)}</p>
          </div>
        </div>
        <aside className={style.mobile_ticket__accordion}>
          <button onClick={() => setShowTrip(!showTrip)}>
            <span className={style.text_small}>{t('29421')}</span>
            <img
              src={isContrastTheme ? ArrowBottomContrast : ArrowBottom}
              alt={showTrip ? t('13026') : t('13027')}
              style={{transform: showTrip ? 'rotate(180deg)' : ''}}
            />
          </button>
        </aside>
        <div className={`${style.summary_container} ${showTrip ? style.summary_open : ''}`}>
          <p className={style.text_small}>{t('29422')}</p>
          <div className={style.partContainer}>
            {ticket.wyjazdData && <p className={style.text_blue}>{getFullTimeFromDate(ticket.wyjazdData)}</p>}
            <div className={style.partWrapper}>
              <div className={style.icon_box}>
                <img src={isContrastTheme ? ContrastCircleSmall : OrangeCircleSmall} alt={t('29080')} />
                <div />
                <img src={isContrastTheme ? ContrastPinSmall : OrangePinSmall} alt={t('29081')} />
              </div>
              <div className={style.train_box}>
                <div className={style.singleTicket__cityInfo}>
                  <p className={style.text_small}>{returnStationByCode(ticket.stacjaOdKod as number, stationsList)}</p>
                </div>
                <div className={style.trainData}>
                  {returnCarrierLogo(ticket.pociagKategoriaNazwa, theme)}
                  <p className={style.text_blue}>{ticket.pociagNr}</p>
                  <p className={style.text_blue}>{ticket.pociagNazwa}</p>
                </div>
                <div className={style.singleTicket__cityInfo}>
                  <p className={style.text_small}>{returnStationByCode(ticket.stacjaDoKod as number, stationsList)}</p>
                </div>
              </div>
            </div>
            {ticket.przyjazdData && <p className={style.text_blue}>{getFullTimeFromDate(ticket.przyjazdData)}</p>}
          </div>
        </div>
      </div>
      <div className={`${style.mobile_ticket__box}`}>
        <div className={`${style.mobile_ticket__box_textbox}`}>
          <div>
            <p className={style.text_blue} style={{marginBottom: '5px'}}>
              {t('29365')}
            </p>
            <p className={style.text_big}>{makeTimeOfTravel(ticket.wyjazdData, ticket.przyjazdData)}</p>
          </div>
          <div>
            <span>
              <span>
                <p className={style.text_blue}>{t('29423')}</p>
                <div className={style.trainData}>
                  {returnCarrierLogo(ticket.pociagKategoriaNazwa, theme)}
                  {ticket.pociagNr && <p className={style.train_details}>{ticket.pociagNr}</p>}
                </div>
                {ticket.stacjaDoKod && (
                  <Text variant="P" as="p">
                    {/* Jedzie do {returnStationByCode(ticket.stacjaDoKod as number, stationsList)} */}{' '}
                    {/*TODO waiting for BE to resolve this*/}
                    {ticket.pociagNazwa}
                  </Text>
                )}
              </span>
            </span>
          </div>
        </div>
        {details &&
          details[0]?.odcinki[index]?.miejsca.map((el) => {
            return (
              <div key={el.miejsceNr} className={style.placesContainer}>
                <div className={`${style.mobile_ticket__box_textbox}`} style={{marginBottom: '10px'}}>
                  <div>
                    <span>
                      <span>
                        <p className={style.text_blue}>{t('21001')}</p>
                        <p className={`${style.text_medium} ${style.wagonIconContainer}`}>
                          <Icon icon="wagon" />
                          {el.wagonNr}
                        </p>
                      </span>
                      <p className={style.text_small}>
                        {t('17058')} {details[0]?.odcinki[index].klasa}
                      </p>
                    </span>
                  </div>
                  <div style={{alignSelf: 'center', justifySelf: 'center'}}>
                    <p className={style.text_small}>{el.miejsceTypNazwa}</p>
                  </div>
                </div>
                <div className={`${style.mobile_ticket__box_textbox}`}>
                  <div>
                    <p className={style.text_blue} style={{marginBottom: '5px'}}>
                      {t('21035')}
                    </p>
                    <p className={style.text_big}>
                      {getPlaceTypeIcon(el.miejsceTypKod)}
                      <span>{el.miejsceNr}</span>
                    </p>
                  </div>
                  <div style={{alignSelf: 'center', justifySelf: 'center'}}>
                    <p className={style.text_small} style={{paddingTop: '18px'}}>
                      {el.usytowanieNazwa}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        {generateTicketDetails?.odcinki &&
          generateTicketDetails?.odcinki[index]?.miejsca.map((el) => {
            return (
              <div key={el.miejsceNr} className={style.placesContainer}>
                <div className={`${style.mobile_ticket__box_textbox}`} style={{marginBottom: '10px'}}>
                  <div>
                    <span>
                      <span>
                        <p className={style.text_blue} style={{marginBottom: '5px'}}>
                          {t('21001')}
                        </p>
                        <p className={style.text_medium}>
                          <Icon icon="wagon" />
                          {el.wagonNr}
                        </p>
                      </span>
                      <p className={style.text_small}>
                        {t('22012')} {generateTicketDetails?.odcinki[index].klasa}
                      </p>
                    </span>
                  </div>
                  <div style={{alignSelf: 'center', justifySelf: 'center'}}>
                    <p className={style.text_small}>{el.miejsceTypNazwa}</p>
                  </div>
                </div>
                <div className={`${style.mobile_ticket__box_textbox}`}>
                  <div>
                    <p className={style.text_blue} style={{marginBottom: '5px'}}>
                      {t('21035')}
                    </p>
                    <p className={style.text_big}>
                      {getPlaceTypeIcon(el.miejsceTypKod)}
                      <span>{el.miejsceNr}</span>
                    </p>
                  </div>
                  <div style={{alignSelf: 'center', justifySelf: 'center'}}>
                    <p className={style.text_small} style={{paddingTop: '18px'}}>
                      {el.usytowanieNazwa}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MyTicketsSingleTicketOpenMobileTripPart;
