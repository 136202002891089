/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {FC, useContext, useEffect, useState} from 'react';
import * as DOMPurify from 'dompurify';

import DiscountToggleDetail from './DiscountToggleDetail';
import {ShopDiscount} from 'common/api/localData';
import S from './SetDiscounts.module.scss';
import {useTranslation} from 'react-i18next';
import {discountListLanguageMapper} from 'Utils/commonFunctions';
import {SHOP_DISCOUNT_CODES} from 'common/consts';
import {ShopDiscountOfferCode} from 'common/types';
import {SearchContext} from 'Utils/SearchContext';
import {isDiscountActive} from './SetDiscountsLogic';
import {useValidateInactiveDiscount} from './SetDiscounts.hooks';

interface Props {
  list: ShopDiscount[];
  selectedDiscount: ShopDiscount | undefined | null;
  onDiscountChange: (discount: ShopDiscount | null) => void;
  isUserLoggedIn?: boolean;
  variant?: 'mobileBorder';
}

const DiscountList: FC<Props> = ({list, selectedDiscount, onDiscountChange, isUserLoggedIn, variant}) => {
  const {t, i18n} = useTranslation();
  const [languageNumber, setLanguageNumber] = useState(0);
  const {searchFormFrom} = useContext(SearchContext);

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const parse = require('html-react-parser');

  useValidateInactiveDiscount(selectedDiscount, onDiscountChange);

  const isDiscountDisabled = (discount: ShopDiscount) =>
    !isDiscountActive(discount, searchFormFrom?.date, searchFormFrom?.time);

  useEffect(() => {
    const selectedLang = discountListLanguageMapper(i18n.language);
    setLanguageNumber(selectedLang);
  }, [i18n.language]);
  const translations: Partial<Readonly<Record<ShopDiscountOfferCode, string>>> = {
    [SHOP_DISCOUNT_CODES.CHILD_37]: t('13099'),
    [SHOP_DISCOUNT_CODES.CHILD_49]: t('13103'),
  };

  return (
    <>
      {list.map((el) => {
        return (
          <React.Fragment key={el?.kodZakupowy}>
            <div
              className={`${S.singleDiscountBox} ${
                selectedDiscount?.kodZakupowy === el.kodZakupowy ? S.singleDiscountBoxActive : ''
              } ${isDiscountDisabled(el) ? S.singleDiscountBoxDisabled : ''} ${variant ? S[variant] : ''}`}
              onClick={() => !isDiscountDisabled(el) && onDiscountChange(el)}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  e.preventDefault();
                  !isDiscountDisabled(el) && onDiscountChange(el);
                }
              }}
              tabIndex={0}
              role="button"
            >
              <div className={S.singleDiscountHeader}>
                <p>
                  <span className="screen-readers">{t('13018')}</span>
                  {el.opisy[languageNumber].nazwa} {translations[el.kodZakupowy] && `- ${translations[el.kodZakupowy]}`}
                </p>
                <p className={S.descriptionInfo}>{el.prezentacjaOpis}</p>
              </div>
              <div className={S.singleDiscountInfo}>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(el.opisy[languageNumber].informacja)}} />
              </div>
              <DiscountToggleDetail
                description={parse(el.opisy[languageNumber].opis)}
                ariaLabel={el.opisy[languageNumber].nazwa}
              />
            </div>
            <div className={S.graySeparatorMobile} />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DiscountList;
