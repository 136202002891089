import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';

import MyTicketsSingleTicketOpenMobile from 'components/pages/UserProfile/components/MyTickets/MyTicketsSingleTicketOpenMobile';
import {Tab, Tabs} from 'common/components/base/Tabs';
import UniversalHeader from 'components/common/UniversalHeader';
import {TicketComponent} from '../../components/Ticket/TicketComponent';
import {TicketsListPageLoader} from '../../components/MyTicketsListPageLoader/TicketsListPageLoader';
import {EmptyInformation} from 'common/components/feature/EmptyInformation';
import {SearchInput} from 'common/components/feature/SearchInput';

import {useMyTicketDispatch} from '../../hooks/useTicket';
import {useFilterTickets} from '../../hooks/useFilterTickets';
import {useTicketDataHandler, useTicketModalTabs} from './TicketDetailsModal.hooks';
import {isReservationFromSeasonalTicket} from 'components/pages/UserProfile/common/utils';
import {isSeasonalOfferCode} from 'common/utils';
import {isAdditionalTicketKind, isPrimaryTicketKind} from '../../MyTicketsList.utils';
import {makeTicketQuery as makeQuery} from 'Utils/tickets';
import {ISingleId} from 'api/sprzedaz/interfaces';

import {SearchInputContainer, TicketDetailsModalContainer} from './TicketDetailsModal.styles';

interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}

const TabPanel = ({children, value, index}: TabPanelProps) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <Box mt={2.5}>{children}</Box>}
  </div>
);

export const TicketDetailsModal = React.memo(() => {
  const {t} = useTranslation();

  const {setTicketDetails} = useMyTicketDispatch();
  const {
    identifier,
    tickets,
    reservationListIdentifier,
    dataSeasonalTicket,
    dataSingleTickets,
    isFetchingSingleTicket,
    isFetchingReservationListIdentifiers,
    isSeasonal,
    isReturned,
    isHistoryVariant,
    showSeasonalTicketTab,
    isMobileVersion,
  } = useTicketDataHandler();
  const [selectedTab, handleChangeTab] = useTicketModalTabs(identifier, undefined); //TODO for seasonal ticket last tab should be selected by default
  const {filteredList, isFilteredResultsEmpty, handleSearchChange, searchTerm} = useFilterTickets(
    dataSingleTickets,
    isFetchingSingleTicket,
  );

  const allPrimaryTickets = tickets?.filter((el) => isPrimaryTicketKind(el.biletRodzaj)) ?? [];
  const additionalTickets = tickets?.filter((el) => isAdditionalTicketKind(el.biletRodzaj)) ?? [];

  const showTabs = allPrimaryTickets.length > 1 || showSeasonalTicketTab;

  const isEmptyList = reservationListIdentifier?.length === 0 || isFilteredResultsEmpty;

  const primaryTicketTabs = allPrimaryTickets.map((ticket, index, array) => (
    <Tab
      key={index}
      label={`${
        isReservationFromSeasonalTicket(ticket.biletRodzaj) ? t('29304') : isSeasonal ? t('29487') : t('29291')
      } ${array.length > 1 ? index + 1 : ''}`}
    />
  ));

  return isMobileVersion && identifier && tickets ? (
    <TicketDetailsModalContainer>
      <UniversalHeader
        title={isReservationFromSeasonalTicket(identifier.typBiletu) ? t('29486') : t('29249')}
        goBack={() => setTicketDetails(undefined, undefined)}
      />
      {showTabs && (
        <Tabs variant="fullWidth" onChange={handleChangeTab} value={selectedTab}>
          {primaryTicketTabs}
          {showSeasonalTicketTab && <Tab label={t('13111')} />}
        </Tabs>
      )}

      {/* Tickets tabs | Reservations tabs | Seasonal Tickets in MyTickets view tab |*/}

      {allPrimaryTickets.map((primaryTicket, index) => (
        <TabPanel
          key={index}
          value={selectedTab}
          index={
            isHistoryVariant || isReturned || !isSeasonalOfferCode(tickets[0]?.ofertaKod)
              ? index
              : allPrimaryTickets.length // set Seasonal Tickets in MyTickets to last tab
          }
        >
          <MyTicketsSingleTicketOpenMobile
            allPrimaryTickets={allPrimaryTickets}
            exit={() => setTicketDetails(undefined, undefined)}
            identifier={identifier}
            singleTicket={[primaryTicket, ...additionalTickets]}
            history={isHistoryVariant || isReturned ? 1 : 0} // Additional condition resulting from tickets returned incorrectly by the API.
            makeQuery={makeQuery}
            ticketToReturn={tickets}
            isSeasonal={isSeasonal}
          />
        </TabPanel>
      ))}

      {/* Reservation List tab */}

      {isSeasonal && (
        <TabPanel value={selectedTab} index={0}>
          <SearchInputContainer>
            <SearchInput handleSearchChange={handleSearchChange} searchTerm={searchTerm} />
          </SearchInputContainer>
          {isFetchingReservationListIdentifiers || isFetchingSingleTicket ? (
            <TicketsListPageLoader isMobile={true} elementsLength={3} ticketVariant="SINGLE" />
          ) : isEmptyList ? (
            <EmptyInformation message={isFilteredResultsEmpty ? t('29414') : t('29413')} />
          ) : (
            filteredList?.map((filteredTicketObject) => {
              const {bilety} = filteredTicketObject ?? {};
              const identifier = reservationListIdentifier?.find((identifierTicket) =>
                bilety?.find(
                  ({biletNr, biletSeria}) =>
                    `${identifierTicket.biletSeria + identifierTicket.biletNr}` === `${biletSeria + biletNr}`,
                ),
              );
              return identifier && bilety ? (
                <TicketComponent
                  key={`${identifier.transakcjaNr}_${identifier.biletSeria}_${identifier.biletNr}`}
                  identifier={identifier}
                  tickets={bilety}
                  isHistoryVariant={0}
                  onMobileTileClick={() => setTicketDetails(identifier, bilety)}
                  isMobile={true}
                  isReturned={identifier.zwrocony === 1}
                  isSeasonal={false}
                />
              ) : null;
            })
          )}
        </TabPanel>
      )}

      {/* Seasonal Ticket in single ticket tab */}

      {showSeasonalTicketTab && dataSeasonalTicket[0] && (
        <TabPanel value={selectedTab} index={allPrimaryTickets.length}>
          <MyTicketsSingleTicketOpenMobile
            exit={() => setTicketDetails(undefined, undefined)}
            identifier={
              {biletSeria: identifier.biletPodstawowySeria, biletNr: identifier.biletPodstawowyNr} as ISingleId
            } //TODO for now it should work, but in the future the entire identifier needs to be removed
            singleTicket={dataSeasonalTicket[0]?.bilety}
            history={0}
            makeQuery={makeQuery}
            ticketToReturn={dataSeasonalTicket[0]?.bilety}
            isSeasonal
          />
        </TabPanel>
      )}
    </TicketDetailsModalContainer>
  ) : null;
});
