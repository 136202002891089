import {StateContext, Theme, Font, FontSizeScale, Breakpoints} from './types';
import {defaultTheme, contrastTheme, StyledTheme} from './variants';
import {GlobalStylesProps} from '@mui/material';

export const storageName = 'styledTheme';
export const themes = ['default', 'contrast'] as const;
export const fonts = ['small', 'medium', 'large'] as const;
export const fontSizeScales = [1, 2, 3] as const;
export const breakpoints = ['xs', 'sm', 'md'] as const;

export const initialStateContext: StateContext = {
  theme: 'default',
  isContrastTheme: false,
  font: 'small',
};

export const baseFontSizes: Readonly<Record<Font, number>> = {
  small: 10,
  medium: 11.25,
  large: 12.5,
};

export const mappedFontSizeScales: Readonly<Record<FontSizeScale, Font>> = {
  1: 'small',
  2: 'medium',
  3: 'large',
};

export const styledThemes: Readonly<Record<Theme, StyledTheme>> = {
  default: defaultTheme,
  contrast: contrastTheme,
};

export const globalStyles: GlobalStylesProps['styles'] = {
  body: {
    fontSize: '1.6rem',
  },
};

export const screenSizes: Readonly<Record<Breakpoints, number>> = {
  xs: 481,
  sm: 901,
  md: 1100,
};

export const mediaQueries: Readonly<Record<Breakpoints, string>> = {
  xs: `(min-width: ${screenSizes.xs}px)`,
  sm: `(min-width: ${screenSizes.sm}px)`,
  md: `(min-width: ${screenSizes.md}px)`,
};
