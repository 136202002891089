import {ReactComponent as Add} from 'assets/mathSymbols/plus.svg';
import {ReactComponent as AddContrast} from 'assets/mathSymbols/plusContrast.svg';
import {ReactComponent as AddGrey} from 'assets/mathSymbols/plusGrey.svg';
import {ReactComponent as Remove} from 'assets/mathSymbols/minus.svg';
import {ReactComponent as RemoveContrast} from 'assets/mathSymbols/minusContrast.svg';
import {ReactComponent as RemoveGrey} from 'assets/mathSymbols/minusGrey.svg';

import {INewTicket} from 'api/sprzedaz/interfaces';
import {useStyledThemeState} from 'common/theme';

import S from './TicketHandler.module.scss';
import {useTranslation} from 'react-i18next';
import {useContext} from 'react';
import {TicketContext} from 'Utils/TicketContext';

interface TicketHandlerDefaultProps {
  adultCount: INewTicket[];
  changeAdult: React.Dispatch<React.SetStateAction<INewTicket[]>>;
  childrenCount: INewTicket[];
  changeChildren: React.Dispatch<React.SetStateAction<INewTicket[]>>;
  exchangeMode?: boolean;
}

const TicketHandler = ({
  adultCount,
  childrenCount,
  changeAdult,
  changeChildren,
  exchangeMode,
}: TicketHandlerDefaultProps) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();

  const maxTicketCount = adultCount.length + childrenCount.length === 6;
  const adultBtnDisable = !adultCount.length || adultCount.length + childrenCount.length <= 0;
  const childrenBtnDisable = !childrenCount.length || adultCount.length + childrenCount.length <= 0;

  const checkContrastRemove = isContrastTheme ? <RemoveContrast /> : <Remove />;
  const checkContrastAdd = isContrastTheme ? <AddContrast /> : <Add />;
  const {setPkpOffers, disableOffer} = useContext(TicketContext);
  const handleRemoveAdultClick = () => {
    if (adultCount.length === 1) {
      setPkpOffers(`autoOffersDisabled:familyTicket`);
      disableOffer('familyTicket');
    }
    const tab = [...adultCount];
    tab.pop();

    changeAdult(tab);
  };
  const handleAddAdultClick = () => {
    if (adultCount.length + childrenCount.length < 6) {
      changeAdult([
        ...adultCount,
        {
          discountCode: 1010,
          ticketTypeCode: 1,
        },
      ]);
    }
  };
  const handleRemoveChildButton = () => {
    if (childrenCount.length === 1) {
      setPkpOffers(`autoOffersDisabled:familyTicket`);
      disableOffer('familyTicket');
    }
    const tab = [...childrenCount];
    tab.pop();

    changeChildren(tab);
  };
  const handleAddChildButton = () => {
    if (adultCount.length + childrenCount.length < 6) {
      changeChildren([
        ...childrenCount,
        {
          discountCode: 0,
          ticketTypeCode: null,
        },
      ]);
    }
  };
  return (
    <div className={S.container}>
      <div role="region" aria-live="assertive" aria-atomic="true" id="ticketInfo">
        <span id="ticketDescription" className="sr-only">
          {`${t('12002')} ${adultCount.length}. ${t('12003')} ${childrenCount.length}.`}
        </span>
      </div>
      <div className={S.travelers_container}>
        <p className={S.text}>{t('12004')}</p>
        <div className={`${S.buttons_wrapper}`}>
          {!exchangeMode && (
            <button
              disabled={adultBtnDisable}
              aria-label={`${t('12005')} ${adultCount.length}`}
              className={`${S.button} ${adultBtnDisable ? S.buttonInactive : ''}`}
              onClick={handleRemoveAdultClick}
            >
              <span className="icon-minus" aria-hidden="true"></span>
              {!adultBtnDisable ? checkContrastRemove : <RemoveGrey />}
            </button>
          )}

          <div className={S.count_container}>
            <p className={S.text}>{adultCount.length}</p>
          </div>
          <button
            disabled={maxTicketCount}
            aria-label={`${t('12006')} ${adultCount.length}`}
            className={`${S.button} ${
              exchangeMode && S.hidden
            }  ${adultCount.length + childrenCount.length === 6 ? S.buttonInactive : ''}`}
            onClick={handleAddAdultClick}
          >
            <span className="icon-plus" aria-hidden="true"></span>
            {adultCount.length + childrenCount.length < 6 ? checkContrastAdd : <AddGrey />}
          </button>
        </div>
      </div>
      <div className={S.travelers_container}>
        <p className={S.text}>{t('12007')}</p>
        <div className={S.buttons_wrapper}>
          {!exchangeMode && (
            <button
              disabled={childrenBtnDisable}
              aria-label={`${t('12008')} ${childrenCount.length}`}
              className={`${S.button} ${childrenBtnDisable ? S.buttonInactive : ''}`}
              onClick={handleRemoveChildButton}
            >
              <span className="icon-minus" aria-hidden="true"></span>
              {!childrenBtnDisable ? checkContrastRemove : <RemoveGrey />}
            </button>
          )}
          <div className={S.count_container}>
            <p className={S.text}>{childrenCount.length}</p>
          </div>
          <button
            disabled={maxTicketCount}
            aria-label={`${t('12009')} ${childrenCount.length}`}
            className={`${S.button} ${
              exchangeMode && S.hidden
            }  ${adultCount.length + childrenCount.length === 6 ? S.buttonInactive : ''}`}
            onClick={handleAddChildButton}
          >
            <span className="icon-plus" aria-hidden="true"></span>
            {adultCount.length + childrenCount.length < 6 ? checkContrastAdd : <AddGrey />}
          </button>
        </div>
      </div>
    </div>
  );
};
export default TicketHandler;
