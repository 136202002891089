/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  returnCarrierLogo,
  getFullDate,
  getFullTimeFromDate,
  getDayOfTheWeek,
  returnStationByCode,
  returnAdditionalTypeTicketData,
} from 'Utils/commonFunctions';
import usePayLaterTicket from './usePayLaterTicket';

import MyTicketsSingleTicketMobileUnpaidModal from './MyTicketsSingleTicketMobileUnpaidModal';
import PaymentDeadlineTimer from 'components/pages/PurchasePath/PaymentSummaryView/PaymentDeadlineTimer/PaymentDeadlineTimer';

import {usePayment} from 'Utils/PaymentContext';
import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';
import {isSeasonalOfferCode} from 'common/utils';

import Clock from 'assets/clock.svg';
import OrangeCircleSmall from 'assets/orangeCircleSmall.svg';
import OrangePinSmall from 'assets/orangePinSmall.svg';
import ClockContrast from 'assets/clockContrast.svg';
import ContrastCircleSmall from 'assets/contrastCircleSmall.svg';
import ContrastPinSmall from 'assets/contrastPinSmall.svg';

import style from './MyTicketsMobilet.module.scss';
import {useTranslation} from 'react-i18next';
import {ticketKinds} from 'common/api/myTickets';

const MyTicketsSingleTicketMobileUnpaid = () => {
  const {t} = useTranslation();

  const [showTicketModal, setShowTicketModal] = useState(false);

  const {isContrastTheme, theme} = useStyledThemeState();
  const navigate = useNavigate();
  const {ticketFrom, additionalTicketsFrom} = usePayment();

  const {stationsList} = useData();

  const {name, getTicket} = usePayLaterTicket();

  useEffect(() => {
    const tick = async () => {
      await getTicket();
    };

    tick();
  }, []);

  const isSeasonal = isSeasonalOfferCode(ticketFrom?.transakcjeNr?.[0]?.oferta);

  return (
    <>
      <div
        className={style.singleTicket__box}
        onClick={() => {
          setShowTicketModal(true);
        }}
      >
        {ticketFrom && (
          <>
            <div className={style.singleTicket__payLaterContainer}>
              <p className={style.singleTicket__payLaterInfo}>Oczekujący na płatność</p>
              <div className={style.timerContainer}>
                <PaymentDeadlineTimer
                  isPayLater
                  onPaymentDeadline={() => {
                    window.localStorage.removeItem('payLaterTicket');
                    navigate('/profil');
                  }}
                />
              </div>
            </div>
            <div className={style.singleTicket_MainInfo}>
              <div>
                <p
                  className={style.singleTicket_MainInfoTitle}
                >{`${isSeasonal ? ticketFrom.ofertaNazwa : t('11060')}`}</p>
                <h2 className={`${style.singleTicket__active_period} ${style.singleTicket_MainInfoTitleMargin}`}>
                  {getFullDate(ticketFrom.odcinki[0].wyjazdData)}
                  {isSeasonal ? ` - ${getFullDate(ticketFrom.odcinki[0].przyjazdData)}` : null}
                </h2>
              </div>
              <div className={style.singleTicket_TimeContainer}>
                {!isSeasonal && (
                  <>
                    <img src={isContrastTheme ? ClockContrast : Clock} alt={t('17051')} />
                    <p className={style.singleTicket__active_period}>
                      {getFullTimeFromDate(ticketFrom.odcinki[0].wyjazdData)}
                    </p>
                  </>
                )}
              </div>
            </div>
            <p className={style.singleTicket__day_name}>
              {getDayOfTheWeek(new Date(ticketFrom.odcinki[0].wyjazdData).getDay())}
            </p>
            <div className={style.trip_data_box}>
              {!!ticketFrom?.odcinki[0].stacjaOdKod && (
                <>
                  <div className={style.icon_box}>
                    <img src={isContrastTheme ? ContrastCircleSmall : OrangeCircleSmall} alt={t('29080')} />
                    <div />
                    <img src={isContrastTheme ? ContrastPinSmall : OrangePinSmall} alt={t('29081')} />
                  </div>
                  <div className={style.data_box}>
                    {ticketFrom?.odcinki.length === 1 && (
                      <>
                        <div>
                          <div className={style.singleTicket__cityInfo}>
                            <p className={style.from_to}>
                              {returnStationByCode(ticketFrom?.odcinki[0].stacjaOdKod, stationsList)}
                            </p>
                            <p className={style.singleTicket__day_name}>{ticketFrom?.odcinki[0].pociagNr}</p>
                            {returnCarrierLogo(ticketFrom?.odcinki[0]?.pociagKategoriaNazwa, theme)}
                          </div>
                        </div>
                        <div>
                          <div className={style.singleTicket__cityInfo}>
                            <p className={style.from_to}>
                              {returnStationByCode(ticketFrom?.odcinki[0]?.stacjaDoKod, stationsList)}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {ticketFrom?.odcinki.length > 1 && (
                      <>
                        {ticketFrom?.odcinki.map((el, index, arr) => {
                          if (index === 0) {
                            return (
                              <div key={`${el.stacjaOdKod}-${el.stacjaDoKod}`}>
                                <div className={style.singleTicket__cityInfo}>
                                  <p className={style.from_to}>{returnStationByCode(el.stacjaOdKod, stationsList)}</p>
                                  <p className={style.singleTicket__day_name}>{el.pociagNr}</p>
                                  {returnCarrierLogo(el.pociagKategoriaNazwa, theme)}
                                </div>
                              </div>
                            );
                          } else if (index > 0 && index < arr.length - 1) {
                            return (
                              <div key={`${el.stacjaOdKod}-${el.stacjaDoKod}`}>
                                <p className={style.singleTicket_MainInfoTitle}>{t('22010')}</p>
                                <div className={style.singleTicket__cityInfo}>
                                  <p className={style.from_to}>{returnStationByCode(el.stacjaOdKod, stationsList)}</p>
                                  <p className={style.singleTicket__day_name}>{el.pociagNr}</p>
                                  {returnCarrierLogo(el.pociagKategoriaNazwa, theme)}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div key={`${el.stacjaOdKod}-${el.stacjaDoKod}`}>
                                <div className={style.singleTicket__cityInfo}>
                                  <p className={style.from_to}>{returnStationByCode(el.stacjaDoKod, stationsList)}</p>
                                  <p className={style.singleTicket__day_name}>
                                    {returnStationByCode(el.pociagNr, stationsList)}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={style.singleTicket__ikonContainerUnpaid}>
              {!!additionalTicketsFrom && !!additionalTicketsFrom.piesCena && (
                <div className={style.singleTicket__singleIconContainer}>
                  <img
                    src={returnAdditionalTypeTicketData(t, ticketKinds.DOG, isContrastTheme, 'dark')?.image}
                    alt={returnAdditionalTypeTicketData(t, ticketKinds.DOG, isContrastTheme, 'dark')?.nazwa}
                  />
                  <span>x {additionalTicketsFrom.piesCena / 1520}</span>
                </div>
              )}
              {!!additionalTicketsFrom && !!additionalTicketsFrom.piesPrzewodnikCena && (
                <div className={style.singleTicket__singleIconContainer}>
                  <img
                    src={returnAdditionalTypeTicketData(t, ticketKinds.GUIDE_DOG, isContrastTheme, 'dark')?.image}
                    alt={returnAdditionalTypeTicketData(t, ticketKinds.GUIDE_DOG, isContrastTheme, 'dark')?.nazwa}
                  />
                  <span>x {additionalTicketsFrom.piesPrzewodnikCena / 355}</span>
                </div>
              )}
              {!!additionalTicketsFrom && !!additionalTicketsFrom.bagazCena && (
                <div className={style.singleTicket__singleIconContainer}>
                  <img
                    src={returnAdditionalTypeTicketData(t, ticketKinds.LUGGAGE, isContrastTheme, 'dark')?.image}
                    alt={returnAdditionalTypeTicketData(t, ticketKinds.LUGGAGE, isContrastTheme, 'dark')?.nazwa}
                  />
                  <span>x {additionalTicketsFrom.bagazCena / 510}</span>
                </div>
              )}
              {!!ticketFrom && !!ticketFrom.cenaRowery && (
                <div className={style.singleTicket__singleIconContainer}>
                  <img
                    src={returnAdditionalTypeTicketData(t, ticketKinds.BICYCLE, isContrastTheme, 'dark')?.image}
                    alt={returnAdditionalTypeTicketData(t, ticketKinds.BICYCLE, isContrastTheme, 'dark')?.nazwa}
                  />
                  <span>x {ticketFrom.cenaRowery / 910}</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {showTicketModal && (
        <MyTicketsSingleTicketMobileUnpaidModal
          closeFn={() => {
            setShowTicketModal(false);
          }}
          name={name}
        />
      )}
    </>
  );
};

export default MyTicketsSingleTicketMobileUnpaid;
