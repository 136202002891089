import {useState} from 'react';
import {usePayment} from 'Utils/PaymentContext';
import apiPayment from 'api/sprzedaz/repository';
import {getTransactionNumbers} from 'Utils/commonFunctions';

const usePayLaterTicket = () => {
  const [name, setName] = useState('');
  const [payByBlik, setPayByBlik] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const {setTicketFrom, setTicketTo, setAdditionalTicketsTo, setAdditionalTicketsFrom} = usePayment();

  const getTicket = async () => {
    const unpaidTicket = window.localStorage.payLaterTicket;
    if (unpaidTicket) {
      const parsedTicket = JSON.parse(unpaidTicket);
      const tickArr = getTransactionNumbers(
        parsedTicket.ticketFrom,
        parsedTicket.ticketTo,
        parsedTicket.additionalTicketsFrom,
        parsedTicket.additionalTicketsTo,
        null,
      );

      const status = await apiPayment.getTransactionStatus(tickArr);

      const timeStamp = new Date(parsedTicket.ticketFrom.czasNaOplacenie);

      const checkTime = new Date().getTime() - timeStamp.getTime() > 15 * 1000 * 60;

      if (status?.statusy[0].statusKod === 1 && !checkTime) {
        setTicketFrom(parsedTicket.ticketFrom);
        setTicketTo(parsedTicket.ticketTo);
        setAdditionalTicketsTo(parsedTicket.additionalTicketsTo);
        setAdditionalTicketsFrom(parsedTicket.additionalTicketsFrom);
        setName(parsedTicket.traveler);
      } else {
        window.localStorage.removeItem('payLaterTicket');
        window.location.reload();
      }
    }
  };

  return {
    name,
    setName,
    payByBlik,
    setPayByBlik,
    showTicketModal,
    setShowTicketModal,
    isPaymentModalOpen,
    setIsPaymentModalOpen,
    getTicket,
  };
};

export default usePayLaterTicket;
