import {FC, useEffect, useState} from 'react';
import {getFormatedStringHourMinutesFromSeconds, returnSecondsUntilDate} from 'Utils/commonFunctions';

interface Props {
  deadline: string;
}

const Timer: FC<Props> = ({deadline}) => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    setTimeLeft(returnSecondsUntilDate(deadline));

    const intervalId = setInterval(() => {
      setTimeLeft(() => {
        const next = returnSecondsUntilDate(deadline);
        if (next < 1) {
          clearInterval(intervalId);
          return 0;
        }
        return next;
      });
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [deadline]);

  return <>{getFormatedStringHourMinutesFromSeconds(timeLeft)}</>;
};

export default Timer;
