import {IErrorsList} from 'api/error-interface';
import {PobierzIdentyfikatoryFakturFakturaOdp} from 'api/faktury/interfaces';
import {ForeignElasticOffer} from 'common/api/connections';

export enum TicketType {
  SingleTicket = 1,
  SeasonalTicket = 2,
  Reservation = 3,
  InternationalTicket = 4,
  TicketOffice = 5,
  ReservationToSeasonalTicket = 30,
  ReservationToIntercityCard = 32,
}

export type TicketTypeValue = (typeof TicketType)[keyof typeof TicketType];

export interface IPriceCheckRequest {
  urzadzenieNr: number;
  metoda: string;
  odcinki: Array<IPriceCheckTripSection>;
  podrozni: Array<IPriceCheckTraveler>;
  jezyk: string;
  ofertaKod: number;
  biletTyp?: number;
  powrot?: boolean;
}

export interface IPriceCheckTripSection {
  wyjazdData: string;
  stacjaOdKod: number;
  stacjaDoKod: number;
  pociagNr?: number;
  kilometry?: number;
}

export interface IPriceCheckTripSectionExtended extends IPriceCheckTripSection {
  przyjazdData: string;
}

export interface TravelerForForeignConnection {
  dataUrodzenia: string | null; // RRRR-MM-DD,
  podrozZOpiekunem: 0 | 1;
  typOpiekuna: 0 | 1;
  typBiletuOpiekuna: 0 | 1 | 2;
  biletSeriaOpiekun?: string;
  biletNrOpiekun?: number;
  wspolneMiejsce: 0 | 1;
}

export interface IPriceCheckTraveler extends Partial<TravelerForForeignConnection> {
  kodZakupowyZnizki: number;
  biletPodstawowyNr?: number;
  biletPodstawowySeria?: string;
}

export interface IPriceCheckResponse extends IErrorsList {
  ceny: Array<IPriceCheckOffer>;
  sladWeglowy: string;
  komunikatTekst: string;
  kilometry: number;
}

export interface IPriceCheckOffer {
  rodzajMiejscaKod: number;
  cenaTypKod: number;
  cenaTypNazwa: string;
  klasa: number;
  cena: number;
  komunikatKod: number;
  komunikatTekst: string;
  blad: boolean;
  zwrotInfo: string;
  kodPropozycji: string;
  oferta: number;
  kodyOfert?: number[];
  elastycznosc?: ForeignElasticOffer;
  cenaEuro?: number;
}

export interface ICheckDiscountsRequest {
  urzadzenieNr: number;
  metoda: string;
  jezyk: string;
  ofertaKod: number;
  podrozni: Array<IPriceCheckTraveler>;
}

export interface ICheckDiscountsResponse extends IErrorsList {
  komunikatKod: number;
  komunikatNazwa: string;
}

export enum OperationKind {
  Sale = 0, // Default value
  Exchange = 1,
}

export type OperationKindType = (typeof OperationKind)[keyof typeof OperationKind];

export interface IGenerateTicketRequest {
  urzadzenieNr: number;
  metoda: string;
  biletTyp: number;
  odcinki: IGenerateTicketTrip[];
  podrozni: IGenerateTicketTraveler[];
  ofertaKod: number;
  liczbaRowerow: number;
  biletPodstawowySeria?: string;
  biletPodstawowyNr?: number;
  jezyk: string;
  rodzajOperacji?: OperationKindType;
  wymianaTransakcjaNr?: number;
}

export interface IGenerateTicketTrip {
  wyjazdData: string;
  stacjaOdKod: number | null;
  stacjaDoKod: number | null;
  pociagNr?: number;
  klasa: number;
  miejsca: IGenerateTicketSeat[];
  kilometry?: number;
}

export interface IGenerateTicketSeat {
  miejsceTypKod: number;
  usytowanieKod: number;
  wyborMiejscaRodzaj: number;
  wagonNr: number;
  miejsceNr: number;
  plecKod: number;
}

export interface IGenerateTicketTraveler {
  kodZakupowyZnizki: number | null;
  spolkaKolejowaKod: number | null;
  seriaINumerDokumentu?: string;
  wspolneMiejsce?: number;
}

export interface IGenerateTicketResponse extends IErrorsList {
  transakcjaNr: number;
  transakcjeNr: {
    transakcjaNr: number;
    cena: number;
    oferta: number;
    nazwaOferty: string;
    poziomCeny: string;
  }[];
  sprzedazData: string;
  odcinki: IGenerateTicketTripResponse[];
  podrozni: IGenerateTicketTraveler[];
  ofertaKod: number;
  ofertaNazwa: string;
  cenaBilet: number;
  ptuBiletKwota: number;
  ptuBiletProcent: number;
  cenaRezerwacja: number;
  ptuRezerwacjaKwota: number;
  ptuRezerwacjaProcent: number;
  sladWeglowy: string;
  cenaRowery: number;
  ptuRoweryKwota: number;
  ptuRoweryProcent: number;
  cenaBazowa: number;
  poziomCenowy: string;
  komunikaty: string[];
  czasNaOplacenie: string;
  cena?: number;
  cenaEuro?: number;
  cenaPsy?: number;
}

export interface IGenerateTicketTripResponse {
  wyjazdData: string;
  przyjazdData: string;
  stacjaOdKod: number;
  stacjaDoKod: number;
  pociagNr: number;
  pociagNazwa: string;
  pociagKategoriaNazwa: string;
  klasa: number;
  kilometry: number;
  miejsca: IGenerateTicketSeatResponse[];
}

export interface IGenerateTicketSeatResponse {
  miejsceTypKod: number;
  usytowanieNazwa: string;
  miejsceTypNazwa?: string;
  wagonNr: number;
  miejsceNr: number;
  plecKod: number;
}

export interface INewTicket {
  discountCode: number | null;
  ticketTypeCode: number | null;
  railwayEmployee?: IRailwayEmployee;
}

export interface IRailwayEmployee {
  employeeId: string;
  employerCode: number | null;
}

export interface IPkpOffers {
  familyTicket: boolean;
  bigFamily: boolean;
  cheaperWithRelatives: boolean;
}

export interface IGenerateAdditionalTicketsRequest {
  urzadzenieNr: number;
  metoda: string;
  jezyk: string;
  transakcjaNr: number;
  liczbaPsow: number;
  liczbaPsowPrzewodnikow: number;
  liczbaBagazy: number;
}

export interface IGenerateAdditionalTicketsResponse extends IErrorsList {
  piesCena: number;
  piesPtuKwota: number;
  piesPtuProcent: number;
  piesPrzewodnikCena: number;
  piesPrzewodnikPtuKwota: number;
  piesPrzewodnikPtuProcent: number;
  bagazCena: number;
  bagazPtuKwota: number;
  bagazPtuProcent: number;
  transakcjeNr: {
    cena: number;
    transakcjaNr: number;
  }[];
}

export interface ICheckTransactionResponse {
  bledy: string[];
  statusy: {
    statusKod: number;
    transakcjaData: string;
    transakcjaNr: number;
  }[];
}

export interface IRegisterPayment {
  client_email: string;
  client_name: string;
  device_uuid: string;
  language: string;
  domestic: boolean;
  paymentOperator: string;
  timeForPayment: string | undefined;
  paymentRegisterRequests: {
    item_amount: number;
    item_id: number | number[];
    item_type?: number;
    paymentRegisterP24Request: {
      p24_methodRefId: null;
      p24_sdkVersion: string;
      p24_method: null;
      p24_t6_code_blik: string;
    };
  }[];
}

export interface IRegisterPaymentResponse {
  paymentId: number;
  paymentToken: string;
  paymentRedirect: string;
}

export interface IPaymentStatus extends IRegisterPayment {
  paymentId: number;
  paymentOperatorToken: string;
  paymentStatus: string | null;
  registerDate: string;
  p24: boolean;
}

export interface IPayTheTicket {
  // urzadzenieNr: number;
  // metoda: string,
  transakcjeNr: number | number[];
  podroznyNazwa: string;
  platnoscData: string;
  platnoscSposobKod: number;
  platnoscNr: number;
}

export interface IPayedTicketResponse {
  transakcjaNr: number;
  biletSeria: string;
  biletNr: number;
  status: string;
}

export interface IPayTheTicketResponse {
  data: {
    paymentStatus: string;
  };
  bilety: Array<IPayedTicketResponse>;
  bledy: {
    kod: number;
    opisy: {
      jezyk: string;
      komunikat: string;
    }[];
  }[];
}

export interface IsendBackPayment {
  transakcjeNr: number | number[];
  platnoscNr: number;
}

export interface IsendBackPaymentResponse {
  BladOdp: string[];
  bledy: {
    kod: number;
  }[];
}

export interface IDownloadTicketRequest {
  urzadzenieNr?: number;
  metoda?: string;
  transakcjaNr: number;
  biletSeria?: string | null;
  biletNr?: number | null;
}

export interface IDownloadTicketResponseSingleTicket {
  biletRodzaj: number;
  transakcjaNr: number;
  biletSeria: string;
  biletPodstawowySeria: string;
  biletNr: number;
  biletPodstawowyNr: number;
  biletZrodloKod: number;
  sprzedazData: string;
  odcinki: IDownloadTicketTrip[];
  podrozni: IDownloadTicketPassenger[];
  ofertaKod: number;
  ofertaNazwa: string;
  cenaBilet: number;
  cenaCalkowita: number;
  ptuBiletKwota: number;
  ptuBiletProcent: number;
  cenaRezerwacja: number;
  ptuRezerwacjaKwota: number;
  ptuRezerwacjaProcent: number;
  podroznyNazwa: string;
  kod2D: string;
  kodKontrolny: string;
  zwrotData: string;
  sladWeglowy: {
    jezyk: string;
    tresc: string;
  }[];
  zwrotKwota: number;
  czyMozliwyZwrot: 0 | 1;
  mozliwaZmianaDanychPodroznego: boolean;
  fakturaSeria: string;
  fakturaNr: string;
  fakturaKorektaSeria: string;
  fakturaKorektaNr: string;
  notaKorygujacaSeria: string;
  notaKorygujacaNr: string;
  poziomCenowy: string;
  stacjaKoncowa: string;
  platnoscSposobKod: number;
  zwrotOpis: string;
  waznoscBiletuDo: string;
  waznoscBiletuOd: string;
  miastoOdNazwaDoWyszukiwania?: string;
  miastoDoNazwaDoWyszukiwania?: string;
  nrSeriaDoWyszukiwania?: string;
}

export interface IDownloadTicketResponse extends IErrorsList {
  bilety: IDownloadTicketResponseSingleTicket[];
}

export interface IDownloadTicketTrip {
  wyjazdData: string;
  przyjazdData: string;
  stacjaOdKod: number;
  stacjaDoKod: number;
  pociagNr: number;
  pociagNazwa: string;
  pociagKategoriaNazwa: string;
  klasa: number;
  kilometry: number;
  miejsca: IDownloadTicketTripPlaces[];
  nrReferencyjnyRezerwacji: string;
}

export interface IDownloadTicketTripPlaces {
  miejsceTypKod: number;
  usytowanieNazwa: string;
  wagonNr: number;
  miejsceNr: number;
  plecKod: number;
  miejsceTypNazwa: string;
}

export interface IDownloadTicketPassenger {
  znizkaKod: number;
  spolkaKolejowaKod: number;
}

export interface ITicketList {
  biletTyp: number;
  historia: number;
}

export interface IIdList {
  biletTyp: number;
  historia: number;
  wynikowNaStronie: number;
  listaTyp: number;
  sortowanie: number;
  numerStrony: number;
}

export interface ISingleId {
  biletNr: number;
  biletSeria: string;
  transakcjaNr: number;
  typBiletu: number;
  waznoscBiletuDo: string;
  waznoscBiletuOd: string;
  zwrocony: number;
  typeOfNote?: number;
  anulowany: number;
  biletPodstawowyNr?: number;
  biletPodstawowySeria?: string;
}

export interface IIdResponseList {
  bledy?: [];
  identyfikatory: ISingleId[] | PobierzIdentyfikatoryFakturFakturaOdp[];
  paginacja: {
    obecnaStrona: number;
    wszystkichStron: number;
    wszystkichWynikow: number;
    wynikowNaStrone: number;
  };
}

export interface FullTicket {
  ticketInfo: ISingleId;
  ticketDetail: IDownloadTicketResponse;
  searchDate: string;
}

export interface IGetTickePart {
  wyjazdData: string;
  przyjazdData: string;
  stacjaOdKod: number | string;
  stacjaDoKod: number | string;
  stacjaDoKodNr?: number;
  stacjaOdKodNr?: number;
  pociagNr: number;
  pociagNazwa: string;
  pociagKategoriaNazwa: string;
}

export interface IGetTicketList {
  transakcjaNr: number;
  biletSeria: string;
  biletNr: number;
  odcinki: IGetTickePart[];
  liczbaPodroznych: number;
  liczbaRowerow: number;
  liczbaPsow: number;
  liczbaPsowPrzewodnikow: number;
  liczbaBagazy: number;
  uwagi: string;
  zwrocony: number;
  ofertaNazwa?: string;
  waznoscBiletuDo?: string;
  waznoscBiletuOd?: string;
}

export interface ITicketListResponse {
  bledy: [];
  bilety: IGetTicketList[];
}
export interface ICancelTicketRequest {
  urzadzenieNr: number;
  metoda: string;
  transakcjaNr: number;
}

export interface IReturnTicket {
  urzadzenieNr?: number;
  metoda?: string;
  transakcjaNr: number;
  biletSeria: string;
  biletNr?: number | null;
  powodKod: number;
  wymianaTransakcjaNr: number;
}

export interface IReturnTicketResponse extends IErrorsList {
  procentZwrotu: number;
  kwotaZwrotu: number;
  fakturaKorektaSeria: string;
  fakturaKorektaNr: string;
  notaKorygujacaSeria: string;
  notaKorygujacaNr: string;
  zwrotRozpoczety?: 0 | 1;
}

export interface IChangePassengerDataRequest {
  urzadzenieNr?: number;
  metoda?: string;
  transakcjaNr: number;
  podroznyNazwa: string;
}

export interface ITicketAsPdfResponse extends IErrorsList {
  obrazPDF: number[];
}

export interface IGenerateAdditionalFeeRequest {
  urzadzenieNr: number;
  metoda: string;
  transakcjaNr: number;
  kod: number;
  kwota: number | undefined;
}

export interface IAdditionalFeeTransaction {
  transakcjaNr: number;
  kwota: number;
  stawkaPTU: number;
  ptu: number;
}

export interface IGenerateAdditionalFeeResponse extends IErrorsList {
  transakcjeNr: IAdditionalFeeTransaction[];
}
