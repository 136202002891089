export const TRAIN_DIRECTION = {
  LEFT: 1,
  NO_DATA: 0,
  RIGHT: -1,
};
export const TRAIN_TYPES_WITH_GRM_AVAILABLE = {
  PENDOLINO: 'ED250',
  FLIRT: 'ED160',
  DART: 'ED161',
  TLK: 'SN84',
  IC: ' ED74',
};
