/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {ChangeEvent, useContext, useEffect, useState, forwardRef, useRef} from 'react';
import {Element as ScrollTarget} from 'react-scroll';

import TicketHandler from '../../PurchasePathMobile/Tickets/components/TicketHandler';
import Ticket from '../../PurchasePathMobile/Tickets/components/Ticket';
import ConfirmButton from 'components/common/ConfirmButton';
import OutlineButton from 'components/common/OutlineButton';
import DiscountList from './DiscountList';
import ErrorYellow from 'components/common/ErrorYellow';
import {useData} from 'Utils/DataContex';
import {ShopDiscount} from 'common/api/localData';
import {
  getPassengerAge,
  isPassengerAdult,
  sortFromMostPopulars,
  handleConfirmDiscounts,
  handleCancelDiscounts,
  handleCancelDiscountsModal,
  handleRecommendedAgeDiscounts,
  registerEmployeeData,
  onSelectedDiscountChange,
  isDiscountQuantityValid,
  areAllDiscountsSelected,
  scrollToErrorsDesktop,
  scrollToConfirmBtnDesktop,
  areAllRailwayDiscValid,
  isRailwayEmployeeRelated,
  scrollToRailwayEmplError,
  filterDiscounts,
  formatMonth,
  setDaysToDisplay,
  validateAdditionalTicketsQQ,
  areAllRailwayCodesValid,
  EmployeeIdStatus,
  isCodeRailwayEmployeeRelated,
} from './SetDiscountsLogic';
import {
  discountListLanguageMapper,
  getActiveOffer,
  getPKpOfferCode,
  getShopDiscountFromList,
  returnOfferNameFromOffersModalError,
} from 'Utils/commonFunctions';
import {INewTicket, IPriceCheckTraveler, IRailwayEmployee, ICheckDiscountsRequest} from 'api/sprzedaz/interfaces';
import saleApi from 'api/sprzedaz/repository';
import documentsApi from 'api/dokumenty/repository';

import S from './SetDiscounts.module.scss';
import Profile from 'assets/profileNavyDesktop.svg';
import ProfileContrast from 'assets/profileContrastDesktop.svg';
import {TicketContext} from 'Utils/TicketContext';
import {useStyledThemeState} from 'common/theme';
import DiscountsAgePicker from './DiscountsAgePicker/DiscountsAgePicker';
import {checkRefreshAndReturnJwt} from 'Utils/auth';
import ConfirmationModal from 'components/common/ConfirmationModal/ConfirmationModal';
import {ICheckDocumentRequest} from 'api/dokumenty/interfaces';
import {useTranslation} from 'react-i18next';
import i18n from 'i18n';
import {useValidateInactiveDiscounts} from './SetDiscounts.hooks';

interface Props {
  adultCount: INewTicket[];
  childrenCount: INewTicket[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeAdult: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeAdultBack?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeChildren: any;
  manageDiscounts: () => void;
  modalMode?: boolean;
  passOpenModal?: () => void;
  exchangeMode?: boolean;
  buyerProfileMode?: boolean;
}

export type ActiveTicket = {
  index: number;
  isAdult: boolean;
};

export type InitialCount = {
  adultCount: INewTicket[];
  childrenCount: INewTicket[];
};

// eslint-disable-next-line react/display-name
const SetDiscounts = forwardRef<HTMLHeadingElement, Props>(
  (
    {
      adultCount,
      childrenCount,
      changeAdult,
      changeAdultBack,
      changeChildren,
      manageDiscounts,
      modalMode,
      passOpenModal,
      exchangeMode,
      buyerProfileMode,
    },
    ref,
  ) => {
    const {t} = useTranslation();

    const {isContrastTheme} = useStyledThemeState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initialCount, setInitialCount] = useState<InitialCount>({
      adultCount: adultCount,
      childrenCount: childrenCount,
    });
    const [adultCountState, setAdultCountState] = useState<INewTicket[]>(adultCount);
    const [childrenCountState, setChildrenCountState] = useState<INewTicket[]>(childrenCount);
    const [inputDay, setInputDay] = useState<string>('');
    const [inputMonth, setInputMonth] = useState<string>('');
    const [inputYear, setInputYear] = useState<string>('');
    const [inputDate, setInputDate] = useState<string>('');
    const [searchInput, setSearchInput] = useState<string>('');
    const [passengerAge, setPassengerAge] = useState<string>('');
    const [mostPopularDiscs, setMostPopularDiscs] = useState<ShopDiscount[]>([]);
    const [recommendedDisc, setRecommendedDisc] = useState<ShopDiscount[]>([]);
    const [filteredDiscs, setFilteredDiscs] = useState<ShopDiscount[]>([]);
    const [selectedDiscount, setSelectedDiscount] = useState<ShopDiscount>();
    const [tempDiscount, setTempDiscount] = useState<ShopDiscount | null>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [activeTicket, setActiveTicket] = useState<ActiveTicket>({
      index: 0,
      isAdult: adultCount.length > 0,
    });
    // Set employeeId: "" on init and reset to enable employee id validation
    const [employeeData, setEmployeeData] = useState<IRailwayEmployee>({
      employeeId: '0',
      employerCode: null,
    });
    const [discountQuantityError, setDiscountQuantityError] = useState(false);

    const [railwayEmplIdError, setRailwayEmplIdError] = useState(false);
    const [railwayEmplCodeError, setRailwayEmplCodeError] = useState(false);
    const [railwayEmplIdStatus, setRailwayEmplIdStatus] = useState<EmployeeIdStatus>(EmployeeIdStatus.UNCHECKED);

    const employerSearcherRef = useRef<HTMLDivElement | null>(null);
    const [checkDiscountsError, setCheckDiscountsError] = useState<string>('');
    const isRailwayEmployee = selectedDiscount && isRailwayEmployeeRelated(selectedDiscount);
    const {shopDiscounts} = useData();
    const {
      pkpOffers,
      setPkpOffers,
      verifyDiscountCodesWithOffers,
      offersModalError,
      resetErrorModal,
      resetOfferModal,
      addTicketContextFrom,
      addTicketContextTo,
      setAddTicketContextFrom,
      setAddTicketContextTo,
    } = useContext(TicketContext);

    useValidateInactiveDiscounts(adultCountState, setAdultCountState, childrenCountState, setChildrenCountState);

    useEffect(() => {
      const checkToken = async () => {
        const token = await checkRefreshAndReturnJwt();
        if (token) {
          !isLoggedIn && setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      };
      checkToken();
    }, []);

    useEffect(() => {
      if (shopDiscounts.length > 0) {
        if (!selectedDiscount && adultCountState.length) {
          const find = getShopDiscountFromList(adultCountState[0].discountCode, shopDiscounts);
          find && setSelectedDiscount(find);
        }
      }
    }, [shopDiscounts]);

    useEffect(() => {
      setInputDate('');
      setInputDay('');
      setInputMonth('');
      setInputYear('');
      setRailwayEmplIdStatus(EmployeeIdStatus.UNCHECKED);
      if (searchInput.length) {
        setSearchInput('');
        handleRecommendedAgeDiscounts(passengerAge, mostPopularDiscs, setRecommendedDisc, setFilteredDiscs);
      }
      if (activeTicket.isAdult) {
        const ticket = adultCountState[activeTicket.index];
        const find = getShopDiscountFromList(ticket?.discountCode, shopDiscounts);
        find && setSelectedDiscount(find);
        if (ticket?.railwayEmployee) {
          setEmployeeData({...ticket.railwayEmployee});
        }
        if (!isCodeRailwayEmployeeRelated(ticket?.discountCode)) {
          setFocusBasedOnEvent();
        } else {
          !ticket?.railwayEmployee?.employerCode && focusEmployerCodeSelection();
        }
        shopDiscounts && setMostPopularDiscs(sortFromMostPopulars(shopDiscounts, true));
      } else {
        const ticket = childrenCountState[activeTicket.index];
        const find = getShopDiscountFromList(childrenCountState[activeTicket.index]?.discountCode, shopDiscounts);
        find && setSelectedDiscount(find);
        if (ticket?.railwayEmployee) {
          setEmployeeData({...ticket.railwayEmployee});
        }
        if (!isCodeRailwayEmployeeRelated(ticket?.discountCode)) {
          setFocusBasedOnEvent();
        } else {
          !ticket?.railwayEmployee?.employerCode && focusEmployerCodeSelection();
        }
        shopDiscounts && setMostPopularDiscs(sortFromMostPopulars(shopDiscounts, false));
      }
    }, [activeTicket]);

    useEffect(() => {
      setPassengerAge(getPassengerAge(inputDate));
      !inputDate && setRecommendedDisc([]);
    }, [inputDate]);

    useEffect(() => {
      if (!!inputDate?.length && inputDate.length === 10) {
        handleRecommendedAgeDiscounts(passengerAge, mostPopularDiscs, setRecommendedDisc, setFilteredDiscs);
        setSearchInput('');
      }
    }, [passengerAge, inputDate]);

    useEffect(() => {
      if (selectedDiscount && (employeeData?.employerCode || employeeData?.employeeId.length === 13)) {
        if (employeeData?.employeeId.length === 13) {
          validateAndRegisterEmployeeId();
        } else {
          railwayEmplIdStatus === EmployeeIdStatus.VALID && setRailwayEmplIdStatus(EmployeeIdStatus.INVALID);
          registerEmployeeData(
            activeTicket,
            activeTicket.isAdult ? adultCountState : childrenCountState,
            activeTicket.isAdult ? setAdultCountState : setChildrenCountState,
            selectedDiscount,
            employeeData,
          );
        }
      }
    }, [employeeData]);

    useEffect(() => {
      const validDiscountsQQ = isDiscountQuantityValid(adultCountState, childrenCountState);
      if (!validDiscountsQQ) {
        !discountQuantityError && setDiscountQuantityError(true);
        scrollToErrorsDesktop();
      }
      if (validDiscountsQQ) {
        discountQuantityError && setDiscountQuantityError(false);
        validateAreAllDiscountsSelected();
      }

      resetErrorModal();
      resetOfferModal();
    }, [adultCountState, childrenCountState]);

    const validateAndRegisterEmployeeId = async () => {
      if (selectedDiscount) {
        const employeeId = employeeData.employeeId.trim().replace(/\s/g, '').replace('/', '');

        const request: ICheckDocumentRequest = {
          urzadzenieNr: 0,
          metoda: '',
          typDokumentu: 0,
          seriaINumerDokumentu: employeeId,
        };

        await documentsApi
          .checkDocument(request)
          .then((data) => {
            if (data && data?.statusDokumentu === 0 && !data?.bledy?.length) {
              setRailwayEmplIdStatus(EmployeeIdStatus.VALID);
              registerEmployeeData(
                activeTicket,
                activeTicket.isAdult ? adultCountState : childrenCountState,
                activeTicket.isAdult ? setAdultCountState : setChildrenCountState,
                selectedDiscount,
                employeeData,
              );
            } else {
              setRailwayEmplIdStatus(EmployeeIdStatus.INVALID);
            }
          })
          .catch(() => {
            setRailwayEmplIdStatus(EmployeeIdStatus.INVALID);
          });
      }
    };

    const getOffersNamesFromModalError = (offerErrors: string[]) => {
      return offerErrors.map((x) => returnOfferNameFromOffersModalError(t, x));
    };

    const getConfirmationMsg = () => {
      if (offersModalError && offersModalError.length > 0) {
        return `Wybrano ulgę ${tempDiscount?.opisy.find((x) => x.jezyk === i18n.language.toUpperCase())?.nazwa}, która nie obowiązuje w ${offersModalError.length > 1 ? 'ofertach' : 'ofercie'} ${getOffersNamesFromModalError(offersModalError)}.`;
      }

      return t('13008');
    };

    const handleConfirmModal = () => {
      if (pkpOffers.bigFamily) setPkpOffers('autoOffersDisabled:bigFamily');
      if (pkpOffers.familyTicket) setPkpOffers('autoOffersDisabled:familyTicket');
      if (pkpOffers.cheaperWithRelatives) setPkpOffers('autoOffersDisabled:cheaperWithRelatives');

      setAdultCountState(
        adultCountState.map((x) => {
          if (x.discountCode === 1650 || x.discountCode === 1570) return {...x, discountCode: 1010};
          return x;
        }),
      );
      setPkpOffers('');
      setOpenModal(false);
    };

    const handleCloseModal = () => {
      setTempDiscount(undefined);
      resetErrorModal();
      resetOfferModal();
      setOpenModal(false);
    };

    const validateAreAllDiscountsSelected = () => {
      const discTemp = [...adultCountState, ...childrenCountState];
      const areAllRailwayValid = areAllRailwayDiscValid([...adultCountState, ...childrenCountState]);

      if (selectedDiscount && areAllRailwayValid) {
        railwayEmplIdError && setRailwayEmplIdError(false);
        railwayEmplCodeError && setRailwayEmplCodeError(false);
      }
      if (!areAllRailwayValid) {
        // Uncomment to enable employee id validation
        // if (!railwayEmplIdError) {
        //     !areAllRailwayIdValid(discTemp) && setRailwayEmplIdError(true);
        // } else {
        //     areAllRailwayIdValid(discTemp) && setRailwayEmplIdError(false);
        // }
        if (!railwayEmplCodeError) {
          !areAllRailwayCodesValid(discTemp) && setRailwayEmplCodeError(true);
        } else {
          areAllRailwayCodesValid(discTemp) && setRailwayEmplCodeError(false);
        }
        isRailwayEmployee && scrollToRailwayEmplError();
      }
    };

    const onSearchChange = (value: string) => {
      setSearchInput(value);
      if (value.length > 1) {
        const filtered = filterDiscounts(value, mostPopularDiscs);
        setFilteredDiscs(filtered);
      }
      if (value.length === 0) {
        handleRecommendedAgeDiscounts(passengerAge, mostPopularDiscs, setRecommendedDisc, setFilteredDiscs);
      }
    };

    const onActiveTicketChange = (index: number, isAdult: boolean) => {
      if (activeTicket.index !== index && isAdult === activeTicket.isAdult) {
        setActiveTicket({index, isAdult});
        isRailwayEmployee && setEmployeeData({employeeId: '0', employerCode: null});
      }
      if (isAdult !== activeTicket.isAdult) {
        setActiveTicket({index, isAdult});
        isRailwayEmployee && setEmployeeData({employeeId: '0', employerCode: null});
      }
      if (activeTicket.index === index) {
        const ticket = isAdult ? adultCountState[activeTicket.index] : childrenCountState[activeTicket.index];
        if (!isCodeRailwayEmployeeRelated(ticket?.discountCode)) {
          setFocusBasedOnEvent();
        } else {
          focusEmployerCodeSelection();
        }
      }
    };

    const handleDiscountChange = (discount: ShopDiscount | null) => {
      const discountToTicket = {discountCode: discount?.kodZakupowy ?? null} as INewTicket;
      const canSetDiscount = verifyDiscountCodesWithOffers([discountToTicket]);

      if (!canSetDiscount && (pkpOffers.bigFamily || pkpOffers.cheaperWithRelatives || pkpOffers.familyTicket)) {
        setTempDiscount(discount);
        setOpenModal(true);
        return;
      }

      onSelectedDiscountChange(
        activeTicket,
        adultCountState,
        setAdultCountState,
        childrenCountState,
        setChildrenCountState,
        discount,
        isRailwayEmployee,
        setEmployeeData,
        setSelectedDiscount,
        setRailwayEmplIdStatus,
      );
      if (!isRailwayEmployeeRelated(discount)) {
        handleJumpToNextTicket(discount);
      } else {
        setTimeout(() => {
          focusEmployerCodeSelection();
        }, 50);
      }
      tempDiscount && setTempDiscount(undefined);
    };

    useEffect(() => {
      tempDiscount && offersModalError?.length === 0 && handleDiscountChange(tempDiscount);
    }, [pkpOffers, tempDiscount, offersModalError]);

    useEffect(() => {
      let timeout: NodeJS.Timeout;
      if (inputMonth === '1') {
        timeout = setTimeout(() => {
          setInputMonth('01');
        }, 1500);
      }
      return () => clearTimeout(timeout);
    }, [inputMonth]);

    const handleJumpToNextTicket = (discount: ShopDiscount | null) => {
      let nextIndex: number | null = null;
      let isAdult = true;
      const nextTemp = activeTicket.index + 1;
      if (activeTicket.isAdult) {
        const isValidIndex = nextTemp <= adultCountState.length - 1;
        if (isValidIndex) {
          nextIndex = nextTemp;
          setFocusBasedOnEvent();
        }
        if (!isValidIndex && childrenCountState.length) {
          nextIndex = 0;
          isAdult = false;
          setFocusBasedOnEvent();
        }
        if (!childrenCountState.length && activeTicket.index === adultCountState.length - 1 && !!discount) {
          modalMode ? scrollToConfirmBtnDesktop(true) : scrollToConfirmBtnDesktop();
        }
      } else {
        const isValidIndex = nextTemp <= childrenCountState.length - 1;
        if (isValidIndex) {
          nextIndex = nextTemp;
          isAdult = false;
          setFocusBasedOnEvent();
        }
        if (activeTicket.index === childrenCountState.length - 1 && !!discount) {
          modalMode ? scrollToConfirmBtnDesktop(true) : scrollToConfirmBtnDesktop();
        }
      }
      nextIndex !== null && setActiveTicket({index: nextIndex, isAdult});
    };

    const onEmployeeIdChange = (e: ChangeEvent<HTMLInputElement>) => {
      const {value, name} = e.target;
      const newValue = name === 'employeeId' ? value.toUpperCase() : value;
      setEmployeeData((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    };

    const onEmployerCodeChange = (code: number | null) => {
      setEmployeeData((prev) => ({
        ...prev,
        employerCode: code,
      }));

      const nextTarget = activeTicket.index + 1;
      const isAdult = adultCountState.length >= nextTarget;

      if (nextTarget === adultCountState.length + childrenCountState.length) {
        modalMode ? scrollToConfirmBtnDesktop(true) : scrollToConfirmBtnDesktop();
      } else {
        setTimeout(() => {
          setActiveTicket({index: nextTarget, isAdult});
        }, 100);
      }
    };

    const handleBackButton = () => {
      setCheckDiscountsError('');
      resetErrorModal();

      if (buyerProfileMode && typeof passOpenModal == 'function') {
        passOpenModal();
        return;
      }

      if (modalMode && passOpenModal) {
        handleCancelDiscountsModal(initialCount, changeAdult, changeChildren);
        passOpenModal();
        return;
      }

      handleCancelDiscounts(initialCount, changeAdult, changeChildren, manageDiscounts);
    };

    const validateDiscounts = () => {
      setCheckDiscountsError('');

      const adultDiscounts =
        [...adultCountState].map((x) => {
          return {
            kodZakupowyZnizki: x.discountCode,
          } as IPriceCheckTraveler;
        }) ?? [];
      const childDiscounts =
        [...childrenCountState].map((x) => {
          return {
            kodZakupowyZnizki: x.discountCode,
          } as IPriceCheckTraveler;
        }) ?? [];
      const offer = getActiveOffer(pkpOffers);
      const offerCode = getPKpOfferCode(offer) ?? 1;

      const checkDiscountsReq: ICheckDiscountsRequest = {
        urzadzenieNr: 0,
        metoda: '',
        jezyk: i18n.language.toUpperCase(),
        ofertaKod: offerCode,
        podrozni: [...adultDiscounts, ...childDiscounts],
      };

      const checkDiscounts = async () => {
        const res = await saleApi.checkDiscounts(checkDiscountsReq);

        if (res && res.komunikatKod !== 0) {
          setCheckDiscountsError(res.komunikatNazwa);
          return;
        }

        changeAdult(adultCountState);
        changeChildren(childrenCountState);
        changeAdultBack && changeAdultBack(adultCountState);
        validateAdditionalTicketsQQ(
          adultCountState.length + childrenCount.length,
          addTicketContextFrom,
          addTicketContextTo,
          setAddTicketContextFrom,
          setAddTicketContextTo,
        );
        handleConfirmDiscounts(manageDiscounts);
      };
      checkDiscounts();
      passOpenModal && passOpenModal();
    };

    useEffect(() => {
      setInputDate(`${inputYear}-${formatMonth(inputMonth)}-${inputDay}`);
    }, [inputDay, inputMonth, inputYear]);

    const offersNoCheck = offersModalError && offersModalError.length > 1 ? 'ofert' : 'oferty';

    const setFocusBasedOnEvent = (): void => {
      const dayInput = document.querySelector('#birthDay');
      const focusTarget = document.querySelector('#focusTarget');
      if (window.event && window.event.type === 'click') {
        (dayInput as HTMLInputElement).focus();
      } else if (window.event && window.event.type === 'keydown') {
        (focusTarget as HTMLDivElement).focus();
      }
    };

    const focusEmployerCodeSelection = (): void => {
      !!employerSearcherRef?.current && employerSearcherRef.current.focus();
    };

    return (
      <div>
        <ScrollTarget name="discountQuantityError">
          {discountQuantityError && <ErrorYellow displayText={t('13009')} />}
        </ScrollTarget>
        <ScrollTarget name="railwayEmplError">
          {railwayEmplIdError && <ErrorYellow displayText={t('13010')} />}
          {railwayEmplCodeError && <ErrorYellow displayText={t('13011')} />}
        </ScrollTarget>
        <section className={S.discount_container}>
          <div className={S.left_column}>
            <div className={S.handler_box}>
              <h2 className={S.blue_absolute_span}>{t('13012')}</h2>
              <TicketHandler
                adultCount={adultCountState}
                changeAdult={setAdultCountState}
                childrenCount={childrenCountState}
                changeChildren={setChildrenCountState}
                exchangeMode={exchangeMode}
              />
            </div>
            <div className={S.tickets_box}>
              {adultCountState.map((el, index) => (
                <div
                  aria-label={`${t('13015')} ${index + 1}`}
                  key={index}
                  tabIndex={0}
                  onClick={() => onActiveTicketChange(index, true)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onActiveTicketChange(index, true);
                    }
                  }}
                >
                  <Ticket
                    isAdult
                    isActive={activeTicket.index === index && activeTicket.isAdult}
                    index={index + 1}
                    discountCode={el.discountCode}
                    employeeData={employeeData}
                    onEmployeeIdChange={onEmployeeIdChange}
                    onEmployerCodeChange={onEmployerCodeChange}
                    railwayEmplIdStatus={railwayEmplIdStatus}
                    employerSearcherRef={employerSearcherRef}
                  />
                </div>
              ))}
            </div>
            <div className={S.tickets_box}>
              {childrenCountState.map((el, index) => (
                <div
                  aria-label={`${t('13016')} ${index + 1}`}
                  key={index}
                  tabIndex={0}
                  onClick={() => onActiveTicketChange(index, false)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onActiveTicketChange(index, false);
                    }
                  }}
                >
                  <Ticket
                    isAdult={false}
                    index={index + 1}
                    isActive={activeTicket.index === index && !activeTicket.isAdult}
                    discountCode={el.discountCode}
                    employeeData={employeeData}
                    onEmployeeIdChange={onEmployeeIdChange}
                    onEmployerCodeChange={onEmployerCodeChange}
                    railwayEmplIdStatus={railwayEmplIdStatus}
                    employerSearcherRef={employerSearcherRef}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={S.right_column}>
            <div className={S.header} tabIndex={-1} ref={ref} id="focusTarget">
              <img src={isContrastTheme ? ProfileContrast : Profile} alt={t('22015')} />
              <h2 aria-live="polite">
                {(activeTicket.isAdult ? t('13015') : t('13016')) + ' ' + (activeTicket.index + 1)}: {t('13031')}
              </h2>
            </div>
            <h3>{t('13033')}</h3>
            <p>{t('13034')}</p>
            <div className={S.inputDateBox}>
              <DiscountsAgePicker
                min={1}
                max={setDaysToDisplay(inputMonth, inputYear)}
                placeholder={t('13051')}
                value={inputDay}
                callback={(e) => setInputDay(e.target.value)}
                callback2={setInputDay}
                type="number"
                autoComplete="birthDay"
                id="birthDay"
                label={t('13052')}
              />
              <DiscountsAgePicker
                min={1}
                max={12}
                placeholder={t('13050')}
                value={inputMonth}
                callback={(e) => setInputMonth(e.target.value)}
                callback2={setInputMonth}
                type="text"
                autoComplete="bityhMonth"
                id="bityhMonth"
                label={t('13054')}
              />
              <DiscountsAgePicker
                min={1900}
                max={new Date().getFullYear()}
                placeholder={t('13047')}
                value={inputYear}
                callback={(e) => setInputYear(e.target.value)}
                callback2={setInputYear}
                type="number"
                autoComplete="birthYear"
                id="birthYear"
                label={t('13056')}
              />
            </div>
            <div
              className={`${S.blueAlert} ${
                isPassengerAdult(passengerAge) && !activeTicket.isAdult ? S.showAlert : S.hideAlert
              }`}
            >
              {t('13057')}
            </div>
            <div className={S.minor_text_box}>
              <h3>{t('13058')}</h3>
              <p>
                {t('13059')}
                <span>{activeTicket.isAdult ? ` ${t('13015')} ` : ` ${t('13016')} `}</span>
                <span>{activeTicket.index + 1}</span>
              </p>
              {selectedDiscount && (
                <p>
                  {t('13062')} <span>{selectedDiscount.opisy[discountListLanguageMapper(i18n.language)].nazwa}</span>
                </p>
              )}
            </div>
            <label htmlFor="searchDiscounts" className="hiddenLabel">
              {t('13063')}
            </label>
            <input
              id="searchDiscounts"
              value={searchInput}
              className={`${S.discount_search_input} ${isContrastTheme && S.contrast}`}
              type="text"
              placeholder={t('13064')}
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <div className={S.discounts_box}>
              {recommendedDisc.length === 0 && mostPopularDiscs.length > 0 && searchInput.length < 2 && (
                <>
                  <h4>{t('13065')}</h4>
                  <DiscountList
                    list={mostPopularDiscs.slice(0, 3)}
                    selectedDiscount={selectedDiscount}
                    isUserLoggedIn={isLoggedIn}
                    onDiscountChange={handleDiscountChange}
                  />
                </>
              )}
              {recommendedDisc.length > 0 && searchInput.length < 2 && (
                <>
                  <h4>{t('13068')}</h4>
                  <DiscountList
                    list={recommendedDisc}
                    selectedDiscount={selectedDiscount}
                    isUserLoggedIn={isLoggedIn}
                    onDiscountChange={handleDiscountChange}
                  />
                </>
              )}
              <h4>{t('13069')}</h4>
              {mostPopularDiscs.length > 0 && (
                <>
                  {searchInput.length < 2 ? (
                    <DiscountList
                      list={filteredDiscs.length === 0 ? mostPopularDiscs.slice(3) : filteredDiscs}
                      selectedDiscount={selectedDiscount}
                      isUserLoggedIn={isLoggedIn}
                      onDiscountChange={handleDiscountChange}
                    />
                  ) : (
                    <DiscountList
                      list={filteredDiscs}
                      isUserLoggedIn={isLoggedIn}
                      selectedDiscount={selectedDiscount}
                      onDiscountChange={handleDiscountChange}
                    />
                  )}
                  <div
                    className={`${S.blueAlert} ${
                      filteredDiscs?.length === 0 && searchInput.length > 1 ? S.showAlert : S.hideAlert
                    }`}
                  >
                    {t('13070')}
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        {checkDiscountsError && (
          <div style={{marginTop: '20px'}}>
            <ErrorYellow displayText={checkDiscountsError} />
          </div>
        )}
        <div className={S.setdiscountdata_buttons} id="discountsConfirmationBtnTarget" tabIndex={-1}>
          <ScrollTarget name="discountsConfirmationBtn">
            <ConfirmButton
              text={t('13071')}
              execute={validateDiscounts}
              disabled={
                (offersModalError && offersModalError.length > 0) ||
                discountQuantityError ||
                !areAllDiscountsSelected(adultCountState, childrenCountState)
              }
            />
          </ScrollTarget>
          <div>
            <OutlineButton text={t('13072')} path={handleBackButton} />
          </div>
        </div>
        {openModal && (
          <div className={S.confirmModal}>
            <ConfirmationModal
              closeFn={handleCloseModal}
              confirmFn={handleConfirmModal}
              customCancelBtnTxt={t('29404')}
            >
              <p>{getConfirmationMsg()}</p>
              <p>
                {offersModalError && offersModalError.length > 0
                  ? `${t('29403')} ${offersNoCheck} ${getOffersNamesFromModalError(offersModalError)}?`
                  : t('29410')}
              </p>
            </ConfirmationModal>
          </div>
        )}
      </div>
    );
  },
);

export default SetDiscounts;
