import {styled} from '@mui/material';

export const ModalWrapper = styled('div')`
  min-width: 85rem;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  gap: 1.6rem;
`;

export const ModalHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`;

export const TicketList = styled('div')`
  max-height: 80rem;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
