import React from 'react';
import {MyTicket, MyTicketIdentifier} from 'common/api/myTickets';
import {useMyTicketsState} from '../../hooks/useTickets';
import {useMyTicketDispatch} from '../../hooks/useTicket';
import {useRefreshTicketDetails} from '../../hooks/useRefreshTicketDetails';
import {isSeasonalVariant, isReturnedVariant} from '../../MyTicketsList.utils';
import {TicketComponent} from './TicketComponent';
import {isSeasonalOfferCode} from 'common/utils';

interface TicketProps {
  identifier: MyTicketIdentifier;
  tickets: MyTicket[];
}

export const Ticket = React.memo(({identifier, tickets}: TicketProps) => {
  const {ticketVariant, isHistoryVariant, isMobileVersion} = useMyTicketsState();
  const {setTicketDetails} = useMyTicketDispatch();

  /* 
    Because when you click on the tile, the ticket details are displayed and that's when the data is transferred to the context,
    so when an additional ticket is returned, the data is not refreshed.
    The hook below refreshes the ticket details when it change.
  */
  useRefreshTicketDetails(identifier, tickets);

  const isSeasonal = React.useMemo(
    () => isSeasonalVariant(ticketVariant) || isSeasonalOfferCode(tickets[0]?.ofertaKod),
    [ticketVariant, tickets],
  );
  const isReturned = React.useMemo(
    () => isReturnedVariant(ticketVariant) || !!identifier.zwrocony, // Additional condition resulting from tickets returned incorrectly by the API.
    [ticketVariant, identifier],
  );

  // Sometimes the API method 'pobierzBilet' returns an empty array despite the identifier returned by the method 'pobierzIdentyfikatoryBiletow'.
  if (tickets.length === 0) {
    return null;
  }

  return (
    <TicketComponent
      isMobile={isMobileVersion}
      identifier={identifier}
      tickets={tickets}
      isHistoryVariant={isHistoryVariant}
      isReturned={isReturned}
      isSeasonal={isSeasonal}
      onMobileTileClick={() => setTicketDetails(identifier, tickets)}
    />
  );
});
