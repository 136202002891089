import {MyTicketsListVariants} from './MyTicketsList.types';
import {
  myTicketsListSeasonalVariants,
  myTicketsListReturnedVariants,
  myTicketsListHistoryVariants,
  additionalTicketKinds,
  primaryTicketKinds,
} from './MyTicketsList.consts';
import {TicketKind, ticketKinds} from 'common/api/myTickets';

export const isSeasonalVariant = (ticketVariant: MyTicketsListVariants) => {
  return myTicketsListSeasonalVariants.includes(ticketVariant);
};

export const isReturnedVariant = (ticketVariant: MyTicketsListVariants) => {
  return myTicketsListReturnedVariants.includes(ticketVariant);
};

export const isHistoryVariant = (ticketVariant: MyTicketsListVariants) => {
  return myTicketsListHistoryVariants.includes(ticketVariant);
};

export const isAdditionalTicketKind = (ticketKind: number) => {
  return additionalTicketKinds.includes(ticketKind as TicketKind);
};

export const isPrimaryTicketKind = (ticketKind: number) => {
  return primaryTicketKinds.includes(ticketKind as TicketKind);
};

export const isValidTicketKind = (ticketKind: number) => {
  return Object.values(ticketKinds).includes(ticketKind as TicketKind);
};
