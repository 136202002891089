import {MyTicket, MyTicketIdentifier} from 'common/api/myTickets';
import MyTicketsSingleTicket from 'components/pages/UserProfile/components/MyTickets/MyTicketsSingleTicket';
import MyTicketsSingleTicketMobile from 'components/pages/UserProfile/components/MyTickets/MyTicketsSingleTicketMobile';

export type TicketComponentVariant =
  | 'reservationListModal'
  | 'seasonalTicketReminderModal'
  | 'seasonalTicketInformationModal'
  | 'seasonalTicketReminderDrawer';

interface TicketComponentProps {
  isMobile: boolean;
  identifier: MyTicketIdentifier;
  tickets: MyTicket[];
  isHistoryVariant: 0 | 1;
  isReturned: boolean;
  isSeasonal: boolean;
  onMobileTileClick?: () => void;
  variant?: TicketComponentVariant;
}

export const TicketComponent = ({
  isMobile,
  identifier,
  tickets,
  isHistoryVariant,
  isReturned,
  isSeasonal,
  onMobileTileClick,
  variant,
}: TicketComponentProps) => {
  return isMobile ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={onMobileTileClick}>
      <MyTicketsSingleTicketMobile
        tickets={tickets}
        history={isHistoryVariant || isReturned ? 1 : 0}
        isSeasonal={isSeasonal}
        variant={variant}
      />
    </div>
  ) : (
    <MyTicketsSingleTicket
      identifier={identifier}
      tickets={tickets}
      history={isHistoryVariant || isReturned ? 1 : 0} // Additional condition resulting from tickets returned incorrectly by the API.
      isSeasonal={isSeasonal}
      isReturned={isReturned}
      variant={variant}
    />
  );
};
