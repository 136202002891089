import {useState, useEffect, Suspense, lazy} from 'react';
import {BrowserRouter} from 'react-router-dom';
import DataProvider from 'Utils/DataContex';
import AuthProvider from 'Utils/AuthContext';
import UserTicketProvider from 'Utils/UserTicketsContext';
import {AppRouting} from 'routing';
import {SWRConfig} from 'swr';
import Layout from 'components/common/Layout/Layout';
import CenteredLoader from 'components/common/Loader/CenteredLoader';
import {StyledThemeProvider} from 'common/theme';
import {QueryClientProvider} from '@tanstack/react-query';
import ReservationProvider from 'Utils/ReservationContext';
import {queryClient} from 'common/api';
import ShoppingBasketProvider from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketContext';
import {env} from 'env';
import GoogleTagManager from 'Utils/googleTagManager/googleTagManager';

GoogleTagManager.initialize(env.REACT_APP_GTM_ID);

const LazyPaymentConfirmationModal = lazy(
  () => import('components/pages/PurchasePath/PaymentConfirmationModal/PaymentConfirmationModal'),
);

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    window.location.href.indexOf('pobierzbilet') > -1 && setIsModalOpen(true);
  }, []);

  return (
    <SWRConfig>
      <StyledThemeProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <DataProvider>
              <UserTicketProvider>
                <ShoppingBasketProvider>
                  <ReservationProvider>
                    <BrowserRouter>
                      <Suspense fallback={<CenteredLoader />}>
                        <Layout>
                          <AppRouting />
                          {isModalOpen && <LazyPaymentConfirmationModal handleCloseModal={closeModal} />}
                        </Layout>
                      </Suspense>
                    </BrowserRouter>
                  </ReservationProvider>
                </ShoppingBasketProvider>
              </UserTicketProvider>
            </DataProvider>
          </AuthProvider>
        </QueryClientProvider>
      </StyledThemeProvider>
    </SWRConfig>
  );
};

export default App;
