import {ChangeEvent, FC} from 'react';
import MaskedInput from 'react-text-mask';
import {useTranslation} from 'react-i18next';

import {IPaymentInvoiceData} from 'interfaces/forms';

import style from './Invoice.module.scss';
import Checked from 'assets/orangeCheckboxIcon.svg';
import ArrowBottom from 'assets/orangeArrowBottomDesktop.svg';

interface Props {
  values: IPaymentInvoiceData;
  handleInputChange(e: ChangeEvent<HTMLInputElement>): void;
  validationErrors: {[p: string]: string};
}

const GUSData: FC<Props> = ({values, handleInputChange, validationErrors}) => {
  const {t} = useTranslation();
  return (
    <>
      <div className={style.data_found_text}>
        <img src={Checked} alt={t('26024')} />
        {t('26026')}
      </div>
      <section className="grid">
        <div className={`col-12 ${style.input_box} ${validationErrors?.companyName ? style.inputBoxError : ''}`}>
          <input type="text" name="companyName" value={values.companyName} onChange={handleInputChange} />
          <label htmlFor="companyName" className={style.company_invoice_label}>
            {t('26027')}
            <span className={style.requiredRedStar}>*</span>
          </label>
          {validationErrors?.companyName && validationErrors?.companyName !== 'Empty' && (
            <div className={style.validationError}>{validationErrors?.companyName}</div>
          )}
        </div>
        <div className={`col-12 ${style.input_box} ${validationErrors?.companyStreet ? style.inputBoxError : ''}`}>
          <input type="text" name="companyStreet" value={values.companyStreet} onChange={handleInputChange} />
          <label htmlFor="companyStreet" className={style.company_invoice_label}>
            {t('26028')}
            <span className={style.requiredRedStar}>*</span>
          </label>
          {validationErrors?.companyStreet && validationErrors?.companyStreet !== 'Empty' && (
            <div className={style.validationError}>{validationErrors?.companyStreet}</div>
          )}
        </div>
        <div
          className={`col-6 col-sm-12 ${style.input_box} ${validationErrors?.companyHouseNumber ? style.inputBoxError : ''}`}
        >
          <input
            type="text"
            name="companyHouseNumber"
            value={`${values.companyHouseNumber}`}
            onChange={handleInputChange}
          />
          <label htmlFor="companyHouseNumber" className={style.company_invoice_label}>
            {t('26029')}
            <span className={style.requiredRedStar}>*</span>
          </label>
        </div>
        <div className={`col-6 col-sm-12 ${style.input_box}`}>
          <input
            type="text"
            name="companyLocalNumber"
            value={`${values.companyLocalNumber}`}
            onChange={handleInputChange}
          />
          <label htmlFor="companyLocalNumber" className={style.company_invoice_label}>
            {t('26030')}
          </label>
        </div>
        <div className={`col-12 ${style.input_box} ${validationErrors?.companyZipCode ? style.inputBoxError : ''}`}>
          <MaskedInput
            mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            guide={false}
            type="text"
            inputMode="numeric"
            id="companyZipCode"
            name="companyZipCode"
            value={values.companyZipCode}
            onChange={handleInputChange}
          />
          <label htmlFor="companyZipCode" className={style.company_invoice_label}>
            {t('26031')}
            <span className={style.requiredRedStar}>*</span>
          </label>
        </div>
        <div className={`col-12 ${style.input_box} ${validationErrors?.companyCity ? style.inputBoxError : ''}`}>
          <input type="text" name="companyCity" value={values.companyCity} onChange={handleInputChange} />
          <label htmlFor="companyCity" className={style.company_invoice_label}>
            {t('26032')}
            <span className={style.requiredRedStar}>*</span>
          </label>
          {validationErrors?.companyCity && validationErrors?.companyCity !== 'Empty' && (
            <div className={style.validationError}>{validationErrors?.companyCity}</div>
          )}
        </div>
        <div className={`col-12 ${style.select_box}`}>
          <select className={`col-12 ${style.custom_select}`} disabled>
            <option value="PL">{t('26033')}</option>
          </select>
          <img src={ArrowBottom} alt={t('26034')} />
        </div>
      </section>
    </>
  );
};

export default GUSData;
