import {FC, ReactNode, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import newBackground from 'assets/layout/newBackground.png';

import TopMenu from '../TopMenu';
import FooterDesktop from '../FooterDesktop';
import Footer from '../Footer';
import ShoppingBasketCounterWithBadge from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketCounterWithBadge/ShoppingBasketCounterWithBadge';
import {SEASONAL_OFFER_CODE_KINDS} from 'common/consts';
import {useWindowResize} from 'common/hooks/screen';
import {useStyledThemeState} from 'common/theme';
import S from './Layout.module.scss';
import VersionInfo from 'components/common/VersionInfo/VersionInfo';

interface Props {
  children?: ReactNode;
}

const Layout: FC<Props> = ({children}) => {
  const [deviceWidth, setDeviceWidth] = useState(0);
  const {isMobile} = useWindowResize();
  const {isContrastTheme} = useStyledThemeState();

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      setDeviceWidth(window.innerWidth);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [deviceWidth]);

  const location = useLocation();

  const getBackground = () => {
    if (isMobile) return {};
    if (
      (location.pathname.includes('/biletokresowy') &&
        location.pathname !== `/biletokresowy/${SEASONAL_OFFER_CODE_KINDS.PERIODIC}`) ||
      location.pathname === '/wybormiejsc' ||
      location.pathname === '/podsumowanie' ||
      location.pathname === '/rezerwacje/podsumowanie' ||
      location.pathname === '/rezerwacje/wybormiejsc'
    ) {
      return {
        background: `url(${newBackground})`,
        backgroundColor: `${isContrastTheme ? 'transparent' : '#f4f9fb'}`,
        backgroundPosition: 'bottom center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };
    }

    return {background: 'none'};
  };

  const shouldDisplayVersion = !isMobile || (isMobile && location.pathname === '/profil');
  return (
    <>
      {!isMobile && !location.pathname.includes('login') && <TopMenu />}
      <main className={S.layoutContainer} style={getBackground()}>
        {children}
      </main>
      {isMobile ? (
        <>
          <ShoppingBasketCounterWithBadge />
          <Footer />
        </>
      ) : (
        <>
          {!location.pathname.includes('login') && (
            <div className={S.footerDesktop}>
              <FooterDesktop />
            </div>
          )}
        </>
      )}
      {shouldDisplayVersion && <VersionInfo />}
    </>
  );
};

export default Layout;
