import {useState, useEffect} from 'react';

import {IAmenities} from 'api/pociagi/interfaces';
import {useData} from 'Utils/DataContex';
import {TrainCategory} from 'common/api/localData';
import {useAmenitiesActions, useAmenitiesState} from 'common/hooks/useAmenities';

const useAmenities = (
  setOpen: (e: boolean) => void,
  amenitiesObj: IAmenities,
  setAmenities: (e: IAmenities) => void,
  hideTrainsCategorySelection?: boolean,
) => {
  const [yellowBar, setYellowBar] = useState(false);
  const [localAmenities, setLocalAmenities] = useState(amenitiesObj);

  const {placeTypes, railwayCompanies} = useData();

  const {contextAmenities, showMinimumTimeFilter, minimumTransferTotalMinutes} = useAmenitiesState();
  const {setContextAmenities, setMinimumTransferTotalMinutes, setShowMinimumTimeFilter} = useAmenitiesActions();

  const [temporaryShowMinimumTimeFilter, setTemporaryShowMinimumTimeFilter] = useState<boolean>(showMinimumTimeFilter);
  const [temporaryMinimumTransferTotalMinutes, setTemporaryMinimumTransferTotalMinutes] =
    useState<number>(minimumTransferTotalMinutes);
  const [temporaryDirectConnection, setTemporaryDirectConnection] = useState<boolean>(
    localAmenities.polaczeniaBezposrednie === 1 ? true : false,
  );

  const chekIfShowYellowBar = (hideTrainsCategorySelection: boolean = false) => {
    let check = false;
    for (const key in localAmenities) {
      const arr = localAmenities[key as keyof typeof localAmenities];
      if (
        typeof arr === 'object' &&
        arr.length &&
        key !== 'kodyPrzewoznikow' &&
        (hideTrainsCategorySelection ? key !== 'kategoriePociagow' : true)
      ) {
        check = true;
      } else if (typeof arr === 'object' && arr.length > 1 && key === 'kodyPrzewoznikow') {
        check = true;
      } else if (typeof arr !== 'object' && arr !== 0) {
        check = true;
      }
    }
    setYellowBar(check);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchForId = (name: string, arr: any) => {
    if (arr.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const serchedEl = arr.filter((el: any) => {
        let check = false;
        if (el.opisy) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          el.opisy.forEach((el2: any) => {
            if (el2.nazwa === name) {
              check = true;
            }
          });
        } else {
          if (el.nazwa === name) check = true;
        }
        if (check) return el;
      });
      if (serchedEl.length) {
        return serchedEl[0].kod;
      }
      return false;
    }
  };

  const removeTypeFromArray = (id: number) => {
    const filteredArr = localAmenities.typyMiejsc.filter((number) => number !== id);
    setLocalAmenities({
      ...localAmenities,
      typyMiejsc: [...filteredArr],
    });
  };

  const managePlaceTypes = (name: string, bool: boolean, code: number) => {
    const id = searchForId(name, placeTypes);
    if (bool) {
      if (id) {
        setLocalAmenities({
          ...localAmenities,
          typyMiejsc: [...localAmenities.typyMiejsc, id ? id : code],
        });
      }
    } else {
      removeTypeFromArray(id ? id : code);
    }
  };

  const selectedType = (name: string, number: number) => {
    const id = searchForId(name, placeTypes) as number;
    const arr = localAmenities.typyMiejsc as number[];
    if (id && arr) return arr.includes(id);
    else if (id! && number && arr) return arr.includes(number);
    return false;
  };

  const selectedPlaces = (number: number) => {
    const arr = localAmenities.rodzajeMiejsc as number[];
    return arr.includes(number);
  };

  const setFrequency = (value: boolean) => {
    setLocalAmenities({
      ...localAmenities,
      pokazFrekwencje: value ? 1 : 0,
    });
  };
  const setDirectConnection = (directConnectionSelected: boolean) => {
    directConnectionSelected && setTemporaryDirectConnection(false);
    setLocalAmenities({
      ...localAmenities,
      polaczeniaBezposrednie: directConnectionSelected ? 1 : 0,
    });
  };
  const setMinimumTransferTime = (value: number) => {
    setLocalAmenities({
      ...localAmenities,
      czasNaPrzesiadkeMin: showMinimumTimeFilter ? value : 3,
    });
  };
  const setBraileSigns = (value: boolean) => {
    setLocalAmenities({
      ...localAmenities,
      braille: value ? 1 : 0,
    });
  };

  const setRestingPlaces = (value: boolean) => {
    setLocalAmenities({
      ...localAmenities,
      rodzajeMiejsc: value
        ? [...localAmenities.rodzajeMiejsc, 2]
        : [...localAmenities.rodzajeMiejsc.filter((el) => el !== 2)],
      typyMiejsc: [
        ...localAmenities.typyMiejsc.filter((el) => {
          return (
            searchForId('Strefa Ciszy', placeTypes) !== el &&
            el !== 52 &&
            el !== searchForId('Miejsce dla podróżnego z rowerem', placeTypes) &&
            el !== 24
          );
        }),
      ],
    });
  };

  const setSleepingPlaces = (value: boolean) => {
    setLocalAmenities({
      ...localAmenities,
      rodzajeMiejsc: value
        ? [...localAmenities.rodzajeMiejsc, 3]
        : [...localAmenities.rodzajeMiejsc.filter((el) => el !== 3)],
      typyMiejsc: [
        ...localAmenities.typyMiejsc.filter((el) => {
          return (
            searchForId('Strefa Ciszy', placeTypes) !== el &&
            el !== 52 &&
            el !== searchForId('Miejsce dla podróżnego z rowerem', placeTypes) &&
            el !== 24
          );
        }),
      ],
    });
  };

  const setBikeSpace = (bool: boolean) => {
    if (bool) {
      const bikeSpace = searchForId('Miejsce dla podróżnego z rowerem', placeTypes);
      setLocalAmenities({
        ...localAmenities,
        rodzajeMiejsc: [],
        typyMiejsc: [
          ...localAmenities.typyMiejsc.filter((el) => {
            return searchForId('Strefa Ciszy', placeTypes) !== el && el !== 52;
          }),
          bikeSpace ? bikeSpace : 24,
        ],
      });
    } else {
      managePlaceTypes('Miejsce dla podróżnego z rowerem', bool, 24);
    }
  };

  const setWheelChairSpace = (bool: boolean) => {
    managePlaceTypes('Miejsce dla osoby na wózku', bool, 6);
  };

  const setMoveDisabilitySpace = (bool: boolean) => {
    managePlaceTypes('Miejsce dla OzN - nie na wózku', bool, 2);
  };

  const setCarForFamily = (bool: boolean) => {
    managePlaceTypes('Przedział dla osoby z dzieckiem do lat 6', bool, 3);
  };

  const setQuietZone = (bool: boolean) => {
    if (bool) {
      const quiteZone = searchForId('Strefa Ciszy', placeTypes);
      setLocalAmenities({
        ...localAmenities,
        rodzajeMiejsc: [],
        typyMiejsc: [
          ...localAmenities.typyMiejsc.filter((el) => {
            return searchForId('Miejsce dla podróżnego z rowerem', placeTypes) !== el && el !== 24;
          }),
          quiteZone ? quiteZone : 25,
        ],
      });
    } else {
      managePlaceTypes('Strefa Ciszy', bool, 52);
    }
  };

  const selectedTrainSymbol = (name: string) => {
    const arr = localAmenities.kategoriePociagow as string[];
    if (arr.includes(name)) return true;
    return false;
  };

  const showSelectedCarriers = (name: string, id: number) => {
    const foundedId = searchForId(name, railwayCompanies) as number;
    const arr = localAmenities.kodyPrzewoznikow as number[];
    return !!arr.includes(foundedId ? foundedId : id);
  };

  const handleClose = () => {
    contextAmenities && setLocalAmenities(contextAmenities);
    setTemporaryShowMinimumTimeFilter(showMinimumTimeFilter);
    setTemporaryDirectConnection(localAmenities.polaczeniaBezposrednie === 1 ? true : false);
    setOpen(false);
  };
  const saveDataAmenities = () => {
    const amenities = {
      ...localAmenities,
      czasNaPrzesiadkeMin: temporaryShowMinimumTimeFilter ? temporaryMinimumTransferTotalMinutes : 3,
      polaczeniaBezposrednie: temporaryDirectConnection ? 1 : 0,
    };
    setShowMinimumTimeFilter(temporaryShowMinimumTimeFilter);
    !temporaryShowMinimumTimeFilter && setMinimumTransferTotalMinutes(3);
    setAmenities(amenities);
    setContextAmenities(amenities);
    setOpen(false);
  };

  const filterTrains = (arr: TrainCategory[]) => {
    const newArr = [] as TrainCategory[];
    let idEIP: number = 0;
    arr.forEach((el, index) => {
      if (el.nazwa === 'EIP') idEIP = index;
      const checkArr = newArr.filter((el2) => {
        return el2.nazwa === el.nazwa;
      });
      if (!checkArr.length) {
        newArr.push(el);
      }
    });

    // Move EIP to the first position in the array //
    const toIndex = 0;
    const pulledEIP = newArr.splice(idEIP, 1)[0];
    newArr.splice(toIndex, 0, pulledEIP);

    return newArr;
  };

  const showIfSleepOrLaying = () => {
    if (localAmenities.rodzajeMiejsc.length) {
      const arr = localAmenities.rodzajeMiejsc as number[];
      if (arr.includes(3) || arr.includes(2)) {
        return true;
      }
      return false;
    }
    return false;
  };

  const setFakeNumber = (checked: boolean, num: number) => {
    if (localAmenities.fakeNumberToCounter) {
      setLocalAmenities({
        ...localAmenities,
        fakeNumberToCounter: checked
          ? [...localAmenities.fakeNumberToCounter, num]
          : [...localAmenities.fakeNumberToCounter.filter((el) => el !== num)],
      });
    }
  };
  useEffect(() => {
    temporaryShowMinimumTimeFilter && setTemporaryDirectConnection(false);
  }, [temporaryShowMinimumTimeFilter]);
  useEffect(() => {
    temporaryDirectConnection && setTemporaryShowMinimumTimeFilter(false);
  }, [temporaryDirectConnection]);
  useEffect(() => {
    chekIfShowYellowBar(hideTrainsCategorySelection);
    !!localAmenities.polaczeniaBezposrednie && setTemporaryShowMinimumTimeFilter(false);
  }, [localAmenities]);
  useEffect(() => {
    temporaryShowMinimumTimeFilter && setTemporaryDirectConnection(false);
  }, [temporaryShowMinimumTimeFilter]);
  return {
    yellowBar,
    localAmenities,
    setLocalAmenities,
    setRestingPlaces,
    chekIfShowYellowBar,
    searchForId,
    selectedType,
    selectedPlaces,
    setFrequency,
    setDirectConnection,
    setBraileSigns,
    setSleepingPlaces,
    setBikeSpace,
    setWheelChairSpace,
    setMoveDisabilitySpace,
    setCarForFamily,
    setQuietZone,
    selectedTrainSymbol,
    showSelectedCarriers,
    handleClose,
    saveDataAmenities,
    filterTrains,
    showIfSleepOrLaying,
    setFakeNumber,
    setMinimumTransferTime,
    temporaryShowMinimumTimeFilter,
    setTemporaryShowMinimumTimeFilter,
    temporaryDirectConnection,
    setTemporaryDirectConnection,
    temporaryMinimumTransferTotalMinutes,
    setTemporaryMinimumTransferTotalMinutes,
  };
};

export default useAmenities;
