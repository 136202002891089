import React from 'react';
import {useNavigate} from 'react-router-dom';
import {MyTicket, ticketKinds} from 'common/api/myTickets';
import {differenceInCalendarDays} from 'date-fns';
import {getStationByCode} from 'common/utils';
import {useData} from 'Utils/DataContex';

const NUMBER_OF_DAYS_OF_ADULT = 14610; // This is how many days an passenger must have on the day of purchasing the ticket to be considered an adult on international connections, if the number of days is different, the passenger is a child.

export class Passenger {
  dataUrodzenia: string | null;

  constructor(dataUrodzenia: string | null) {
    this.dataUrodzenia = dataUrodzenia;
  }
}

export const useSearchTicket = (ticket: MyTicket) => {
  const navigate = useNavigate();
  const {stationsList} = useData();
  const {podrozni: passengers, odcinki} = ticket;
  const isForeignTicket = ticket.biletRodzaj === ticketKinds.INTERNATIONAL_EPA;

  const searchTicket = React.useCallback(
    (query: string, returnTrip?: boolean) => {
      if (isForeignTicket) {
        const ticketSalteDate = new Date(ticket.sprzedazData);

        const departure = getStationByCode(odcinki[0].stacjaOdKod, stationsList);
        const destination = getStationByCode(odcinki[odcinki.length - 1].stacjaDoKod, stationsList);

        const departureStation = returnTrip ? destination : departure;
        const destinationStation = returnTrip ? departure : destination;

        const adults = passengers
          .filter(
            (passenger) =>
              differenceInCalendarDays(ticketSalteDate, new Date(passenger.dataUrodzenia)) === NUMBER_OF_DAYS_OF_ADULT,
          )
          .map(() => new Passenger(null));

        const childrens = passengers
          .filter(
            (passenger) =>
              differenceInCalendarDays(ticketSalteDate, new Date(passenger.dataUrodzenia)) !== NUMBER_OF_DAYS_OF_ADULT,
          )
          .map((children) => new Passenger(children.dataUrodzenia));

        navigate(query, {
          state: {
            numberOfAdults: adults.length,
            numberOfChildrens: childrens.length,
            adults,
            childrens,
            departureStation,
            destinationStation,
          },
        });
      } else {
        navigate(query);
      }
    },
    [isForeignTicket, ticket.sprzedazData, odcinki, stationsList, passengers, navigate],
  );

  return {searchTicket} as const;
};
