import {useReducer, createContext, useContext, ReactNode, useCallback, useMemo, Dispatch, FC} from 'react';
import {IAmenities} from 'api/pociagi/interfaces';
import {exhaustiveCheck} from 'common/utils';
import {AmenitiesState, Action, AmenitiesActions} from 'common/types/amenities';

const AmenitiesStateContext = createContext<AmenitiesState | undefined>(undefined);
const AmenitiesDispatchContext = createContext<Dispatch<Action> | undefined>(undefined);

const reducer = (state: AmenitiesState, action: Action): AmenitiesState => {
  switch (action.type) {
    case AmenitiesActions.SET_AMENITIES:
      return {...state, contextAmenities: action.payload};
    case AmenitiesActions.SET_SHOW_MINIMUM_TIME_FILTER:
      return {...state, showMinimumTimeFilter: action.payload};
    case AmenitiesActions.SET_MINIMUM_TRANSFER_TIME:
      return {...state, minimumTransferTotalMinutes: action.payload};
    default:
      return exhaustiveCheck(action, 'Unhandled Amenities action type');
  }
};

interface AmenitiesProviderProps {
  children: ReactNode;
}
export const initialState: AmenitiesState = {
  contextAmenities: null,
  showMinimumTimeFilter: false,
  minimumTransferTotalMinutes: 3,
};

export const AmenitiesProvider: FC<AmenitiesProviderProps> = ({children}) => {
  const [amenitiesState, dispatch] = useReducer(reducer, initialState);

  const stateValue = useMemo(() => amenitiesState, [amenitiesState]);
  const setAmenitiesState = useMemo(() => dispatch, [dispatch]);

  return (
    <AmenitiesStateContext.Provider value={stateValue}>
      <AmenitiesDispatchContext.Provider value={setAmenitiesState}>{children}</AmenitiesDispatchContext.Provider>
    </AmenitiesStateContext.Provider>
  );
};

export const useAmenitiesState = (): AmenitiesState => {
  const context = useContext(AmenitiesStateContext);
  if (!context) {
    throw new Error('useAmenitiesState must be used within the AmenitiesProvider');
  }
  return context;
};

export const useAmenitiesDispatch = (): Dispatch<Action> => {
  const context = useContext(AmenitiesDispatchContext);
  if (!context) {
    throw new Error('useAmenitiesDispatch must be used within the AmenitiesProvider');
  }
  return context;
};

export const useAmenitiesActions = () => {
  const dispatch = useAmenitiesDispatch();

  const setContextAmenities = useCallback(
    (amenities: IAmenities | null) => {
      dispatch({type: AmenitiesActions.SET_AMENITIES, payload: amenities});
    },
    [dispatch],
  );

  const setShowMinimumTimeFilter = useCallback(
    (show: boolean) => {
      dispatch({type: AmenitiesActions.SET_SHOW_MINIMUM_TIME_FILTER, payload: show});
    },
    [dispatch],
  );

  const setMinimumTransferTotalMinutes = useCallback(
    (minutes: number) => {
      dispatch({type: AmenitiesActions.SET_MINIMUM_TRANSFER_TIME, payload: minutes});
    },
    [dispatch],
  );

  return {setContextAmenities, setShowMinimumTimeFilter, setMinimumTransferTotalMinutes};
};
