import {useState, useEffect} from 'react';
import {MOBILE_BREAKPOINT} from 'common/consts';
import {WindowResize} from './useWindowResize.types';
import {isMobile} from 'react-device-detect';
export const useWindowResize = () => {
  const [windowResize, setWindowResize] = useState<WindowResize>({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: isMobile || window.innerWidth <= MOBILE_BREAKPOINT,
  });

  const updateSize = () => {
    setWindowResize({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: isMobile || window.innerWidth <= MOBILE_BREAKPOINT,
    });
  };
  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return windowResize;
};
