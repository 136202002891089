export const MESSAGE_CODES = {
  AVAILABLE_DIFFERENT_CATEGORIES: 10,
  UNAVAILABLE_IN_ANY_CLASS_SECTION_1: 11,
  UNAVAILABLE_IN_ONE_CLASS_SECTION_1: 12,
  UNAVAILABLE_IN_ANY_CLASS_SECTION_2: 111,
  UNAVAILABLE_IN_ONE_CLASS_SECTION_2: 112,
  NO_SEATS_AVAILABLE: 200,
  // TODO: Add additional codes when documentation from IC becomes available
} as const;

export const UNAVAILABLE_TICKET_MESSAGE_CODES = [
  MESSAGE_CODES.NO_SEATS_AVAILABLE,
  MESSAGE_CODES.UNAVAILABLE_IN_ANY_CLASS_SECTION_1,
  MESSAGE_CODES.UNAVAILABLE_IN_ONE_CLASS_SECTION_1,
  MESSAGE_CODES.UNAVAILABLE_IN_ANY_CLASS_SECTION_2,
  MESSAGE_CODES.UNAVAILABLE_IN_ONE_CLASS_SECTION_2,
] as const;
