import {ReactElement} from 'react';
import {BuyerProfileContextProvider} from 'Utils/BuyerProfileContext';
import BuyerProfile from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfile';
import {AmenitiesProvider} from 'common/hooks/useAmenities';

const Index = (): ReactElement => {
  return (
    <BuyerProfileContextProvider>
      <AmenitiesProvider>
        <BuyerProfile />
      </AmenitiesProvider>
    </BuyerProfileContextProvider>
  );
};

export default Index;
