import {Station} from 'common/api/localData';
import {CodeEVASchema} from 'common/schemas/EVA';
import {CodeIBNRSchema} from 'common/schemas/IBNR';

export const getStationByCode = (code: number, stationsList: Station[]): Station | undefined =>
  code <= 0
    ? undefined
    : stationsList.find((station: Station): boolean =>
        CodeIBNRSchema('code').safeParse(code).success
          ? station.kod === code
          : CodeEVASchema('code').safeParse(code).success
            ? station.kodEVA === code
            : station.kodEPA === code,
      );

export const getStationByCodeIBNR = (code: number, stationsList: Station[]): Station | undefined =>
  code ? stationsList.find((station: Station): boolean => station.kod === code) : undefined;

export const isMetaStationByCodeEVA = (stationCode: number | string): boolean => {
  const {success} = CodeEVASchema('isMetaStation').safeParse(stationCode);
  return success;
};

export const isValidCodeEVA = (code: string | number): boolean => {
  const {success} = CodeEVASchema('code').safeParse(code);
  return success;
};

export const isForeignStationByCodeEVA = (stationCode: string | number): boolean => {
  const {success} = CodeEVASchema('isForeignStation').safeParse(stationCode);
  return success;
};
