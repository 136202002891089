import {Dispatch, SetStateAction} from 'react';
import {scroller} from 'react-scroll';

import {RailwayCompany, ShopDiscount} from 'common/api/localData';
import {ActiveTicket, InitialCount} from './SetDiscounts';
import {INewTicket, IRailwayEmployee} from 'api/sprzedaz/interfaces';
import {removePolishLetters} from 'Utils/commonFunctions';
import {calendarMonthData} from 'components/common/Calendar/CalendarData';
import {AddTicket} from 'Utils/TicketContext';
import i18n from 'i18n';
import {SHOP_DISCOUNT_CODES} from 'common/consts';
import {isAfter, isBefore, isEqual, parse} from 'date-fns';
const t = i18n.t;
export enum EmployeeIdStatus {
  UNCHECKED = 'unchecked',
  VALID = 'valid',
  INVALID = 'invalid',
}

const conditionAgeSuffix = (age: number) => {
  if (age === 1) return t('13047');
  if (age % 10 > 1 && age % 10 < 5) return 'lata';
  if (age > 10 && age < 20) return 'lat';
  return 'lat';
};

export const getPassengerAge = (input: string) => {
  const arr = input.split('-');
  const birthDate = new Date(input);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  if (input && Number(arr[0]) > 999 && age >= 0 && age <= 110) return `(${age} ${conditionAgeSuffix(age)})`;
  else return '';
};

export const sortFromMostPopulars = (shopDiscounts: ShopDiscount[], isAdult: boolean) => {
  const anyAge: ShopDiscount[] = [];
  const railwayRelated: ShopDiscount[] = [];
  const adultRelated: ShopDiscount[] = [];
  const childrenRelated: ShopDiscount[] = [];
  const familyDiscountCode = 1650;
  if (isAdult) {
    shopDiscounts.forEach((el) => {
      if (el.opisy[0].nazwa.includes('kolei') && el.ofertaTypKod === 1) {
        railwayRelated.push(el);
      } else if (
        (el.osobaTypKod === 1 || el.osobaTypKod === 0) &&
        el.ofertaTypKod === 1 &&
        el?.kodZakupowy !== familyDiscountCode
      ) {
        adultRelated.push(el);
      }
    });
    const adultSorted = [...adultRelated].sort((a, b) => b.popularnosc - a.popularnosc);
    return [...adultSorted, ...railwayRelated];
  }
  shopDiscounts.forEach((el) => {
    if (el.opisy[0].nazwa.includes('kolei') && el.osobaTypKod === 0 && el.ofertaTypKod === 1) {
      railwayRelated.push(el);
    } else if (el.osobaTypKod === 2 && el.ofertaTypKod === 1) {
      childrenRelated.push(el);
    } else if (el.osobaTypKod === 0 && el.ofertaTypKod === 1 && el?.kodZakupowy !== familyDiscountCode) {
      anyAge.push(el);
    }
  });
  const childrenSorted = [...childrenRelated].sort((a, b) => b.popularnosc - a.popularnosc);
  const sortedAge = [...anyAge].sort((a, b) => b.popularnosc - a.popularnosc);
  return [...childrenSorted, ...sortedAge, ...railwayRelated];
};

export const parseAgeStrToNumber = (passengerAge: string) => Number(passengerAge.split('').slice(1, 3).join(''));

export const isPassengerAdult = (passengerAge: string): boolean => parseAgeStrToNumber(passengerAge) >= 24;

export const isPassengerAdultStudent = (passengerAge: string): boolean => parseAgeStrToNumber(passengerAge) < 35;

export const isPassengerOlderAdult = (passengerAge: string): boolean => parseAgeStrToNumber(passengerAge) >= 60;

export const isPassengerYoungChild = (passengerAge: string): boolean => parseAgeStrToNumber(passengerAge) < 4;

export const isPassengerOlderChild = (passengerAge: string): boolean => {
  const age = parseAgeStrToNumber(passengerAge);
  return age >= 4 && age < 24;
};

const scrollToMainSearchPanel = () => {
  scroller.scrollTo('searchPanelSection', {
    duration: 800,
    delay: 0,
    offset: -55,
  });
};

export const handleConfirmDiscounts = (manageDiscounts: () => void) => {
  manageDiscounts();
  scrollToMainSearchPanel();
};

export const handleCancelDiscounts = (
  initialCount: InitialCount,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeAdult: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeChildren: any,
  manageDiscounts: () => void,
) => {
  changeAdult(initialCount.adultCount);
  changeChildren(initialCount.childrenCount);
  manageDiscounts();
  scrollToMainSearchPanel();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleCancelDiscountsModal = (initialCount: InitialCount, changeAdult: any, changeChildren: any) => {
  changeAdult(initialCount.adultCount);
  changeChildren(initialCount.childrenCount);
};

export const getAdultOrChildrenDiscounts = (options: ShopDiscount[], code: 1 | 2) => {
  const groupDiscs = options.filter((el) => el.osobaTypKod === code);
  const allPassengers = options.filter((el) => el.osobaTypKod === 0);
  return [...groupDiscs, ...allPassengers];
};

export const checkIfBirthdayIsToday = (birthday: string) => {
  if (!birthday?.length) return false;
  const today = new Date();
  const birthDayDate = new Date(birthday);
  return today.getMonth() === birthDayDate.getMonth() && today.getDate() === birthDayDate.getDate();
};

export const handleRecommendedAgeDiscounts = (
  passengerAge: string,
  mostPopularDiscs: ShopDiscount[],
  setRecommendedDisc: Dispatch<SetStateAction<ShopDiscount[]>>,
  setFilteredDiscs: Dispatch<SetStateAction<ShopDiscount[]>>,
) => {
  if (passengerAge.length > 0 && mostPopularDiscs.length > 0) {
    const student = mostPopularDiscs.filter((el) => el.opisy[0].nazwa === 'Studenci do 26 lat / doktoranci do 35 lat');
    const senior = mostPopularDiscs.filter((el) => el.opisy[0].nazwa === 'Bilet dla Seniora');
    const retired = mostPopularDiscs.filter((el) => el.opisy[0].nazwa === 'Emeryt/Rencista kolei');
    const retiredTrips = mostPopularDiscs.filter((el) => el.opisy[0].nazwa === '2 przejazdy w roku (emeryt/rencista)');
    const youngChild = mostPopularDiscs.filter((el) => el.opisy[0].nazwa === 'Dzieci do 4 roku życia');
    const olderChild = mostPopularDiscs.filter((el) => el.opisy[0].nazwa === 'Dzieci/Młodzież');
    const seniorDisc = [...senior, ...retiredTrips, ...retired];

    if (isPassengerAdult(passengerAge)) {
      if (isPassengerAdultStudent(passengerAge)) {
        const filtered = Array.from(new Set([...olderChild, ...seniorDisc, ...mostPopularDiscs])).slice(
          seniorDisc.length + olderChild.length,
        );
        setRecommendedDisc(Array.from(new Set([...student, ...filtered.slice(0, 3)])));
        setFilteredDiscs(mostPopularDiscs);
      }
      if (!isPassengerAdultStudent(passengerAge)) {
        const filtered = Array.from(new Set([...olderChild, ...seniorDisc, ...mostPopularDiscs])).slice(
          seniorDisc.length + olderChild.length,
        );
        setRecommendedDisc(Array.from(new Set([...student, ...filtered.slice(0, 4)])).slice(student.length));
        setFilteredDiscs(mostPopularDiscs);
      }
      if (isPassengerOlderAdult(passengerAge)) {
        setRecommendedDisc(seniorDisc);
        setFilteredDiscs(mostPopularDiscs);
      }
    }
    if (!isPassengerAdult(passengerAge)) {
      if (isPassengerYoungChild(passengerAge)) {
        setRecommendedDisc(youngChild);
        setFilteredDiscs(mostPopularDiscs);
      }
      if (isPassengerOlderChild(passengerAge)) {
        setRecommendedDisc(
          Array.from(new Set([...youngChild, ...student, ...mostPopularDiscs.slice(0, 3)])).slice(
            youngChild.length + student.length,
          ),
        );
        setFilteredDiscs(mostPopularDiscs);
      }
    }
  } else {
    setFilteredDiscs([]);
  }
};

export const hasNotSelectedDiscount = (tickets: INewTicket[]): boolean => {
  return !!tickets.find((el) => el.discountCode === null || el.discountCode === 0);
};

export const getEmployerCodeMock = (employeeId: string, employeeData: IRailwayEmployee) => {
  if (employeeId.length === 13) {
    return {employerCode: '321 - PKP INTERCITY'};
  }
  if (employeeId.length < 13 && !!employeeData.employerCode) {
    return {employerCode: ''};
  }
  return null;
};

export const registerEmployeeData = (
  activeTicket: ActiveTicket,
  groupCount: INewTicket[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeGroup: any,
  discount: ShopDiscount,
  employeeData?: IRailwayEmployee,
) => {
  const temp = [...groupCount];
  temp[activeTicket.index] = {
    discountCode: discount?.kodZakupowy,
    ticketTypeCode: 1,
    ...(employeeData && {railwayEmployee: {...employeeData}}),
  };
  changeGroup(temp);
};

export const onSelectedDiscountChange = (
  activeTicket: ActiveTicket,
  adultCount: INewTicket[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeAdult: any,
  childrenCount: INewTicket[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeChildren: any,
  discount: ShopDiscount | null,
  isRailwayEmployee: boolean | undefined,
  setEmployeeData: Dispatch<SetStateAction<IRailwayEmployee>>,
  setSelectedDiscount: Dispatch<SetStateAction<ShopDiscount | undefined>>,
  setRailwayEmplIdStatus?: Dispatch<SetStateAction<EmployeeIdStatus>>,
) => {
  if (activeTicket.isAdult) {
    const temp = [...adultCount];
    temp[activeTicket.index] = {
      discountCode: discount?.kodZakupowy ?? null,
      ticketTypeCode: 1,
    };
    changeAdult(temp);
  } else {
    const temp = [...childrenCount];
    temp[activeTicket.index] = {
      discountCode: discount?.kodZakupowy ?? null,
      ticketTypeCode: 1,
    };
    changeChildren(temp);
  }
  if (isRailwayEmployee || isCodeRailwayEmployeeRelated(discount?.kodZakupowy ?? 0)) {
    setEmployeeData({employeeId: '0', employerCode: null});
    setRailwayEmplIdStatus && setRailwayEmplIdStatus(EmployeeIdStatus.UNCHECKED);
  }
  setSelectedDiscount(discount ?? undefined);
};

export const isDiscountQuantityValid = (adultCount: INewTicket[], childrenCount: INewTicket[]) => {
  const discountsSet = new Set<number>();
  [...adultCount, ...childrenCount].forEach(
    (el) => el.discountCode && el.discountCode !== 1010 && discountsSet.add(el.discountCode),
  );
  return discountsSet.size <= 3;
};

export const areDiscountsSelected = (passengerTicket: INewTicket[]): boolean => {
  const error = passengerTicket.find((el) => el.discountCode === null || el.discountCode === 0);
  return error === undefined;
};

export const isCodeRailwayEmployeeRelated = (code: number | null) => {
  return (
    code === SHOP_DISCOUNT_CODES.UUT_EMPLOYEE ||
    code === SHOP_DISCOUNT_CODES.UUT_FAMILY ||
    code === SHOP_DISCOUNT_CODES.UUT_RETIRED
  );
};

export const areAllRailwayIdValid = (groupCount: INewTicket[]): boolean => {
  const railwayEmplError = groupCount.find((el) => {
    if (isCodeRailwayEmployeeRelated(el.discountCode)) {
      return el?.railwayEmployee ? el.railwayEmployee?.employeeId?.length < 13 : true;
    }
    return undefined;
  });
  return railwayEmplError === undefined;
};

export const areAllRailwayCodesValid = (groupCount: INewTicket[]): boolean => {
  const railwayEmplError = groupCount.find(
    (el) => isCodeRailwayEmployeeRelated(el.discountCode) && !el?.railwayEmployee?.employerCode,
  );
  return railwayEmplError === undefined;
};

export const areAllRailwayDiscValid = (groupCount: INewTicket[]): boolean => {
  return areAllRailwayCodesValid(groupCount);
  // Uncomment to enable employee id validation
  // return areAllRailwayIdValid(groupCount) && areAllRailwayCodesValid(groupCount);
};

export const areAllDiscountsSelected = (adultCount: INewTicket[], childrenCount: INewTicket[]): boolean => {
  return (
    areAllRailwayDiscValid([...adultCount, ...childrenCount]) &&
    areDiscountsSelected(childrenCount) &&
    areDiscountsSelected(adultCount)
  );
};

export const scrollToConfirmBtnDesktop = (isModal?: boolean) => {
  const focusTarget = document.querySelector('#discountsConfirmationBtnTarget');
  scroller.scrollTo(`discountsConfirmationBtn`, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -600,
    ...(isModal && {containerId: 'modalScrollContainer'}),
  });
  (focusTarget as HTMLDivElement).focus();
};

export const scrollToErrorsDesktop = () => {
  scroller.scrollTo(`discountQuantityError`, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -12,
  });
};

export const scrollToRailwayEmplError = () => {
  scroller.scrollTo(`railwayEmployeeBox`, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -500,
  });
};

export const isRailwayEmployeeRelated = (discount: ShopDiscount | null): boolean => {
  return (
    discount?.opisy[0].nazwa === 'Pracownik kolei' ||
    discount?.opisy[0].nazwa === 'Emeryt/Rencista kolei' ||
    discount?.opisy[0].nazwa === 'Rodzina pracownika kolei'
  );
};

export const filterDiscounts = (value: string, mostPopularDiscs: ShopDiscount[]) => {
  const filtered = filterOptions(mostPopularDiscs, value);
  const firstLetterMatch = filtered.filter((el) => {
    return removePolishLetters(value) === removePolishLetters(el.opisy[0].nazwa).slice(0, value.length);
  });
  const others = filtered.filter((el) => {
    return removePolishLetters(value) !== removePolishLetters(el.opisy[0].nazwa).slice(0, value.length);
  });
  return [...firstLetterMatch, ...others];
};

export const filterOptions = (options: ShopDiscount[], value: string) => {
  const newOptions: ShopDiscount[] = [];
  options.forEach((el: ShopDiscount) => {
    if (
      removePolishLetters(el.opisy[0].nazwa).includes(value.toLowerCase()) ||
      el.opisy[0].nazwa.toLowerCase().includes(value.toLowerCase()) ||
      el.opisy[0].informacja.toLowerCase().split('<br>')[0].slice(-4).replace('-', '').includes(value.toLowerCase())
    )
      newOptions.push(el);
  });
  return newOptions;
};

export const filterRailwayCompanies = (value: string, options: RailwayCompany[]): RailwayCompany[] => {
  const allMatchingCompanies = filterRailwayCompaniesOptions(options, value);
  const firstLetterMatch = allMatchingCompanies.filter((el: RailwayCompany) => {
    return removePolishLetters(value) === removePolishLetters(el.nazwa).slice(0, value.length);
  });
  const othersLettersMatch = allMatchingCompanies.filter((el: RailwayCompany) => {
    return removePolishLetters(value) !== removePolishLetters(el.nazwa).slice(0, value.length);
  });
  return [...firstLetterMatch, ...othersLettersMatch];
};

export const filterRailwayCompaniesOptions = (options: RailwayCompany[], value: string): RailwayCompany[] => {
  return options.filter((el: RailwayCompany) => {
    return (
      removePolishLetters(el.nazwa).includes(value.toLowerCase()) ||
      el.nazwa.toLowerCase().includes(value.toLowerCase()) ||
      el.kod.toString().includes(value)
    );
  });
};

export const formatMonth = (val: string) => {
  if (Number(val) > 12) return '12';
  if (val.length > 2) {
    switch (removePolishLetters(val)) {
      case 'styczen':
        return '01';
      case 'luty':
        return '02';
      case 'marzec':
        return '03';
      case 'kwiecien':
        return '04';
      case 'maj':
        return '05';
      case 'czerwiec':
        return '06';
      case 'lipiec':
        return '07';
      case 'sierpien':
        return '08';
      case 'wrzesien':
        return '09';
      case 'pazdziernik':
        return '10';
      case 'listopad':
        return '11';
      case 'grudzien':
        return '12';
      default:
        return val;
    }
  }
  return val;
};

export const setDaysToDisplay = (month: string, year: string) => {
  const yearToNumber = Number(year);
  const monthToNumber = Number(formatMonth(month));
  if (month.length) {
    if (monthToNumber === 2 && yearToNumber >= 1900) {
      if (yearToNumber % 4 === 0) {
        if (yearToNumber % 100 === 0) {
          if (yearToNumber % 400 === 0) return 29;
          else return 28;
        } else return 29;
      }
      return 28;
    }
    if (monthToNumber > 0 && monthToNumber < 13) {
      return calendarMonthData[Math.abs(monthToNumber - 1)].days;
    }
  }
  return 31;
};

export const validateAdditionalTicketsQQ = (
  newTicketsQQ: number,
  addTicketContextFrom: AddTicket,
  addTicketContextTo: AddTicket,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAddTicketContextFrom: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAddTicketContextTo: any,
) => {
  const tempAddTicketFrom = {...addTicketContextFrom};
  const tempAddTicketTo = {...addTicketContextTo};
  if (addTicketContextFrom.dogsTickets > newTicketsQQ) tempAddTicketFrom.dogsTickets = newTicketsQQ;
  if (addTicketContextFrom.luggageTickets > newTicketsQQ) tempAddTicketFrom.luggageTickets = newTicketsQQ;
  if (addTicketContextFrom.bikesTickets > newTicketsQQ) tempAddTicketFrom.bikesTickets = newTicketsQQ;
  if (addTicketContextTo.dogsTickets > newTicketsQQ) tempAddTicketTo.dogsTickets = newTicketsQQ;
  if (addTicketContextTo.luggageTickets > newTicketsQQ) tempAddTicketTo.luggageTickets = newTicketsQQ;
  if (addTicketContextTo.bikesTickets > newTicketsQQ) tempAddTicketTo.bikesTickets = newTicketsQQ;
  setAddTicketContextFrom(tempAddTicketFrom);
  setAddTicketContextTo(tempAddTicketTo);
};

export const isDiscountActive = (
  discount: ShopDiscount | undefined | null,
  searchDate: string | undefined,
  searchTime: string | undefined,
) => {
  const {dataObowiazywaniaOd, dataObowiazywaniaDo} = discount ?? {};
  if (!searchDate || !searchTime || !dataObowiazywaniaOd || !dataObowiazywaniaDo) return true;

  const checkedDate = parse(`${searchDate} ${searchTime}:00.000`, 'yyyy-MM-dd HH:mm:ss.SSS', new Date());
  const startActiveDate = parse(dataObowiazywaniaOd, 'yyyy-MM-dd HH:mm:ss.SSS', new Date());
  const endActiveDate = parse(dataObowiazywaniaDo, 'yyyy-MM-dd HH:mm:ss.SSS', new Date());
  return (
    (isAfter(checkedDate, startActiveDate) || isEqual(checkedDate, startActiveDate)) &&
    isBefore(checkedDate, endActiveDate || isEqual(checkedDate, endActiveDate))
  );
};

export const getTranslatedDescription = <T extends {jezyk: string}>(descriptions: T[]) =>
  descriptions.filter((description) => description.jezyk === i18n.language.toLocaleUpperCase());

export const getDiscountDataByCode = (
  discountCode: number | 0 | null,
  discountList: ShopDiscount[],
  translateDescription?: boolean,
): ShopDiscount | undefined => {
  const discount = discountList.find((discount) => discount.kodZakupowy === discountCode);
  if (!discount) return undefined;

  return translateDescription
    ? {
        ...discount,
        opisy: getTranslatedDescription(discount.opisy),
      }
    : discount;
};
