import {styled} from '@mui/material';

export const StyledModalContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.color.shadow.secondary};
  z-index: 100;
`;

export const StyledModal = styled('div')`
  border-radius: 0.4rem;
  border: 1px solid ${({theme}) => theme.color.border.primary};
  background-color: ${({theme}) => theme.color.background.primary};
`;
