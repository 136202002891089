import {useState, useEffect, FC, useContext, ReactNode, createContext} from 'react';
import {setAutoFreeze} from 'immer';
import {Dictionary} from 'lodash';

import {
  AdditionalFee,
  CommercialAttribute,
  ConfigurationResponse,
  CountriesConfigurationResponse,
  Discount,
  ForeignDiscount,
  Offer,
  PlaceKind,
  PlacePosition,
  PlaceType,
  RailwayCompany,
  SeasonalOffer,
  SeasonalOfferKind,
  ShopDiscount,
  Station,
  TrainCategory,
  useFetchAllLibrary,
} from 'common/api/localData';
import {isStorageDataValid} from './commonFunctions';
import {InternationalDiscount} from 'common/api/intlDiscounts';
setAutoFreeze(false);

type DataState = {
  children: ReactNode;
};

export type DataContext = {
  configuration: ConfigurationResponse | null;
  stationsList: Station[];
  stationListMap: Dictionary<Station>;
  trainCategories: TrainCategory[];
  placeKinds: PlaceKind[];
  placeTypes: PlaceType[];
  placePosition: PlacePosition[];
  railwayCompanies: RailwayCompany[];
  discounts: Discount[];
  foreignDiscounts: ForeignDiscount[];
  shopDiscounts: ShopDiscount[];
  commercialAttributes: CommercialAttribute[];
  offers: Offer[];
  seasonalOffers: SeasonalOffer[];
  seasonalOffersKinds: SeasonalOfferKind[];
  additionalFees: AdditionalFee[];
  foreignPassengersDiscounts: InternationalDiscount[];
  countriesConfiguration: CountriesConfigurationResponse['kraje'];
};

const initialLibrary: DataContext = {
  configuration: null,
  stationsList: [],
  stationListMap: {},
  trainCategories: [],
  placeKinds: [],
  placeTypes: [],
  placePosition: [],
  railwayCompanies: [],
  discounts: [],
  foreignDiscounts: [],
  shopDiscounts: [],
  commercialAttributes: [],
  offers: [],
  seasonalOffers: [],
  seasonalOffersKinds: [],
  additionalFees: [],
  foreignPassengersDiscounts: [],
  countriesConfiguration: [],
};
export const DataContext = createContext<DataContext>(initialLibrary);

export const useData = () => {
  return useContext(DataContext);
};

const DataProvider: FC<DataState> = ({children}) => {
  const [data, setData] = useState<DataContext>(initialLibrary);
  const localData = window.localStorage.getItem('eic2-library');
  const parsedLocalData = localData ? JSON.parse(localData) : null;
  const isLocalStorageDataStale =
    new Date().getTime() > new Date(parsedLocalData?.data.configuration?.przerwaTechnologicznaDoData).getTime();
  const shouldFetch =
    parsedLocalData === null || isLocalStorageDataStale || !isStorageDataValid(parsedLocalData?.data, initialLibrary);

  const {data: allLibraryData, isLoading, isError} = useFetchAllLibrary(shouldFetch);

  useEffect(() => {
    const loadLibraryDataFromAPI = async () => {
      if (!isLoading && !isError && allLibraryData) {
        setData(allLibraryData as DataContext);

        window.localStorage.setItem(
          'eic2-library',
          JSON.stringify({
            data: allLibraryData,
            timeStamp: new Date(),
          }),
        );
      }
    };

    shouldFetch ? loadLibraryDataFromAPI() : setData(parsedLocalData.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError]);

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export default DataProvider;
