/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {FC, Fragment, useContext, useMemo} from 'react';

import {Text} from 'common/components/base/Text';
import Icon from 'common/components/base/Icon';
import OutlineButton from 'components/common/OutlineButton';

import {
  returnCarrierLogo,
  getFullDate,
  getFullTimeFromDate,
  getDayOfTheWeek,
  getTicketSource,
  returnStationByCode,
  getTimeFromDate,
  returnAdditionalTypeTicketData,
  getAdditionalTicketsFromPrimaryTicket,
} from 'Utils/commonFunctions';
import {useStyledThemeState} from 'common/theme';

import {useData} from 'Utils/DataContex';
import {ReservationContext} from 'Utils/ReservationContext';

import OrangeCircleSmall from 'assets/orangeCircleSmall.svg';
import OrangePinSmall from 'assets/orangePinSmall.svg';
import ContrastCircleSmall from 'assets/contrastCircleSmall2.svg';
import ContrastPinSmall from 'assets/contrastPinSmall2.svg';
import TicketContrast from 'assets/contrastTicket.svg';
import Ticket from 'assets/ticket.svg';
import {useTranslation} from 'react-i18next';
import {getAvailableCarriers, getCarrierLogo} from 'components/pages/SeasonalTicket/common/utils';
import {checkIfSeasonalTicketIsActive} from '../../common/utils';
import {isAdditionalTicketKind, isPrimaryTicketKind} from 'common/pages/UserProfile/MyTickets';

import {SEASONAL_OFFER_CODES} from 'common/consts';
import {isNonSpecifiedRouteSeasonalOfferCode, isTicketManuallyAdded} from 'common/utils';
import {TicketComponentVariant} from 'common/pages/UserProfile/MyTickets/components/Ticket/TicketComponent';

import style from './MyTicketsMobilet.module.scss';
import {useNavigate} from 'react-router-dom';
import {FlexRow, SeasonalTicketMobileInfoBanner} from './MyTicketsSingleTicket.style';
import {MyTicket, TicketKind, ticketKinds} from 'common/api/myTickets';

interface Props {
  tickets: MyTicket[];
  isSeasonal?: boolean;
  history?: number;
  variant?: TicketComponentVariant;
}

const MyTicketsSingleTicketMobile: FC<Props> = ({tickets, isSeasonal, history, variant}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {seasonalOffers, stationsList} = useData();
  const {theme, isContrastTheme} = useStyledThemeState();
  const {setDownloadedTicketData, setReminderModalSearchData} = useContext(ReservationContext);

  const primaryTickets = tickets?.filter((el) => isPrimaryTicketKind(el.biletRodzaj)) ?? [];
  const additionalTickets = useMemo(
    () => tickets?.filter((el) => isAdditionalTicketKind(el.biletRodzaj)) ?? [],
    [tickets],
  );

  const {
    biletRodzaj,
    liczbaBagazy,
    liczbaPsow,
    liczbaRowerow,
    cenaBagaze,
    cenaPsy,
    cenaRowery,
    biletSeria,
    zwrotData,
    odcinki,
    ofertaKod,
    waznoscBiletuDo,
    waznoscBiletuOd,
    biletZrodloKod,
    biletNr,
    ofertaNazwa,
  } = primaryTickets[0];

  const firstStation = odcinki[0];
  const ticketSource = getTicketSource(biletSeria || '');
  const isReturned = !!zwrotData;
  const isSeasonalTicketReminderDrawer = variant === 'seasonalTicketReminderDrawer';

  const isSeasonalTicketActive = useMemo(
    () => checkIfSeasonalTicketIsActive(waznoscBiletuOd || '', waznoscBiletuDo || '') && !isReturned,
    [isReturned, waznoscBiletuDo, waznoscBiletuOd],
  );
  const isManuallyAdded = isTicketManuallyAdded(biletZrodloKod, biletSeria);
  const isIntercityCard = ofertaKod === SEASONAL_OFFER_CODES.INTERCITY_CARD;
  const downloadReservationClickHandler = () => {
    if (primaryTickets[0]) {
      !isSeasonalTicketReminderDrawer && setReminderModalSearchData(null);
      navigate('/rezerwacje');
      setDownloadedTicketData(primaryTickets[0]);
    }
  };

  const availableCarriers = getAvailableCarriers(ofertaKod, seasonalOffers) ?? [];
  const carriersIconsToRender = (
    <div className={style.carriersIconsContainer}>
      {availableCarriers.map((carrier) => (
        <Fragment key={carrier}>{getCarrierLogo(carrier, isContrastTheme)}</Fragment>
      ))}
    </div>
  );

  const isNonSpecifiedRouteTicket = isNonSpecifiedRouteSeasonalOfferCode(ofertaKod);

  const isInternationalEPAWithAdditionalTicket =
    biletRodzaj === ticketKinds.INTERNATIONAL_EPA && (liczbaBagazy > 0 || liczbaPsow > 0 || liczbaRowerow > 0);
  const internationalEPAAdditionalTickets = isInternationalEPAWithAdditionalTicket
    ? getAdditionalTicketsFromPrimaryTicket(liczbaBagazy, cenaBagaze, liczbaPsow, cenaPsy, liczbaRowerow, cenaRowery)
    : [];

  const groupedByKindDomesticAdditionalTickets = useMemo(
    () =>
      additionalTickets.reduce<{kind: TicketKind; count: number}[]>((acc, ticket) => {
        const {biletRodzaj, zwrotOpis} = ticket;

        if (zwrotOpis !== '') return acc;
        const existingTicket = acc.find((item) => item.kind === biletRodzaj);
        if (existingTicket) {
          existingTicket.count += 1;
        } else {
          acc.push({kind: biletRodzaj, count: 1});
        }
        return acc;
      }, []),
    [additionalTickets],
  );

  return (
    <div className={style.singleTicket__box}>
      {!history && isManuallyAdded && (
        <SeasonalTicketMobileInfoBanner>
          <Text variant="P3" weight={600} palette="text.info">
            {t('29397')}
          </Text>
        </SeasonalTicketMobileInfoBanner>
      )}
      <div className={style.singleTicket_MainInfo}>
        <div className={style.singleTicket_MainInfo__container}>
          {isSeasonal ? (
            <>
              <p className={style.singleTicket_MainInfoTitle}>{isIntercityCard ? t('13083') : ofertaNazwa}</p>
              <FlexRow>
                <Text variant="P5">{`${biletSeria}${biletNr}`}</Text>
                {isManuallyAdded && <Text variant="P5">{t('13134')}</Text>}
              </FlexRow>
            </>
          ) : (
            <p className={style.singleTicket_MainInfoTitle}>{t('11060')}</p>
          )}
          <h2 className={`${style.singleTicket__active_period}`}>
            {isSeasonal
              ? `${getFullDate(waznoscBiletuOd!)} - ${getFullDate(waznoscBiletuDo!)}`
              : getFullDate(firstStation?.wyjazdData as string)}{' '}
          </h2>
          {isSeasonal && waznoscBiletuOd && waznoscBiletuDo && (
            <Text variant="P5">
              {t('29314', {
                from: getTimeFromDate(waznoscBiletuOd),
                till: getTimeFromDate(waznoscBiletuDo),
              })}
            </Text>
          )}

          {!isSeasonal && (
            <p className={style.singleTicket__day_name}>
              {getDayOfTheWeek(new Date(firstStation?.wyjazdData as string).getDay())}
            </p>
          )}
          {!isReturned &&
            (isSeasonal && primaryTickets[0] ? (
              isSeasonalTicketActive ? (
                <Text variant="P4" weight={700} palette="success.default">
                  {t('29250')}
                </Text>
              ) : (
                <Text variant="P4" weight={700} palette="error.default">
                  {t('29251')}
                </Text>
              )
            ) : null)}
          {isReturned && <p className={style.red_text}>{t('29153')}</p>}
          {ticketSource && <p className={style.ticketTag}>{t(ticketSource)}</p>}
        </div>
        {!isSeasonal && (
          <div className={style.singleTicket_TimeContainer}>
            <Icon icon="clock" />
            <p className={style.singleTicket__active_period}>
              {firstStation!.wyjazdData ? getFullTimeFromDate(firstStation!.wyjazdData as string) : '-'}
            </p>
          </div>
        )}
      </div>
      {firstStation!.stacjaOdKod !== undefined && (
        <div className={style.trip_data_box}>
          <div className={style.icon_box}>
            <img src={isContrastTheme ? ContrastCircleSmall : OrangeCircleSmall} alt={t('29080')} />
            <div />
            <img src={isContrastTheme ? ContrastPinSmall : OrangePinSmall} alt={t('29081')} />
          </div>
          <div className={style.data_box}>
            {tickets && (
              <div className={style.singleTicket__cityInfo}>
                <p className={style.from_to}>
                  {isNonSpecifiedRouteTicket
                    ? t('11095')
                    : returnStationByCode(firstStation!.stacjaOdKod, stationsList)}
                </p>
                {!isSeasonal && (
                  <>
                    <p className={style.singleTicket__day_name}>{firstStation?.pociagNr}</p>
                    {returnCarrierLogo(firstStation!.pociagKategoriaNazwa, theme)}
                  </>
                )}
              </div>
            )}
            {primaryTickets.map((el, index, arr) => {
              return el.odcinki.map((el2, index2, arr2) => {
                const hasTransferChange = index2 !== arr2.length - 1 || (index === 0 && arr.length > 1);
                const isDirect = arr2.length === 1;
                const nextRouteItem = el.odcinki[index2 + 1];

                return (
                  <Fragment key={`${el.biletNr}-${el2.pociagNr}`}>
                    {hasTransferChange && <p className={style.singleTicket_MainInfoTitle}>{t('22010')}</p>}
                    <div className={style.singleTicket__cityInfo}>
                      <p className={style.from_to}>
                        {isNonSpecifiedRouteTicket ? t('11095') : returnStationByCode(el2.stacjaDoKod, stationsList)}
                        {hasTransferChange && nextRouteItem && el2.stacjaDoKod !== nextRouteItem.stacjaOdKod && (
                          <>
                            <br />
                            {returnStationByCode(nextRouteItem.stacjaOdKod, stationsList)}
                          </>
                        )}
                      </p>
                      {!isDirect && !hasTransferChange && (
                        <>
                          <p className={style.singleTicket__day_name}>{el2.pociagNr}</p>
                          {returnCarrierLogo(el2.pociagKategoriaNazwa, theme)}
                        </>
                      )}
                    </div>
                  </Fragment>
                );
              });
            })}
          </div>
        </div>
      )}
      {primaryTickets[0] && isSeasonal && availableCarriers.length > 0 && (
        <div className={style.textAndIconsContainer}>
          <Text variant="P5">{t('29318')}</Text>
          {carriersIconsToRender}
        </div>
      )}
      {!isSeasonal && (
        <div className={style.singleTicket__ikonContainer}>
          {primaryTickets[0] && (
            <div className={style.singleTicket__singleIconContainer}>
              <img src={isContrastTheme ? TicketContrast : Ticket} alt={t('11010')} />
              <span>x {primaryTickets[0].podrozni.length}</span>
            </div>
          )}
          {/* International Additional Tickets */}
          {internationalEPAAdditionalTickets.map(({kind, count}) => (
            <Fragment key={kind}>
              <div className={style.singleTicket__singleIconContainer}>
                <img
                  src={returnAdditionalTypeTicketData(t, kind, isContrastTheme, 'dark')?.image}
                  alt={returnAdditionalTypeTicketData(t, kind, isContrastTheme, 'dark')?.nazwa}
                />
                <span>x {count}</span>
              </div>
            </Fragment>
          ))}
          {/* Domestic Additional Tickets */}
          {groupedByKindDomesticAdditionalTickets.map(({kind, count}) => (
            <Fragment key={kind}>
              <div className={style.singleTicket__singleIconContainer}>
                <img
                  src={returnAdditionalTypeTicketData(t, kind, isContrastTheme, 'dark')?.image}
                  alt={returnAdditionalTypeTicketData(t, kind, isContrastTheme, 'dark')?.nazwa}
                />
                <span>x {count}</span>
              </div>
            </Fragment>
          ))}
        </div>
      )}
      {isSeasonal && !isReturned && !history && (
        <OutlineButton
          text={isSeasonalTicketReminderDrawer ? t('29511') : t('29252')}
          path={downloadReservationClickHandler}
        />
      )}
    </div>
  );
};

export default MyTicketsSingleTicketMobile;
