import {publicApi} from '../config';
import {InternationalDiscount, InternationalDiscountsSchema} from '../intlDiscounts';
import {getCurrentDate} from '../utils';
import {
  AdditionalFeesResponse,
  CommercialAttributesResponse,
  ConfigurationResponse,
  DiscountsResponse,
  Discount,
  ForeignDiscount,
  OffersResponse,
  PlaceKindsResponse,
  PlacePositionsResponse,
  PlaceTypesResponse,
  RailwayCompaniesResponse,
  SeasonalOffersKindsResponse,
  SeasonalOffersResponse,
  ShopDiscountsResponse,
  StationsResponse,
  TrainCategoriesResponse,
  CountriesConfigurationResponse,
} from './types';

export const getConfiguration = async () => {
  const response = await publicApi.post<ConfigurationResponse>('/Aktualizacja', {
    metoda: 'pobierzKonfiguracje',
  });
  return response.data;
};

export const getCountriesConfiguration = async () => {
  const response = await publicApi.post<CountriesConfigurationResponse>('/Aktualizacja', {
    metoda: 'pobierzKonfiguracjeMiedzynarodowa',
  });
  return response.data;
};

export const getStations = async () => {
  const response = await publicApi.post<StationsResponse>('/Aktualizacja', {
    metoda: 'pobierzStacje',
    ostatniaAktualizacjaData: getCurrentDate(),
  });
  return response.data;
};

export const getTrainCategories = async () => {
  const response = await publicApi.post<TrainCategoriesResponse>('/Aktualizacja', {
    metoda: 'pobierzKategoriePociagow',
    ostatniaAktualizacjaData: getCurrentDate(),
  });
  return response.data;
};

export const getPlaceKinds = async () => {
  const response = await publicApi.post<PlaceKindsResponse>('/Aktualizacja', {
    metoda: 'pobierzRodzajeMiejsc',
    ostatniaAktualizacjaData: getCurrentDate(),
  });
  return response.data;
};

export const getPlaceTypes = async () => {
  const response = await publicApi.post<PlaceTypesResponse>('/Aktualizacja', {
    metoda: 'pobierzTypyMiejsc',
    ostatniaAktualizacjaData: getCurrentDate(),
  });
  return response.data;
};

export const getPlacePosition = async () => {
  const response = await publicApi.post<PlacePositionsResponse>('/Aktualizacja', {
    metoda: 'pobierzUsytuowanie',
    ostatniaAktualizacjaData: getCurrentDate(),
  });
  return response.data;
};

export const getRailwayCompanies = async () => {
  const response = await publicApi.post<RailwayCompaniesResponse>('/Aktualizacja', {
    metoda: 'pobierzSpolkiKolejowe',
    ostatniaAktualizacjaData: getCurrentDate(),
  });
  return response.data;
};

export const getDiscounts = async () => {
  const response = await publicApi.post<DiscountsResponse<Discount>>('/Aktualizacja', {
    metoda: 'pobierzZnizki',
  });
  return response.data;
};

export const getForeignDiscounts = async () => {
  const response = await publicApi.post<DiscountsResponse<ForeignDiscount>>('/Aktualizacja', {
    metoda: 'pobierzZnizkiEnrt',
  });
  return response.data;
};

export const getPassengerForeignDiscounts = async () => {
  const response = await publicApi.post<{znizki: InternationalDiscount[]; bledy: Error[]}>('Aktualizacja', {
    metoda: 'pobierzZnizkiMiedzynarodowe',
    ostatniaAktualizacjaData: getCurrentDate(),
  });
  InternationalDiscountsSchema.parse(response.data);
  return response.data;
};

export const getShopDiscounts = async () => {
  const response = await publicApi.post<ShopDiscountsResponse>('/Aktualizacja', {
    metoda: 'pobierzZnizkiZakupowe',
  });
  return response.data;
};

export const getCommercialAttributes = async () => {
  const response = await publicApi.post<CommercialAttributesResponse>('/Aktualizacja', {
    metoda: 'pobierzAtrybutyHandlowe',
  });
  return response.data;
};

export const getOffers = async () => {
  const response = await publicApi.post<OffersResponse>('/Aktualizacja', {
    metoda: 'pobierzOferty',
  });
  return response.data;
};

export const getSeasonalOffers = async () => {
  const response = await publicApi.post<SeasonalOffersResponse>('/Aktualizacja', {
    metoda: 'pobierzOfertyOkresowe',
  });
  return response.data;
};

export const getSeasonalOffersKinds = async () => {
  const response = await publicApi.post<SeasonalOffersKindsResponse>('/Aktualizacja', {
    metoda: 'pobierzOfertyOkresoweRodzaje',
  });
  return response.data;
};

export const getAdditionalFees = async () => {
  const response = await publicApi.post<AdditionalFeesResponse>('/Aktualizacja', {
    metoda: 'pobierzOplatyDodatkowe',
    ostatniaAktualizacjaData: getCurrentDate(),
  });
  return response.data;
};
