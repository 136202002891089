import {FC, useContext, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import * as DOMPurify from 'dompurify';

import {ShopDiscount} from 'common/api/localData';

import S from './DiscountSlider.module.scss';
import {useTranslation} from 'react-i18next';
import {discountListLanguageMapper} from 'Utils/commonFunctions';
import {SearchContext} from 'Utils/SearchContext';
import {isDiscountActive} from 'components/pages/PurchasePath/PurchasePathDesktop/SetDiscounts/SetDiscountsLogic';

interface Props {
  discounts: ShopDiscount[];
  selectedDiscount: ShopDiscount | undefined;
  onDiscountChange: (discount: ShopDiscount) => void;
}

const DiscountSlider: FC<Props> = ({discounts, selectedDiscount, onDiscountChange}) => {
  const {t, i18n} = useTranslation();
  const [languageNumber, setLanguageNumber] = useState(0);
  const {searchFormFrom} = useContext(SearchContext);

  useEffect(() => {
    const selectedLang = discountListLanguageMapper(i18n.language);
    setLanguageNumber(selectedLang);
  }, [i18n.language]);

  return (
    <Swiper className={S.sliderContainer} spaceBetween={8} slidesPerView={discounts.length === 1 ? 'auto' : 1.5}>
      {discounts.map((el) => {
        const isDiscountDisabled = !isDiscountActive(el, searchFormFrom?.date, searchFormFrom?.time);
        return (
          <SwiperSlide key={el?.kodZakupowy}>
            <button
              disabled={isDiscountDisabled}
              onClick={() => onDiscountChange(el)}
              className={`${S.singleDiscountBox} ${
                selectedDiscount?.kodZakupowy === el.kodZakupowy ? S.singleDiscountBoxActive : ''
              }`}
              aria-label={`${t('13018')} ${el.opisy[languageNumber].nazwa}`}
            >
              <div className={S.singleDiscountHeader}>
                <p>{el.opisy[languageNumber].nazwa}</p>
                <p>{el.opisy[languageNumber].informacja.split('<br>')[0].slice(-4).replace('-', '')}</p>
              </div>
              <div className={S.singleDiscountInfo}>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(el.opisy[languageNumber].informacja)}} />
              </div>
              {isDiscountDisabled ? null : <div className={S.selectBtn}> {t('11038')}</div>}
            </button>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default DiscountSlider;
