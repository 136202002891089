import {useTranslation} from 'react-i18next';

import {XButton} from 'common/components/feature/Buttons';
import {TicketComponent} from 'common/pages/UserProfile/MyTickets/components/Ticket/TicketComponent';
import {Text} from 'common/components/base/Text';
import ConfirmButton from 'components/common/ConfirmButton';
import {TicketsListPageLoader} from 'common/pages/UserProfile/MyTickets/components/MyTicketsListPageLoader/TicketsListPageLoader';
import {EmptyInformation} from 'common/components/feature/EmptyInformation';
import {ModalContainer} from 'common/components/base/Modal';

import {MyTicketIdentifier, useMyTicketsPageData} from 'common/api/myTickets';
import {ModalWrapper} from '../TicketDetailsInfoModal/TicketDetailsInfoModal.style';
import {ModalHeader} from 'common/components/feature/Modal';

interface SeasonalTicketInformationModalProps {
  onXClick: () => void;
  buttonText: string;
  onButtonClick: () => void;
  header?: string;
  seasonalTicketSerial: string;
  seasonalTicketNumber: number;
}

export const SeasonalTicketInformationModal = ({
  onXClick,
  buttonText,
  onButtonClick,
  header,
  seasonalTicketSerial,
  seasonalTicketNumber,
}: SeasonalTicketInformationModalProps) => {
  const {t} = useTranslation();
  const {data: dataSingleTicket, isFetching: isFetchingSingleTicket} = useMyTicketsPageData([
    {biletSeria: seasonalTicketSerial, biletNr: seasonalTicketNumber, transakcjaNr: null},
  ]);

  const noTicketFound = dataSingleTicket[0] && dataSingleTicket[0]?.bilety.length === 0;

  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalHeader>
          {header && <Text variant="H2">{header}</Text>}
          <XButton onClick={onXClick} />
        </ModalHeader>

        {isFetchingSingleTicket ? (
          <TicketsListPageLoader isMobile={false} elementsLength={1} ticketVariant="SINGLE" />
        ) : noTicketFound ? (
          <EmptyInformation message={t('29488')} />
        ) : (
          dataSingleTicket[0] && (
            <TicketComponent
              identifier={{biletSeria: seasonalTicketSerial, biletNr: seasonalTicketNumber} as MyTicketIdentifier} //TODO for now it should work, but in the future the entire identifier needs to be removed
              tickets={dataSingleTicket[0].bilety}
              isMobile={false}
              isReturned={false}
              isHistoryVariant={0}
              isSeasonal={true}
              variant="seasonalTicketInformationModal"
            />
          )
        )}

        <ConfirmButton text={buttonText} execute={onButtonClick} />
      </ModalWrapper>
    </ModalContainer>
  );
};
