import {env} from 'env';
import api from '../axios-middleware';

import {
  IDownloadInvoiceRequest,
  IDownloadInvoiceResponse,
  IGenerateInvoiceRequest,
  IGenerateInvoiceResponse,
  IDownloadNoteResponse,
  IIdInvList,
  IIdInvListResponseList,
} from './interfaces';

import {getToken} from 'Utils/auth';

const methods = {
  getInvoiceIdent: async (obj: IIdInvList) => {
    const token = await getToken();
    return await api.postExt<IIdInvListResponseList>(
      '/server/private/endpoint/Faktury',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzIdentyfikatoryFaktur',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  generateInvoice: async (obj: IGenerateInvoiceRequest) => {
    const token = await getToken();
    return await api.postExt<IGenerateInvoiceResponse>(
      '/server/private/endpoint/Faktury',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'wystawFakture',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  downloadInvoice: async (obj: IDownloadInvoiceRequest) => {
    const token = await getToken();
    return await api.postExt<IDownloadInvoiceResponse>(
      '/server/private/endpoint/Faktury',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzFakture',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  downloadNote: async (obj: IDownloadInvoiceRequest) => {
    const token = await getToken();
    return await api.postExt<IDownloadNoteResponse>(
      '/server/private/endpoint/Faktury',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'pobierzNote',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
};

export default methods;
