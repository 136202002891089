/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC, useState, useEffect, useMemo} from 'react';

import {useLocation, Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {checkIfGuest} from 'Utils/commonFunctions';

import RegisterModal from 'components/common/LoginOptions/RegisterModal';
import {ReactComponent as FullLogo} from 'assets/logoFull.svg';
import {ReactComponent as FullLogoContrast} from 'assets/logoFullContrast.svg';
import {ReactComponent as Menu} from 'assets/menu.svg';
import {ReactComponent as MenuContrast} from 'assets/menuContrast.svg';
import {ReactComponent as CircleProfile} from 'assets/circleProfile.svg';
import {ReactComponent as CircleProfileContrast} from 'assets/circleProfileContrast.svg';
import {ReactComponent as ChangeTheme} from 'assets/changeTheme.svg';
import {ReactComponent as ChangeThemeContrast} from 'assets/changeThemeContrast.svg';
import {useAuth} from 'Utils/AuthContext';
import {checkRefreshAndReturnJwt, getJwtTokenOrDefault} from 'Utils/auth';
import {TextResizeControls} from 'components/common/TextResizeControls/TextResizeControls';
import {useStyledThemeState, useToggleTheme} from 'common/theme';

import S from './TopMenu.module.scss';
import SkipLinks from '../SkipLinks/SkipLinks';
import ssoUrlBuilder, {SSOType} from 'Utils/ssoUrlBuilder';
import {useShoppingBasket} from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketContext';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ShoppingBasketTimeoutModal from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketTimeoutModal/ShoppingBasketTimeoutModal';
import ShoppingBasketCounterWithBadge from 'components/pages/PurchasePath/ShoppingBasket/ShoppingBasketCounterWithBadge/ShoppingBasketCounterWithBadge';
import {profileMenuElements} from '../../pages/UserProfile/userProfileMenu';
import SelectLanguage from '../SelectLanguage';

/**
 * Used for E2E tests.
 */
const TAG = 'TopMenu';

const TopMenu: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {userData} = useAuth();
  const {
    setBasketFromStorage,
    clearBasket,
    clearItemsAfterPayment,
    basketCancelled,
    lessThan2MinutesLeft,
    userSaw0MinutesModal,
    setUserSaw0MinutesModal,
    userSaw2MinutesLeftToPayModal,
    setUserSaw2MinutesLeftToPayModal,
  } = useShoppingBasket();
  const [expandLanguage, setExpandLanguage] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState<boolean>(false);
  const [basketTimeOutModal, setBasketTimeOutModal] = useState<boolean>(false);
  const [cancelBasket, setCancelBasket] = useState<boolean>(false);
  const localToken = window.sessionStorage.eic2Token;
  const {isContrastTheme} = useStyledThemeState();
  const {toggleTheme} = useToggleTheme();
  const {t} = useTranslation();

  const isGuest = useMemo(() => checkIfGuest(), []);

  const handleMenuClick = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  }; //TODO add proper handler when burger menu is implemented

  const handleRegisterClick = () => {
    // setIsModalOpen(true); TODO uncomment when registration with fb/google is enabled again

    window.location.replace(ssoUrlBuilder(SSOType.REGISTER, isContrastTheme)); //TODO delete when registration with fb/google is enabled again
  };

  const checkToken = async () => {
    const token = await checkRefreshAndReturnJwt();
    if (token) {
      !isLoggedIn && setIsLoggedIn(true);
      userData && setUserName(`${userData.firstName} ${userData.lastName}`);
    } else {
      setIsLoggedIn(false);
    }
  };

  const clearBasketState = () => {
    clearItemsAfterPayment();
    clearBasket();
    cancelBasket && setCancelBasket(false);
  };

  const handleBasketModalClick = () => {
    if (!!cancelBasket && !!basketCancelled) {
      if (!location.pathname.startsWith('/koszyk')) {
        clearBasketState();
      } else {
        setUserSaw0MinutesModal(true);
      }
    } else if (lessThan2MinutesLeft && !userSaw2MinutesLeftToPayModal) {
      setUserSaw2MinutesLeftToPayModal(true);
    }

    setBasketTimeOutModal(false);
  };

  const isSearchPage = () => (location.pathname.startsWith('/profil') ? false : true);

  const paymentBasketMode =
    location.pathname.startsWith('/koszyk/platnosc') || location.pathname.startsWith('/pobierzbilet');

  useEffect(() => {
    checkToken();
    localToken && setBasketFromStorage();

    if (
      !!lessThan2MinutesLeft &&
      !userSaw2MinutesLeftToPayModal &&
      !basketTimeOutModal &&
      !paymentBasketMode &&
      !basketCancelled
    ) {
      setBasketTimeOutModal(true);
    }

    if (!!basketCancelled && !paymentBasketMode && !lessThan2MinutesLeft && !userSaw0MinutesModal) {
      !cancelBasket && setCancelBasket(true);
      !basketTimeOutModal && setBasketTimeOutModal(true);
    }

    if (!!basketCancelled && !paymentBasketMode && !!userSaw0MinutesModal && !location.pathname.startsWith('/koszyk')) {
      clearBasketState();
    }
  }, [
    location.pathname,
    userData,
    localToken,
    lessThan2MinutesLeft,
    paymentBasketMode,
    basketCancelled,
    userSaw0MinutesModal,
  ]);

  useEffect(() => {
    if (!localToken) {
      setIsLoggedIn(false);
    }
  }, [localToken]);

  return (
    <section className={S.menu_container}>
      <SkipLinks main={isSearchPage()} />
      <a
        href="https://www.intercity.pl"
        aria-label={t('10028')}
        aria-labelledby="logo"
        title={t('10028')}
        className={S.intercity_link}
      >
        <span className="sr-only">{t('10028')}</span>
        {isContrastTheme ? <FullLogoContrast /> : <FullLogo />}
      </a>
      <h1 className="hiddenLabel">PKP Intercity</h1>
      <div className={S.menu_list}>
        <Link to="/" className={S.menu_link} id="buy-tickets-button" aria-label={t('17076')}>
          <p className={S.menu_text}>{t('17076')}</p>
        </Link>
        {isLoggedIn && !isGuest && (
          <Link to="/profil/mojebilety" className={S.menu_link} aria-label={t('10003')}>
            <p className={S.menu_text}>{t('10003')}</p>
          </Link>
        )}
        {isLoggedIn && !isGuest && (
          <Link to="/profil/profilezakupowe" className={S.menu_link} aria-label={t('10004')}>
            <p className={S.menu_text}>{t('10004')}</p>
          </Link>
        )}
        <SelectLanguage setExpandLanguage={setExpandLanguage} expandLanguage={expandLanguage} />
        <button
          id="contrast-mode-button"
          data-testid={`${TAG}-contrast-button`}
          className={S.menu_button}
          onClick={toggleTheme}
          aria-label={t('10006')}
        >
          {isContrastTheme ? <ChangeThemeContrast /> : <ChangeTheme />}
        </button>
        <div className={S.menu_button}>
          <div className={S.flex_box}>
            <TextResizeControls />
          </div>
        </div>
        <a
          href={isLoggedIn && !isGuest ? '/profil/mojedane' : '/profil'}
          onClick={() => {
            if (isGuest) window.sessionStorage.removeItem('eic2Token');
          }}
          className={S.menu_link}
          id="user-login-button"
          aria-label={t('10010')}
        >
          <div className={S.flex_box}>
            {isContrastTheme ? <CircleProfileContrast /> : <CircleProfile />}
            <h2 className="hiddenLabel">{t('10011')}</h2>
            <p className={S.menu_text}>{isLoggedIn && !isGuest ? userName : t('10011')}</p>
          </div>
        </a>
        {(!isLoggedIn || (isLoggedIn && isGuest)) && (
          <button className={S.menu_button} onClick={handleRegisterClick}>
            <span className={S.menu_text}>{t('10012')}</span>
          </button>
        )}
        {isLoggedIn && !isGuest && <ShoppingBasketCounterWithBadge />}
        <div className={`${S.menu_button} ${S.burger}`}>
          <button
            className={S.burgerMenuStyles}
            aria-label={burgerMenuOpen ? t('10013') : t('10014')}
            onClick={handleMenuClick}
          >
            {isContrastTheme ? <MenuContrast /> : <Menu />}
          </button>
          <h2 className="hiddenLabel">{t('10015')}</h2>
          <ul className={`${S.burger_menu} ${burgerMenuOpen ? S.burger_menu__open : null}`}>
            {profileMenuElements &&
              profileMenuElements
                .filter((x) => {
                  const scope = getJwtTokenOrDefault('eic2Token')?.scope;

                  switch (scope) {
                    case 'guest':
                      return x.desktop && !x.hideInMenu && !x.disableInGuestAcc;
                    case 'user':
                      return x.desktop && !x.hideInMenu && x.needLogin;
                    default:
                      return x.desktop && !x.hideInMenu && !x.disableInGuestAcc && !x.needLogin;
                  }
                })
                .map((el) => (
                  <li key={el.id}>
                    <a href={el.url} aria-label={el.text}>
                      {t(el.text)}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
      </div>
      {isModalOpen && <RegisterModal closeFn={() => setIsModalOpen(false)} />}
      {basketTimeOutModal && (
        <ConfirmationModal
          customHeader={cancelBasket ? t('29260') : t('29261')}
          closeFn={() => {
            handleBasketModalClick();
          }}
          confirmFn={() => {
            handleBasketModalClick();
            navigate('/koszyk/platnosc');
          }}
          customConfirmBtnTxt={t('20002')}
          customCancelBtnTxt={t('29262')}
          hideConfirmButton={cancelBasket}
          hideRejectButton={cancelBasket}
          columnButtons
        >
          <ShoppingBasketTimeoutModal timeOutMode={cancelBasket} />
        </ConfirmationModal>
      )}
    </section>
  );
};
export default TopMenu;
