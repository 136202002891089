export const SHOP_DISCOUNT_CODES = {
  NORMAL: 1010,
  ONE_PLN_EIP: 1340,
  MKZ_FIP: 1350,
  SENIOR_TICKET: 1360,
  UUT_EMPLOYEE: 1400,
  UUT_FAMILY: 1410,
  UUT_RETIRED: 1420,
  AGREEMENT: 1510,
  LARGE_FAMILY_37: 1570,
  ANIT_COMMUNIST: 1630,
  FAMILY: 1650,
  DISABLED_VETERAN_51: 1670,
  VETERAN: 1671,
  LARGE_FAMILY_49: 1680,
  CHILD_37: 1710,
  SOLDIER: 1730,
  PENSIONER_2_TRIPS_PER_YEAR: 1740,
  CIVILIAN_BLIND_FROM_WAR_INCAPABLE_OF_UNAIDED_EXISTENCE_37: 1741,
  INCAPABLE_OF_UNAIDED_EXISTENCE_37: 1742,
  DISABLED_VETERAN_37: 1743,
  BLIND_NOT_INCAPABLE_OF_UNAIDED_EXISTENCE: 1750,
  CHILD_49: 1760,
  CHILD_UP_TO_4_YEARS: 1770,
  DISABLED_CHILD_OR_STUDENT_AND_CARETAKER: 1780,
  GUIDE_OR_GUARDIAN: 1790,
  WAR_SERVICE_DISABLED_37: 1820,
  WAR_SERVICE_DISABLED_78: 1830,
  COMBATANT_78: 1831,
  COMBATANT_GUIDE: 1840,
  CIVILIAN_BLIND_FROM_WAR_INCAPABLE_OF_UNAIDED_EXISTENCE_78: 1870,
  POLE_CARD_37: 1880,
  INCAPABLE_OF_UNAIDED_EXISTENCE_51: 1980,
  STUDENT_51: 1990,
  NORMAL_2: 2010,
  SENIOR_TICKET_2: 2390,
  UUT_EMPLOYEE_2: 2400,
  UUT_FAMILY_2: 2420,
  AGREEMENT_2: 2510,
  LARGE_FAMILY_49_2: 2680,
  CIVILIAN_BLIND_FROM_WAR_INCAPABLE_OF_UNAIDED_EXISTENCE_37_2: 2740,
  BLIND_NOT_INCAPABLE_OF_UNAIDED_EXISTENCE_2: 2750,
  CHILD_49_2: 2760,
  DISABLED_CHILD_OR_STUDENT_AND_CARETAKER_2: 2780,
  INCAPABLE_OF_UNAIDED_EXISTENCE_51_2: 2980,
  STUDENT_51_2: 2990,
} as const;

export const LIMITED_CONNECTION_DISCOUNT_CODES = [
  SHOP_DISCOUNT_CODES.ONE_PLN_EIP,
  SHOP_DISCOUNT_CODES.CIVILIAN_BLIND_FROM_WAR_INCAPABLE_OF_UNAIDED_EXISTENCE_78,
] as const;
