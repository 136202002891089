import {useEffect, useState} from 'react';

export const useIsTooLateToReturn = (departureDate: string, isMobile: boolean, openDrawer?: boolean) => {
  const isTooLateToReturn = () => {
    const currentTime = new Date().getTime();
    const departureTime = new Date(departureDate).getTime();
    const timeDifference: number = departureTime - currentTime;
    return timeDifference <= 5 * 60 * 1000;
  };
  const [isButtonActive, setIsButtonActive] = useState<boolean>(!isTooLateToReturn());

  useEffect(() => {
    if (!isMobile) {
      if (openDrawer) {
        const interval = setInterval(() => {
          isTooLateToReturn() && setIsButtonActive(false);
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }
    } else {
      const interval = setInterval(() => {
        isTooLateToReturn() && setIsButtonActive(false);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [openDrawer]);

  return isButtonActive;
};
