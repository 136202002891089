import {useStyledThemeState} from 'common/theme';

import {ICONS_DEFINITION} from './Icon.const';
import {IconNames, IconProps} from './Icon.types';

import S from './Icon.module.scss';
// TODO komponent jest tymczasowym rozwiązaniem - do rozważenia zmiana na sprite, oraz trzeba omówić kwestie theme i styled components

const Icon = ({icon, variant, ariaLabel}: IconProps) => {
  const {isContrastTheme} = useStyledThemeState();

  const getIconComponent = (iconName: IconNames) => {
    const IconComponent = isContrastTheme ? ICONS_DEFINITION[iconName].contrast : ICONS_DEFINITION[iconName].normal;

    return (
      <IconComponent
        className={variant ? S[variant] : ''}
        aria-label={ariaLabel || undefined}
        aria-hidden={!ariaLabel}
      />
    );
  };

  return getIconComponent(icon);
};
export default Icon;
