import {z} from 'zod';

export const IBNRSchemaConfig = {
  minLength: 1,
  maxLength: 4,
};

const code = z
  .union([z.string(), z.number()])
  .transform((v) => v.toString())
  .refine((v) => v.match(/^([0-9]*)$/), {message: 'Invalid code characters'})
  .refine((v) => v.length <= IBNRSchemaConfig.maxLength && v.length >= IBNRSchemaConfig.minLength, {
    message: `Cannot be longer than ${IBNRSchemaConfig.maxLength} characters`,
  })
  .refine((v) => v !== '0', {message: 'Code 0 is invalid'});

const schema = z
  .object({
    code,
  })
  .strict();

const keys = schema.keyof();
type SchemaKeys = z.infer<typeof keys>;

export const CodeIBNRSchema = (key: SchemaKeys) => {
  return schema.shape[key];
};
