import React, {useEffect, useState} from 'react';
import {useMyTicketsData} from 'common/api/myTickets';
import {Box} from '@mui/material';
import {Tabs, Tab} from 'common/components/base/Tabs';
import {Pagination} from 'common/components/feature/Pagination';
import Icon from 'common/components/base/Icon';
import {MyTicketsListVariants} from './MyTicketsList.types';
import {MyTicketsListContainer, HeaderContainer, BackButton, Title, AddTicketContainer} from './MyTicketsList.styles';
import {myTicketsListVariantRequestParams, tabLabels, noDataTexts} from './MyTicketsList.consts';
import {MyTicketsProvider, useMyTicketsState, useMyTicketsDispatch} from './hooks/useTickets';
import {MyTicketProvider} from './hooks/useTicket';
import {useMemoPagination} from 'common/hooks/useMemoPagination';
import {ReturnTicketModal, PendingTicket} from './wrappers';
import {MyTicketsListPage} from './components';
import AddPhysicalTicket from 'components/pages/UserProfile/components/AddPhysicalTicket/AddPhysicalTicket';
import NoDataToDisplay from 'components/pages/UserProfile/components/NoDataToDisplay/NoDataToDisplay';
import {checkIfGuest} from 'Utils/commonFunctions';
import {useTranslation} from 'react-i18next';
import {useDesktopCheckAllGuestTickets} from './hooks/useDesktopCheckAllGuestTickets';
import {useWindowResize} from 'common/hooks/screen';

interface MyTicketsListProps {
  title: string;
  variants: MyTicketsListVariants[];
}

const MyTicketsListView = React.memo(({title}: {title: string}) => {
  const {t} = useTranslation();
  const {variants, ticketVariant, currentPage, isMobileVersion} = useMyTicketsState();
  const {changeTicketVariant, changeCurrentPage, setTicketVariant} = useMyTicketsDispatch();

  const [isFirstDataFetch, setIsFirstDataFetch] = useState<boolean>(true);
  const ticketVariantFromGuestLogin = useDesktopCheckAllGuestTickets();

  const {data, isFetching, isFetched} = useMyTicketsData({
    ...myTicketsListVariantRequestParams[ticketVariant],
    numerStrony: currentPage,
  });

  const {allPages} = useMemoPagination(data?.paginacja?.wszystkichStron);

  const isLoggedInGuest = checkIfGuest();
  const {isMobile} = useWindowResize();

  const setReturnedTabHandler = () => {
    setIsFirstDataFetch(false);
    isMobile ? variants[0] === 'RETURNED' && setTicketVariant('RETURNED') : setTicketVariant('RETURNED');
  };

  useEffect(() => {
    const storageData = sessionStorage.getItem('eic2MyTickets');
    if (!isMobile && storageData) {
      const {hasReturnedTicket} = JSON.parse(storageData);
      if (isLoggedInGuest && hasReturnedTicket) {
        setReturnedTabHandler();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storageData = sessionStorage.getItem('eic2MyTickets');
    const shouldBeActiveReturnedTab =
      isFetched &&
      isFirstDataFetch &&
      !storageData &&
      isLoggedInGuest &&
      ticketVariant === 'SINGLE' &&
      !data?.identyfikatory.length;

    if (shouldBeActiveReturnedTab) setReturnedTabHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);
  useEffect(() => {
    ticketVariantFromGuestLogin && setTicketVariant(ticketVariantFromGuestLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketVariantFromGuestLogin]);
  return (
    <MyTicketsListContainer>
      <HeaderContainer>
        <BackButton to="/profil">
          <Icon icon="arrow" variant="small" />
        </BackButton>
        <Title>{title}</Title>
      </HeaderContainer>
      {variants.length > 1 ? (
        <Tabs value={ticketVariant} onChange={changeTicketVariant} variant={isMobileVersion ? 'fullWidth' : 'standard'}>
          {variants.map((variant) =>
            variant !== 'SEASONAL' || (variant === 'SEASONAL' && !isLoggedInGuest) ? (
              <Tab
                key={variant}
                tabIndex={0}
                value={variant}
                label={t(tabLabels[variant][isMobileVersion ? 'mobile' : 'desktop'])}
              />
            ) : null,
          )}
        </Tabs>
      ) : null}

      {ticketVariant === 'SEASONAL' && (
        <AddTicketContainer>
          <AddPhysicalTicket />
        </AddTicketContainer>
      )}
      <Box mt={2.5}>
        {currentPage === 1 && <PendingTicket />}
        <MyTicketsListPage identifiers={data?.identyfikatory} />

        {isFetched && data?.identyfikatory.length === 0 && (
          <NoDataToDisplay variant={ticketVariant} text={t(noDataTexts[ticketVariant])} imageTicket="ticket" />
        )}

        {((isFetched && data?.identyfikatory.length !== 0) || isFetching) && (
          <Pagination count={allPages} page={currentPage} onChange={changeCurrentPage} />
        )}
      </Box>
    </MyTicketsListContainer>
  );
});

export const MyTicketsList = React.memo(({title, variants}: MyTicketsListProps) => {
  const {t} = useTranslation();
  return (
    <MyTicketsProvider variants={variants}>
      <MyTicketProvider>
        <MyTicketsListView title={t(title)} />
        <ReturnTicketModal />
      </MyTicketProvider>
    </MyTicketsProvider>
  );
});
