import {FC, lazy, useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import SingleFareTicketRouting from './SingleFareTicketRouting';
import {checkIfGuest} from 'Utils/commonFunctions';
import {useFetchAllLibrary} from 'common/api/localData';
import CenteredLoader from 'components/common/Loader/CenteredLoader';

const LazyLogin = lazy(() => import('components/pages/UserProfile/Login/Login'));
const LazyRegister = lazy(() => import('components/pages/Register'));
const LazyUserProfileRouting = lazy(() => import('./UserProfileRouting'));
const LazyReservationRouting = lazy(() => import('./ReservationRouting'));
const LazyShoppingCartRouting = lazy(() => import('./ShoppingCartRouting'));
const LazySeasonTicketsRoutes = lazy(() => import('./SeasonalTicketsRouting'));
const LazyForeignFareTicketsRoutes = lazy(() => import('./ForeignFareTicketsRouting'));

export const AppRouting: FC = () => {
  const {isLoading} = useFetchAllLibrary(false);
  const location = useLocation();
  useEffect(() => {
    if (!checkIfGuest()) sessionStorage.removeItem('eic2MyTickets');
  }, [location]);

  if (isLoading) return <CenteredLoader />;
  // else if (isError) return //TODO add error page
  else
    return (
      <Routes>
        <Route path="/login" element={<LazyLogin />} />
        <Route path="/register" element={<LazyRegister />} />
        <Route path="/polaczenia-miedzynarodowe/*" element={<LazyForeignFareTicketsRoutes />} />
        <Route path="/biletokresowy/*" element={<LazySeasonTicketsRoutes />} />
        <Route path="/koszyk/*" element={<LazyShoppingCartRouting />} />
        <Route path="/profil/*" element={<LazyUserProfileRouting />} />
        <Route path="/rezerwacje/*" element={<LazyReservationRouting />} />
        <Route path="/*" element={<SingleFareTicketRouting />} />
        <Route path="/pobierzbilet" />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
};
