import {FC, useMemo, useState} from 'react';
import OutlineButton from 'components/common/OutlineButton';

import style from './BuyerProfile.module.scss';
import Edit from 'assets/orangePencil.svg';
import EditContrast from 'assets/contrastPencil.svg';
import Profile from 'assets/profile.svg';
import ProfileContrast from 'assets/profileContrastDesktop2.svg';
import ArrowRight from 'assets/orangeArrowRightNarrow.svg';
import ArrowRightContrast from 'assets/whiteArrowRightNarrow.svg';
import OrangeCircleSmall from 'assets/orangeCircleSmall.svg';
import ContrastCircleSmall from 'assets/contrastCircleSmall2.svg';
import OrangePinSmall from 'assets/orangePinSmall.svg';
import ContrastPinSmall from 'assets/contrastPinSmall2.svg';
import TrashBin from 'assets/trashbin.svg';
import TrashBinContrast from 'assets/trashbinContrast.svg';
import {BuyerProfile, Discount} from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfiles.interface';
import {getTicketLabelsFromDiscounts, returnStationByCode} from 'Utils/commonFunctions';
import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';
import {BuyerProfileActions, BuyerProfileScreen, useDispatch} from 'Utils/BuyerProfileContext';
import {useNavigate} from 'react-router-dom';
import {useSWRConfig} from 'swr';
import api from 'api/user/repository';
import ConfirmationModal from 'components/common/ConfirmationModal/ConfirmationModal';
import {format} from 'date-fns';
import InitialObject from 'components/pages/PurchasePath/PurchasePathDesktop/AmenitiesNewDesktop/InitialObject';
import {makeQuerySearch} from 'Utils/querySearch/queryString';
import {IConnectionSearchRequestPlus} from 'api/pociagi/interfaces';
import {useTranslation} from 'react-i18next';
import {useAmenitiesActions} from 'common/hooks/useAmenities';

interface Props {
  profile: BuyerProfile;
}

const SingleProfile: FC<Props> = ({profile}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  const {stationsList, shopDiscounts} = useData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {mutate} = useSWRConfig();
  const [openModal, setOpenModal] = useState(false);
  const {setContextAmenities} = useAmenitiesActions();

  const childTickets = useMemo(() => {
    return (profile.discounts || []).filter((x) => x.type === 'CHILD');
  }, [profile.discounts]);

  const adultTickets = useMemo(() => {
    return (profile.discounts || []).filter((x) => x.type === 'ADULT');
  }, [profile.discounts]);

  const handleDeleteProfile = async () => {
    if (profile.id) {
      const result = await api.deleteBuyerProfile(profile.id);
      let info = t('29215', {profile: profile.name});

      if (result?.error) {
        info = t('29216', {profile: profile.name});
      }

      await mutate('get_user_profiles');
      dispatch({
        type: BuyerProfileActions.DELETE_PROFILE,
        screen: BuyerProfileScreen.MAIN,
        deleteProfileInfo: info,
      });
      setOpenModal(false);
    }
  };

  const makeObjectSearchFrom = () => {
    let parsedAmenities = undefined;

    if (profile.filters && profile.filters.length > 0) {
      const amenities = profile.filters.find((x) => x.filter.startsWith('amenities_'));

      if (amenities) {
        const amenitiesString = amenities.filter.replaceAll('amenities_', '');
        parsedAmenities = JSON.parse(amenitiesString);
        setContextAmenities(parsedAmenities);
      }
    }

    const amenities = parsedAmenities ? parsedAmenities : InitialObject;

    const searchObj = {
      urzadzenieNr: 0,
      metoda: '',
      dataWyjazdu: format(new Date(), 'yyyy-MM-dd'),
      stacjaWyjazdu: Number(profile.stationFrom),
      stacjaPrzyjazdu: Number(profile.stationTo),
      stacjePrzez: [],
      polaczeniaNajszybsze: 0,
      liczbaPolaczen: 0,
      czasNaPrzesiadkeMin: 3,
      czasNaPrzesiadkeMax: 1440,
      ...amenities,
      przyjazd: 0,
      time: format(new Date(), 'HH:mm'),
      ticket100: (profile.discounts || [])
        .filter((p: Discount) => p.type === 'ADULT')
        .map((x: Discount) => {
          return Number(x.code);
        })
        .join(';'),
      ticket50: (profile.discounts || [])
        .filter((p: Discount) => p.type === 'CHILD')
        .map((x: Discount) => {
          return Number(x.code);
        })
        .join(';'),
    };
    return searchObj;
  };

  return (
    <div className={`${style.single_profile_box}`}>
      <header>
        <div>
          <span>{t('22015')}</span>
          <span className={style.profileNameSpan}>{profile.name}</span>
        </div>
        <div className={style.edit_delete_buttons}>
          <button
            aria-label={t('29077')}
            onClick={() => {
              dispatch({
                type: BuyerProfileActions.SET_PROFILE_AND_SCREEN,
                profile: profile,
                screen: BuyerProfileScreen.EDIT,
              });
            }}
          >
            <img src={isContrastTheme ? EditContrast : Edit} alt={t('29076')} />
            <span>{t('29214')}</span>
          </button>
          <button aria-label={t('29078')} onClick={() => setOpenModal(true)}>
            <img
              src={isContrastTheme ? TrashBinContrast : TrashBin}
              alt={t('29037')}
              className={`${style.trash_btn}`}
            />
          </button>
        </div>
      </header>
      <p className={`${style.profile_break_txt} ${style.hide_on_mobile}`}>{t('17028')}</p>
      <div className="grid col-12 col-sm-12">
        <div className={`col-4 col-sm-12 ${style.profile_trip}`}>
          <div className={style.profile_trip__mobile}>
            <img src={isContrastTheme ? ContrastCircleSmall : OrangeCircleSmall} alt={t('29080')} />
            <div className={style.border_btwin_icons} />
            <img src={isContrastTheme ? ContrastPinSmall : OrangePinSmall} alt={t('29081')} />
          </div>
          <div className={style.profile_trip__desktop}>
            <span>{profile.stationFrom && returnStationByCode(profile.stationFrom, stationsList)}</span>
            <img src={isContrastTheme ? ArrowRightContrast : ArrowRight} alt={t('11027')} />
            <span>{profile.stationTo && returnStationByCode(profile.stationTo, stationsList)}</span>
          </div>
        </div>
        <div className={`col-4 col-sm-12 ${style.profile_tickets}`}>
          <div>
            <img src={isContrastTheme ? ProfileContrast : Profile} alt={t('22015')} />
            {/* <span>{profile.discounts?.length}x Bilet normalny</span> */}
            <div className={style.profile_ticketsTickets}>
              {adultTickets &&
                getTicketLabelsFromDiscounts(adultTickets, true, shopDiscounts, t).map((label, index) => (
                  <span key={`adultLabel${index}`}>{label}</span>
                ))}

              {childTickets &&
                getTicketLabelsFromDiscounts(childTickets, false, shopDiscounts, t).map((label, index) => (
                  <span key={`childLabel${index}`}>{label}</span>
                ))}
            </div>
          </div>
        </div>
        <div className="col-4 col-sm-12">
          <OutlineButton
            path={() => {
              navigate(
                `/wyszukiwanie${makeQuerySearch(makeObjectSearchFrom() as IConnectionSearchRequestPlus)}&profilZakupowy=${profile.name}`,
              );
            }}
            text={t('29082')}
          />
        </div>
      </div>
      {openModal && (
        <div className={style.confirmModal}>
          <ConfirmationModal closeFn={setOpenModal} confirmFn={handleDeleteProfile} customHeader={t('29405')}>
            <div className={style.confirmModal__info}>
              <p>
                {t('29083')}: {profile.name}
              </p>
              <p>{t('29084')}?</p>
            </div>
          </ConfirmationModal>
        </div>
      )}
    </div>
  );
};

export default SingleProfile;
