import {EVACountriesCodesList} from './consts';
import {z} from 'zod';

export const EVASchemaConfig = {
  length: 7,
};

const code = z
  .union([z.string(), z.number()])
  .transform((v) => v.toString())
  .refine((v) => v.match(/^([0-9]*)$/), {message: 'Invalid code characters'})
  .refine((v: string) => v.length === EVASchemaConfig.length, {
    message: `Must be exactly ${EVASchemaConfig.length} characters long`,
  })
  .refine(
    (v) => EVACountriesCodesList.includes(v.slice(0, 2)),
    (v) => ({message: `Code has invalid country segment (first two characters of EVA code) -  ${v}`}),
  );

const isMetaStation = code.refine((v) => {
  // currently works only for polish stations
  return v.slice(2, 4) === '96';
});

const isForeignStation = code.refine((v) => {
  return !v.startsWith('51');
});

const schema = z
  .object({
    code,
    isMetaStation,
    isForeignStation,
  })
  .strict();

const keys = schema.keyof();
type SchemaKeys = z.infer<typeof keys>;

export const CodeEVASchema = (key: SchemaKeys) => {
  return schema.shape[key];
};
