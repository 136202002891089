import {env} from 'env';
import api from '../axios-middleware';

import {
  IChangeTransportDocumentFullPayload,
  IChangeTransportDocumentPayload,
  ICheckDocumentRequest,
  ICheckDocumentResponse,
  IDeleteTransportDocumentFullPayload,
  IDeleteTransportDocumentPayload,
  IDownloadTransportDocumentFullPayload,
  IDownloadTransportDocumentPayload,
  IDownloadTransportDocumentResponse,
} from './interfaces';
import {IErrorsList} from 'api/error-interface';

import {getToken} from 'Utils/auth';

const methods = {
  checkDocument: async (obj: ICheckDocumentRequest) => {
    const token = await getToken();
    return await api.postExt<ICheckDocumentResponse>(
      '/server/private/endpoint/Dokumenty',
      {
        ...obj,
        urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
        metoda: 'sprawdzDokument',
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  downloadTransportDocument: async (obj: IDownloadTransportDocumentPayload) => {
    const payload: IDownloadTransportDocumentFullPayload = {
      ...obj,
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'pobierzDokumentPrzewozowy',
    };

    const token = await getToken();
    return await api.postExt<IDownloadTransportDocumentResponse>('/server/private/endpoint/Dokumenty', payload, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
  changeTransportDocument: async (obj: IChangeTransportDocumentPayload) => {
    const payload: IChangeTransportDocumentFullPayload = {
      ...obj,
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'zmienDokumentPrzewozowy',
    };

    const token = await getToken();
    return await api.postExt<IErrorsList>('/server/private/endpoint/Dokumenty', payload, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
  deleteTransportDocument: async (obj: IDeleteTransportDocumentPayload) => {
    const payload: IDeleteTransportDocumentFullPayload = {
      ...obj,
      urzadzenieNr: env.REACT_APP_DEVICE_NUMBER,
      metoda: 'zmienDokumentPrzewozowy',
    };

    const token = await getToken();
    return await api.postExt<IErrorsList>('/server/private/endpoint/Dokumenty', payload, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
};

export default methods;
