import React from 'react';
import {useMyTicketsState} from '../../hooks/useTickets';
import {isSeasonalOfferCode} from 'common/utils';
import MyTicketsSingleUnpaidTicket from 'components/pages/UserProfile/components/MyTickets/MyTicketsSingleUnpaidTicket';
import MyTicketsSingleTicketMobileUnpaid from 'components/pages/UserProfile/components/MyTickets/MyTicketsSingleTicketMobileUnpaid';

export const PendingTicket = React.memo(() => {
  const {ticketVariant, isMobileVersion} = useMyTicketsState();

  const unpaidTicket = window.localStorage.payLaterTicket ? JSON.parse(window.localStorage.payLaterTicket) : undefined;
  const offerCode = unpaidTicket?.ticketFrom?.ofertaKod;
  const isUnpaidTicketSeasonal = React.useMemo(() => isSeasonalOfferCode(offerCode), [offerCode]);

  return (
    offerCode !== undefined &&
    ((!isUnpaidTicketSeasonal && ticketVariant === 'SINGLE') ||
      (isUnpaidTicketSeasonal && ticketVariant === 'SEASONAL')) && (
      <React.Fragment>
        {isMobileVersion ? <MyTicketsSingleTicketMobileUnpaid /> : <MyTicketsSingleUnpaidTicket />}
      </React.Fragment>
    )
  );
});
