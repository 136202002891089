import {FC, Fragment, useRef, useState, useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {UserTicketsContext} from 'Utils/UserTicketsContext';

import ICalendarLink from 'react-icalendar-link';

import {IDownloadTicketResponse, ISingleId} from 'api/sprzedaz/interfaces';
import {
  getFullTimeFromDate,
  getFullDate,
  getTicketSource,
  returnStationByCode,
  capitalizeFirstLetter,
  returnAdditionalTypeTicketData,
  getAdditionalTicketsFromPrimaryTicket,
} from 'Utils/commonFunctions';
import {addTripToCalendar} from 'Utils/addTripToCalendar';
import {useData} from 'Utils/DataContex';
import {useStyledThemeState} from 'common/theme';

import style from './MyTickets.module.scss';
import Google from 'assets/google.svg';
import MyTicketsSingleTicketInnerData from './MyTicketsSingleTicketInnerData';
import {makeTicketQuery as makeQuery} from 'Utils/tickets';
import {SeasonalTicketInfoBanner} from './MyTicketsSingleTicket.style';
import {Text} from 'common/components/base/Text';
import {SEASONAL_OFFER_CODES} from 'common/consts';
import {isAdditionalTicketKind, isPrimaryTicketKind} from 'common/pages/UserProfile/MyTickets';
import {isReservationFromSeasonalTicket} from '../../common/utils';
import {TicketComponentVariant} from 'common/pages/UserProfile/MyTickets/components/Ticket/TicketComponent';
import {isTicketManuallyAdded, isForeignConnection} from 'common/utils';
import {MyTicket, ticketKinds} from 'common/api/myTickets';
import {formatInTimeZone, getTimezoneOffset} from 'date-fns-tz';
interface Props {
  identifier: ISingleId;
  tickets: MyTicket[];
  history: number;
  isReturned?: boolean;
  isSeasonal?: boolean;
  variant?: TicketComponentVariant;
}

const MyTicketsSingleTicket: FC<Props> = ({identifier, tickets, history, isReturned, isSeasonal, variant}) => {
  const {t} = useTranslation();
  const {stationsList} = useData();
  const {isContrastTheme} = useStyledThemeState();

  const {setReturnedTicketData} = useContext(UserTicketsContext);

  const isPrimaryTicket = (tick: MyTicket) => isPrimaryTicketKind(tick.biletRodzaj);
  const isAdditionalTicket = (tick: MyTicket) => isAdditionalTicketKind(tick.biletRodzaj);

  const primaryTickets = tickets.filter(isPrimaryTicket);
  const additionalTickets = tickets.filter(isAdditionalTicket);

  const {
    odcinki,
    biletNr,
    biletRodzaj,
    biletSeria,
    biletZrodloKod,
    ofertaKod,
    liczbaBagazy,
    liczbaPsow,
    liczbaRowerow,
    cenaBagaze,
    cenaPsy,
    cenaRowery,
    ofertaNazwa,
  } = primaryTickets[0] ?? {};

  const startPoint = odcinki[0]?.stacjaOdKod as number;
  const endPoint = odcinki[odcinki.length - 1]?.stacjaDoKod as number;
  const startDate = odcinki[0]?.wyjazdData as string;
  const endDate = odcinki[odcinki.length - 1]?.przyjazdData as string;
  const oneHourInMilliseconds: number = 3600000;
  const HOURS = {
    ONE: 1,
    TWO: 2,
  };
  const getDaylightSavingTimeOffsetInPoland = (date: Date) => {
    const offset: number = getTimezoneOffset('Europe/Warsaw', date);
    return offset === oneHourInMilliseconds ? HOURS.ONE : HOURS.TWO;
  };

  const formatDate = (date: string) => {
    const offset = getDaylightSavingTimeOffsetInPoland(new Date(date));
    return formatInTimeZone(date, 'Europe/Warsaw', `yyy-MM-dd'T'HH:mm:ss+0${offset}:00`);
  };
  const startDateFormatted = startDate && formatDate(startDate);
  const endDateFormatted = startDate && formatDate(endDate);

  const ticketNumber = biletNr;
  const ticketKind = biletRodzaj;
  const ticketSerial = biletSeria;
  const isManuallyAdded = isTicketManuallyAdded(biletZrodloKod, ticketSerial);
  const isIntercityCard = ofertaKod === SEASONAL_OFFER_CODES.INTERCITY_CARD;

  const isInternationalEPAWithAdditionalTicket =
    biletRodzaj === ticketKinds.INTERNATIONAL_EPA && (liczbaBagazy > 0 || liczbaPsow > 0 || liczbaRowerow > 0);

  const internationalEPAAdditionalTickets = isInternationalEPAWithAdditionalTicket
    ? getAdditionalTicketsFromPrimaryTicket(liczbaBagazy, cenaBagaze, liczbaPsow, cenaPsy, liczbaRowerow, cenaRowery)
    : [];
  const [active, setActive] = useState(0);

  const ticketSource = getTicketSource(ticketSerial as string);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const isForeign = useMemo(
    () =>
      primaryTickets?.some((ticket: MyTicket): boolean =>
        ticket.odcinki.some(({stacjaDoKod, stacjaOdKod}) => isForeignConnection(stacjaOdKod, stacjaDoKod)),
      ),
    [primaryTickets],
  );

  const calendarEventFileName = `PKP_Intercity_${returnStationByCode(startPoint, stationsList)}_${returnStationByCode(endPoint, stationsList)}_${getFullDate(
    startDate,
  )}.ics`;

  const calendarEvent = {
    title: `podroz_z_${returnStationByCode(startPoint, stationsList)}_do_${returnStationByCode(endPoint, stationsList)}`,
    description: `PKP Intercity ${t('29419')} ${returnStationByCode(startPoint, stationsList)} ${t('29420')} ${returnStationByCode(endPoint, stationsList)}, ${getFullDate(
      startDate,
    )} ${getFullTimeFromDate(startDate)} - ${getFullDate(
      endDate,
    )} ${getFullTimeFromDate(endDate)}, ${t('29009')} ${ticketNumber}`,
    startTime: startDateFormatted,
    endTime: endDateFormatted,
    location: startPoint?.toString(),
  };

  return (
    <div className={`${style.ticket_container} `} ref={containerRef}>
      {/*         Desktop      */}
      {!history && isManuallyAdded && (
        <SeasonalTicketInfoBanner>
          <Text variant="P3" weight={600} palette="text.info">
            {t('29397')}
          </Text>
        </SeasonalTicketInfoBanner>
      )}
      <div className={`${style.ticket_head_box}`}>
        <div className={style.ticket_header_temp}>
          <div className={style.ticket_header}>
            {ticketNumber && identifier.anulowany !== 1 ? (
              <>
                {' '}
                <p className={`${style.blue_text} ${style.blue_textLight}`}>
                  {isReservationFromSeasonalTicket(ticketKind ?? 0) ? t('29254') : capitalizeFirstLetter(t('24066'))}
                </p>
                {primaryTickets
                  ? primaryTickets.map((el, index, arr) => {
                      return (
                        <p className={`${style.navy_text_big} ${style.navy_text_light}`} key={el.biletNr?.toString()}>
                          {el.biletSeria}
                          {el.biletNr?.toString()}
                          {index !== arr.length - 1 && <span>,</span>}
                        </p>
                      );
                    })
                  : null}
              </>
            ) : (
              <>
                {identifier.anulowany === 1 ? (
                  <>
                    {' '}
                    <p className={`${style.blue_text} ${style.blue_textLight}`}>{t('29178')}</p>
                    <p
                      className={`${style.navy_text_big} ${style.navy_text_light}`}
                      key={identifier.transakcjaNr.toString()}
                    >
                      {identifier.transakcjaNr.toString()}
                    </p>
                  </>
                ) : (
                  <p className={style.blue_text}>{t('29179')}</p>
                )}
              </>
            )}
            {!!ticketSource.length && <div className={style.ticketTag}>{t(ticketSource)}</div>}
          </div>
          {isSeasonal && (
            <div className={style.ticket_header}>
              <p className={`${style.blue_text} ${style.blue_textLight}`}>{t('13103')}</p>
              <p className={`${style.navy_text_small} ${style.navy_text_light}`}>
                {isIntercityCard ? t('13083') : ofertaNazwa}
              </p>
            </div>
          )}
          {identifier?.zwrocony === 1 && (
            <div className={style.ticket_header}>
              <p className={style.red_text}>{t('29153')}</p>
            </div>
          )}
          {identifier?.anulowany === 1 && (
            <div className={style.ticket_header}>
              <p className={style.red_text}>{t('29180')}</p>
            </div>
          )}
        </div>
        {!isSeasonal && (
          <div className={style.ticket_head_icons}>
            <div className={style.icon_box}>
              {history === 0 && (
                <>
                  <ICalendarLink
                    filename={calendarEventFileName}
                    event={calendarEvent}
                    className={isContrastTheme ? style.icalendar_icon_contrast : style.icalendar_icon}
                  />
                  <button
                    aria-label={t('29033')}
                    onClick={() =>
                      addTripToCalendar(
                        returnStationByCode(startPoint, stationsList),
                        returnStationByCode(endPoint, stationsList),
                        startDate,
                        endDate,
                        `${identifier.biletSeria}${ticketNumber}`,
                      )
                    }
                  >
                    <img src={Google} alt="Google" />
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {tickets && (
        <MyTicketsSingleTicketInnerData
          containerRef={containerRef}
          mainTicketIdentifier={identifier}
          tickets={tickets}
          isSeasonal={isSeasonal}
          isReturned={isReturned}
          propsPrimaryTickets={primaryTickets}
          history={history}
          variant={variant}
          query={`/${isForeign ? '' : 'wyszukiwanie'}${makeQuery({
            ticketInfo: identifier,
            ticketDetail: {bilety: primaryTickets, bledy: []} as IDownloadTicketResponse,
            searchDate: '',
          })}`}
          queryAgain={`/${isForeign ? '' : 'wyszukiwanie'}${makeQuery(
            {
              ticketInfo: identifier,
              ticketDetail: {bilety: primaryTickets, bledy: []} as IDownloadTicketResponse,
              searchDate: '',
            },
            true,
          )}`}
        />
      )}
      {/* International Additional Tickets */}
      {isInternationalEPAWithAdditionalTicket && (
        <div className="">
          <div className={`${style.otherTicketsContainer}  `}>
            <p className={style.otherTicketTitle}>{t('29182').toString()}</p>
            <div className={`${style.otherTicketsWrapper} `}>
              {internationalEPAAdditionalTickets.map(({kind, count}, index) => (
                <Fragment key={index}>
                  <button
                    className={`${style.singleAdditionalTicket} ${
                      active === index ? style.singleAdditionalTicketActive : ''
                    }`}
                    onClick={() => setActive(index)}
                  >
                    <img
                      src={returnAdditionalTypeTicketData(t, kind, isContrastTheme, 'dark')?.image}
                      alt={returnAdditionalTypeTicketData(t, kind, isContrastTheme, 'dark')?.nazwa}
                    />
                    <span>
                      {count}x {returnAdditionalTypeTicketData(t, kind, isContrastTheme, 'dark')?.nazwa}
                    </span>
                  </button>
                </Fragment>
              ))}
            </div>
            <div className={`${style.ticket_head_box} `}>
              <div className={style.ticket_header_temp}>
                <div className={style.ticket_header}>
                  <p className={`${style.blue_text} ${style.blue_textLight}`}>{t('24066').toString()}</p>
                  <p className={`${style.navy_text_big} ${style.navy_text_light}`}>
                    {biletSeria}
                    {biletNr}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid col-sm-12 col-12">
            <div className="grid col-sm-12 col-12">
              <div className="col-sm-2 col-2">
                <div className={style.addTicketWrapper}>
                  <div>
                    <p className={style.blue_text}>{t('11010').toString()}</p>
                    <p className={style.navy_text_big}>
                      {
                        returnAdditionalTypeTicketData(
                          t,
                          internationalEPAAdditionalTickets[active].kind,
                          isContrastTheme,
                          'dark',
                        )?.nazwa
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 col-2">
                <div>
                  <p className={style.blue_text}>{t('22032').toString()}</p>
                  <p className={style.navy_text_big}>
                    {(internationalEPAAdditionalTickets[active].price / 100).toFixed(2).replace('.', ',')} {t('24086')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Domestic Additional Tickets */}
      {!!additionalTickets.length && (
        <div className="">
          <div className={`${style.otherTicketsContainer}  `}>
            <p className={style.otherTicketTitle}>{t('29182').toString()}</p>
            <div className={`${style.otherTicketsWrapper} `}>
              {additionalTickets.map((el, index) => {
                return (
                  <Fragment key={el.biletNr}>
                    <button
                      className={`${style.singleAdditionalTicket} ${
                        active === index ? style.singleAdditionalTicketActive : ''
                      }`}
                      onClick={() => setActive(index)}
                    >
                      <img
                        src={returnAdditionalTypeTicketData(t, el?.biletRodzaj, isContrastTheme, 'dark')?.image}
                        alt={returnAdditionalTypeTicketData(t, el?.biletRodzaj, isContrastTheme, 'dark')?.nazwa}
                      />
                      <span>
                        {el?.podrozni.length}x{' '}
                        {el?.biletRodzaj &&
                          returnAdditionalTypeTicketData(t, el?.biletRodzaj, isContrastTheme, 'dark')?.nazwa}
                      </span>
                    </button>
                  </Fragment>
                );
              })}
            </div>
            <div className={`${style.ticket_head_box} `}>
              <div className={style.ticket_header_temp}>
                <div className={style.ticket_header}>
                  <p className={`${style.blue_text} ${style.blue_textLight}`}>{t('24066').toString()}</p>
                  <p className={`${style.navy_text_big} ${style.navy_text_light}`}>
                    {additionalTickets[active]?.biletSeria?.toString()}
                    {additionalTickets[active]?.biletNr?.toString()}
                  </p>
                </div>
                {additionalTickets[active]?.zwrotOpis && (
                  <div className={style.ticket_header}>
                    <p className={style.red_text}>{t('29153')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid col-sm-12 col-12">
            <div className="grid col-sm-12 col-12">
              <div className="col-sm-2 col-2">
                <div className={style.addTicketWrapper}>
                  <div>
                    <p className={style.blue_text}>{t('11010').toString()}</p>
                    <p className={style.navy_text_big}>
                      {
                        returnAdditionalTypeTicketData(
                          t,
                          additionalTickets[active]?.biletRodzaj,
                          isContrastTheme,
                          'dark',
                        )?.nazwa
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 col-2">
                <div>
                  <p className={style.blue_text}>{t('22032').toString()}</p>
                  <p className={style.navy_text_big}>
                    {(additionalTickets[active].cenaBilet / 100).toFixed(2).replace('.', ',')} {t('24086')}
                  </p>
                </div>
              </div>
              {history === 0 && !additionalTickets[active].zwrotOpis && (
                <div className="col-sm-4 col-4">
                  <button
                    className={` ${style.outline_button}`}
                    onClick={() =>
                      setReturnedTicketData({
                        ticket: {
                          bilety: [additionalTickets[active]],
                          bledy: [],
                        },
                        ticketSr: additionalTickets[active].biletSeria,
                        mainTicketIdentifier: identifier,
                      })
                    }
                    aria-label={t('29183')}
                  >
                    {t('29027')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyTicketsSingleTicket;
