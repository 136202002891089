import {IGetTokenResponse, IGuestTicket} from '../api/auth/interfaces';
import api from 'api/auth/repository';

export const getJwtTokenOrDefault = (key: string): IGetTokenResponse | undefined => {
  try {
    const token = sessionStorage.getItem(key);
    return token && JSON.parse(token);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const isJwtExpDateValid = (token: string) => {
  const jwt = parseJwt(token);
  if (jwt?.exp) {
    const today = new Date();
    today.setSeconds(today.getSeconds() + 30);
    return jwt.exp * 1000 > today.getTime();
  }
  return false;
};

export const refreshToken = async (refreshToken: string) => {
  try {
    await api.refreshJwtToken(refreshToken).then((data) => {
      sessionStorage.setItem('eic2Token', JSON.stringify(data));
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeToken = (key: string) => {
  const token = sessionStorage.getItem(key);
  if (token) {
    sessionStorage.removeItem(key);
  }
};

export const returnNewestJwt = (): string => {
  const user = getJwtTokenOrDefault('eic2Token');
  return user?.access_token ?? '';
};

export const renewToken = async (ticket: IGuestTicket) => {
  const token = getJwtTokenOrDefault('eic2Token');
  try {
    const data = await api.renewJwtToken(token?.refresh_token ? token?.refresh_token : '', ticket);
    if (data?.access_token) {
      sessionStorage.setItem('eic2Token', JSON.stringify(data));
    }
    return returnNewestJwt();
  } catch (e) {
    console.log(e);
  }
};

export const checkRefreshAndReturnJwt = async (): Promise<string> => {
  const savedToken = getJwtTokenOrDefault('eic2Token');
  if (savedToken) {
    if (!isJwtExpDateValid(savedToken.access_token)) {
      await refreshToken(savedToken.refresh_token);
    }
  }
  return returnNewestJwt();
};

export const getToken = async (): Promise<string> => {
  const token = checkRefreshAndReturnJwt();

  if (!token) {
    throw new Error('No token provided');
  } else return token;
};
