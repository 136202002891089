export const STATION_TYPES = {
  STANDARD_STATION: 0,
  PUBLIC_META_STATION: 1,
  NON_PUBLIC_META_STATION: 2,
  PKS_POLONUS: 3,
  POLREGIO: 4,
} as const;

export const PLACE_KIND_CODES = {
  SITTING: 1,
  LYING_PLACE: 2,
  SLEEPING_PLACE: 3,
} as const;

export const PLACE_POSITION_CODES = {
  ANY: 0,
  BOTTOM: 1,
  MIDDLE: 2,
  TOP: 3,
} as const;

export const USER_TYPE_CODES = {
  ANY: 0,
  ADULT: 1,
  CHILD: 2,
} as const;

export const DISCOUNT_OFFER_TYPE_CODES = {
  SINGLE: 1,
  SEASONAL: 2,
} as const;

export const SHOP_DISCOUNT_KIND_CODES = {
  STATUTORY: 1,
  COMMERCIAL: 2,
  RAILWAY: 3,
} as const;

export const COMMERCIAL_ATTRIBUTE_CODES = {
  SLEEPING_CAR_FOR_DISABLED: '93',
  LYING_PLACE_FOR_DISABLED: '94',
  WHEELCHAIR_ACCESSIBLE: '95',
  WHEELCHAIR_WITHOUT_LIFT: '97',
  NON_COMPARTMENT_COACH: 'GA',
  RAIL_REPLACEMENT_BUS_SERVICE: 'AM',
  NO_TICKET_FROM_CONDUCTOR: 'CB',
  BICYCLE_TRANSPORT: 'FB',
  BICYCLE_TRANSPORT_IN_NOT_ADAPTED_WAGON: 'FK',
  NON_COMPARTMENT_CAR: 'GA',
  SILENCE_ZONE: 'HD',
  RESTAURANT_CAR: 'JP',
  COURIER_SHIPMENT_VIA_CONDUCTOR: 'K',
  SECOND_CLASS_ONLY: 'K2',
  AIR_CONDITIONING: 'KL',
  LYING_PLACES: 'LW',
  SNACKS_AND_DRINKS_MINIBAR: 'MB',
  ELECTRICAL_TRAINSETS: 'PV',
  FIRST_CLASS_SEAT_BOOKING: 'R4',
  SEAT_RESERVATION: 'RE',
  MANDATORY_SEAT_RESERVATIONS: 'RP',
  MANDATORY_FIRST_CLASS_SEAT_RESERVATIONS: 'RR',
  SLEEPERETTE_CAR: 'SP',
  SLEEPING_PLACES: 'SW',
  RAIL_REPLACEMENT_BUS_SERVICE_2: 'TW',
  BUSINESS_COMPARTMENT: 'UA',
  BRAILLE_SIGNAGE: 'WE',
} as const;
export const STALE_TIME = 1000 * 60 * 60 * 24; // 24 hours,
