import {SeasonalOffer, SeasonalOfferKind, Station} from 'common/api/localData';
import {TrasaPrzejazduDlaBiletuOkresowegoOdp} from 'api/biletyOkresowe/interfaces';
import {format, addWeeks, addMonths, subDays} from 'date-fns';
import {getStationByCode} from 'common/utils';
import {SEASON_TICKET_PERIODIC_LIST, UUT_IDS} from 'components/pages/SeasonalTicket/common/const';
import {ISeasonTicketPeriodic} from '../type';
import {SeasonalOfferCodeKind, SeasonalTicketGroup} from 'common/types';

export const getSeasonalTicketOffersTypesByCodes = (
  offerTypes: SeasonalOfferKind[],
  codes: Array<SeasonalOfferKind['kod']>,
): SeasonalOfferKind[] => offerTypes.filter(({kod}) => codes.indexOf(kod) > -1);

export const getSeasonalTicketOffersByCodes = (
  offers: SeasonalOffer[],
  codes: Array<SeasonalOffer['kod']>,
): SeasonalOffer[] => offers.filter(({kod}) => codes.indexOf(kod) > -1);

export const getOfferCodeByGroup = (group: SeasonalTicketGroup, isBothWayTrip: boolean): number | undefined => {
  switch (group) {
    case 1:
      return isBothWayTrip ? 59 : 58;
    case 2:
      return isBothWayTrip ? 33 : 32;
    case 3:
      return isBothWayTrip ? 43 : 42;
    default:
      return undefined;
  }
};

export const getPeriodicDateToBySeasonGroup = (
  dateFrom: string,
  group: SeasonalTicketGroup,
  dateFormat: string = 'dd.MM.yyyy',
) => {
  const date = new Date(dateFrom);

  switch (group) {
    case 1:
      return format(subDays(addWeeks(date, 1), 1), dateFormat);
    case 2:
      return format(subDays(addMonths(date, 1), 1), dateFormat);
    case 3:
      return format(subDays(addMonths(date, 3), 1), dateFormat);
    default:
      return undefined;
  }
};

export const getSeasonConnectionsWithStations = (
  connections: TrasaPrzejazduDlaBiletuOkresowegoOdp[],
  stationsList: Station[],
  distanceLimit?: number,
) => {
  const connectionsList = distanceLimit
    ? connections.filter((connection) => connection.kilometry <= distanceLimit)
    : connections;

  return connectionsList.map((item: TrasaPrzejazduDlaBiletuOkresowegoOdp) => {
    const {kilometry, trasaBezposredna, ...stations} = item;

    const viaStations = Object.keys(stations)
      .reduce((acc: number[], key: string) => {
        const codeEVA =
          stations[key as keyof Omit<TrasaPrzejazduDlaBiletuOkresowegoOdp, 'kilometry' | 'trasaBezposredna'>];

        return codeEVA ? acc.concat(Number(codeEVA)) : acc;
      }, [])
      .map((code: number): Station => getStationByCode(code, stationsList)!, []);

    return {distance: kilometry, directConnection: trasaBezposredna, viaStations};
  });
};

export const getSeasonTicketPeriodicByGroup = (group: SeasonalTicketGroup): ISeasonTicketPeriodic | undefined =>
  SEASON_TICKET_PERIODIC_LIST.find((item) => item.value === group);

export const getMultirideTicketName = (isMultirideMax: boolean) => (isMultirideMax ? '13120' : '13119');

export const isActiveUutOffer = ({przedsprzedaz}: SeasonalOffer): boolean => {
  const currentDate = new Date().getTime();
  return przedsprzedaz.some(
    ({przedsprzedazod, przedsprzedazdo}) =>
      new Date(przedsprzedazod).getTime() <= currentDate && new Date(przedsprzedazdo).getTime() >= currentDate,
  );
};

export const getExcludedSeasonalUut = (
  types: SeasonalOfferKind[],
  offers: SeasonalOffer[],
  uutIds: SeasonalOfferCodeKind[] = UUT_IDS,
): SeasonalOffer[] => {
  const uutOfferCodes = getSeasonalTicketOffersTypesByCodes(types, uutIds).map(
    ({kodyOfertOkresowych}) => kodyOfertOkresowych[0],
  );

  return getSeasonalTicketOffersByCodes(offers, uutOfferCodes).filter(
    (offer: SeasonalOffer): boolean => !isActiveUutOffer(offer),
  );
};

export const getCurrentOfferTypes = (
  types: SeasonalOfferKind[],
  offers: SeasonalOffer[],
  uutIds: SeasonalOfferCodeKind[] = UUT_IDS,
) => {
  const excludedUut = getExcludedSeasonalUut(types, offers, uutIds).map(({kod}) => kod);

  return types.filter(({kodyOfertOkresowych}) => kodyOfertOkresowych.some((code) => !excludedUut.includes(code)));
};
