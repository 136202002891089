import {FC} from 'react';

import {CircularProgress, LinearProgress} from '@mui/material';

import S from './Loader.module.scss';

import loader from '../../../assets/loader.gif';
import Icon from 'common/components/base/Icon';

interface Props {
  variant?: 'circle' | 'ellipsis' | 'linear' | 'train' | 'spinner';
  maxWidth?: number | string;
  height?: number | string;
  color?: string;
  label?: string;
}

const Loader: FC<Props> = ({variant = 'train', maxWidth = 250, height = 'auto', color, label}) => (
  <>
    {variant === 'train' && <img src={loader} width={maxWidth} height={height} alt="Loader" />}
    {variant === 'circle' && (
      <div className={S.circle}>
        <CircularProgress color="inherit" />
      </div>
    )}
    {variant === 'linear' && (
      <div className={S.linear}>
        <LinearProgress color="inherit" />
      </div>
    )}
    {variant === 'ellipsis' && (
      <div className={S.ldsEllipsis}>
        <div style={{...(color && {background: color})}} />
        <div style={{...(color && {background: color})}} />
        <div style={{...(color && {background: color})}} />
        <div style={{...(color && {background: color})}} />
      </div>
    )}
    {variant === 'spinner' && (
      <div className={S.loader__spinner}>
        <span className={S.loader__spinnerIcon}>
          <Icon icon="preloader" variant="medium" />
        </span>
        {label && <span>{label}</span>}
      </div>
    )}
  </>
);
export default Loader;
