export const InternationalPersonType = {
  adult: 1,
  child: 2,
} as const;

export const InternationalOfferCode = {
  IRT: 2,
  NRT: 3,
} as const;

export const InternationalTravelWithCareTaker = {
  individual: 0,
  withCareTaker: 1,
} as const;

export const CareTakerType = {
  passenger: 0,
  otherTicket: 1,
} as const;

export const CareTakerTicketType = {
  none: 0,
  normalTariff: 1,
  otherTicket: 2,
} as const;

export const SharedPlace = {
  shared: 1,
  separated: 0,
} as const;
