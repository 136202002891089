import {ChangeEvent, FC, useContext, useEffect, useState} from 'react';

import UniversalHeader from 'components/common/UniversalHeader';
import ConfirmButton from 'components/common/ConfirmButton';
import IconHeader from 'components/common/IconHeader';
import MinorHeader from 'components/common/MinorHeader';
import Ticket from '../Ticket';
import BlueHeader from 'components/common/BlueHeader';
import DiscountSlider from '../DiscountSlider/DiscountSlider';
import DiscountDetailsModal from '../DiscountDetailsModal/DiscountDetailsModal';
import {ActiveTicket, InitialCount} from '../../../../PurchasePathDesktop/SetDiscounts/SetDiscounts';
import {INewTicket, IRailwayEmployee} from 'api/sprzedaz/interfaces';
import {ShopDiscount} from 'common/api/localData';
import {
  getPassengerAge,
  handleCancelDiscounts,
  handleRecommendedAgeDiscounts,
  onSelectedDiscountChange,
  registerEmployeeData,
  sortFromMostPopulars,
  setDaysToDisplay,
  isRailwayEmployeeRelated,
  EmployeeIdStatus,
  formatMonth,
} from '../../../../PurchasePathDesktop/SetDiscounts/SetDiscountsLogic';
import {useData} from 'Utils/DataContex';
import {getShopDiscountFromList, returnOfferNameFromOffersModalError} from 'Utils/commonFunctions';
import DiscountsAgePicker from 'components/pages/PurchasePath/PurchasePathDesktop/SetDiscounts/DiscountsAgePicker/DiscountsAgePicker';
import {useStyledThemeState} from 'common/theme';

import S from './DiscountsModal.module.scss';
import {ReactComponent as Profile} from 'assets/profile.svg';
import {ReactComponent as ProfileIconContrast} from 'assets/profileContrastDesktop.svg';
import ConfirmationModal from 'components/common/ConfirmationModal/ConfirmationModal';
import {TicketContext} from 'Utils/TicketContext';
import {ICheckDocumentRequest} from 'api/dokumenty/interfaces';
import documentsApi from 'api/dokumenty/repository';
import {useTranslation} from 'react-i18next';
import i18n from 'i18n';

interface Props {
  setOpen: (e: boolean) => void;
  adultCount: INewTicket[];
  childrenCount: INewTicket[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeAdult: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeChildren: any;
  activeTicket: ActiveTicket;
}

const DiscountsModal: FC<Props> = ({setOpen, adultCount, childrenCount, changeAdult, changeChildren, activeTicket}) => {
  const {t} = useTranslation();
  const {isContrastTheme} = useStyledThemeState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialCount, setInitialCount] = useState<InitialCount>({adultCount, childrenCount});
  const [openDiscountsDetails, setOpenDiscountsDetails] = useState(false);
  const [inputDay, setInputDay] = useState<string>('');
  const [inputMonth, setInputMonth] = useState<string>('');
  const [inputYear, setInputYear] = useState<string>('');
  const [inputDate, setInputDate] = useState<string>('');
  const [passengerAge, setPassengerAge] = useState<string>('');
  const [mostPopularDiscs, setMostPopularDiscs] = useState<ShopDiscount[]>([]);
  const [filteredDiscs, setFilteredDiscs] = useState<ShopDiscount[]>([]);
  const [recommendedDisc, setRecommendedDisc] = useState<ShopDiscount[]>([]);
  const [selectedDiscount, setSelectedDiscount] = useState<ShopDiscount>();
  const [tempDiscount, setTempDiscount] = useState<ShopDiscount>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  // Set employeeId: "" on init and reset to enable employee id validation
  const [employeeData, setEmployeeData] = useState<IRailwayEmployee>({
    employeeId: '0',
    employerCode: null,
  });

  const isRailwayEmployee = selectedDiscount && isRailwayEmployeeRelated(selectedDiscount);
  const [railwayEmplIdStatus, setRailwayEmplIdStatus] = useState<EmployeeIdStatus>(EmployeeIdStatus.UNCHECKED);

  const {shopDiscounts} = useData();
  const {pkpOffers, setPkpOffers, verifyDiscountCodesWithOffers, offersModalError, resetErrorModal, resetOfferModal} =
    useContext(TicketContext);

  useEffect(() => {
    if (activeTicket.isAdult) {
      const railwayEmployee = adultCount[activeTicket.index].railwayEmployee;
      railwayEmployee && setEmployeeData(railwayEmployee);
    } else {
      const railwayEmployee = childrenCount[activeTicket.index].railwayEmployee;
      railwayEmployee && setEmployeeData(railwayEmployee);
    }
  }, []);

  useEffect(() => {
    tempDiscount && offersModalError?.length === 0 && handleDiscountChange(tempDiscount);
  }, [pkpOffers, tempDiscount, offersModalError]);

  useEffect(() => {
    if (shopDiscounts.length > 0) {
      if (!selectedDiscount) {
        const group = activeTicket.isAdult ? adultCount : childrenCount;
        const find = getShopDiscountFromList(group[activeTicket.index].discountCode, shopDiscounts);
        find && setSelectedDiscount(find);
      }
      mostPopularDiscs.length === 0 && setMostPopularDiscs(sortFromMostPopulars(shopDiscounts, activeTicket.isAdult));
    }
  }, [shopDiscounts]);

  useEffect(() => {
    if (mostPopularDiscs.length > 0 && recommendedDisc.length === 0) {
      if (activeTicket.isAdult) {
        setRecommendedDisc(sortFromMostPopulars(shopDiscounts, true).slice(0, 3));
      } else {
        setRecommendedDisc(mostPopularDiscs.filter((el) => el.opisy[0].nazwa === 'Dzieci do 4 roku życia'));
      }
    }
  }, [mostPopularDiscs]);

  useEffect(() => {
    setPassengerAge(getPassengerAge(inputDate));
    !inputDate && setRecommendedDisc([]);
  }, [inputDate]);

  useEffect(() => {
    if (!!inputDate?.length && inputDate.length === 10) {
      handleRecommendedAgeDiscounts(passengerAge, mostPopularDiscs, setRecommendedDisc, setFilteredDiscs);
    }
  }, [passengerAge, inputDate]);

  useEffect(() => {
    if (selectedDiscount && (employeeData?.employeeId.length === 13 || employeeData?.employerCode)) {
      if (employeeData?.employeeId.length === 13) {
        validateAndRegisterEmployeeId();
      } else {
        railwayEmplIdStatus === EmployeeIdStatus.VALID && setRailwayEmplIdStatus(EmployeeIdStatus.INVALID);
        registerEmployeeData(
          activeTicket,
          activeTicket.isAdult ? adultCount : childrenCount,
          activeTicket.isAdult ? changeAdult : changeChildren,
          selectedDiscount,
          employeeData,
        );
      }
    }
  }, [employeeData]);

  const validateAndRegisterEmployeeId = async () => {
    if (selectedDiscount) {
      const employeeId = employeeData.employeeId.trim().replace(/\s/g, '').replace('/', '');

      const request: ICheckDocumentRequest = {
        urzadzenieNr: 0,
        metoda: '',
        typDokumentu: 0,
        seriaINumerDokumentu: employeeId,
      };

      await documentsApi
        .checkDocument(request)
        .then((data) => {
          if (data && data?.statusDokumentu === 0 && !data?.bledy?.length) {
            setRailwayEmplIdStatus(EmployeeIdStatus.VALID);
            registerEmployeeData(
              activeTicket,
              activeTicket.isAdult ? adultCount : childrenCount,
              activeTicket.isAdult ? changeAdult : changeChildren,
              selectedDiscount,
              employeeData,
            );
          } else {
            setRailwayEmplIdStatus(EmployeeIdStatus.INVALID);
          }
        })
        .catch(() => {
          setRailwayEmplIdStatus(EmployeeIdStatus.INVALID);
        });
    }
  };

  const getOffersNamesFromModalError = (offerErrors: string[]) => {
    return offerErrors.map((x) => returnOfferNameFromOffersModalError(t, x));
  };

  const getConfirmationMsg = () => {
    if (offersModalError && offersModalError.length > 0) {
      return `Wybrano ulgę ${tempDiscount?.opisy.find((x) => x.jezyk === i18n.language.toUpperCase())?.nazwa}, która nie obowiązuje w ${offersModalError.length > 1 ? 'ofertach' : 'ofercie'} ${getOffersNamesFromModalError(offersModalError)}.`;
    }

    return t('13008');
  };

  const handleConfirmModal = () => {
    if (pkpOffers.bigFamily) setPkpOffers('autoOffersDisabled:bigFamily');
    if (pkpOffers.familyTicket) setPkpOffers('autoOffersDisabled:familyTicket');
    if (pkpOffers.cheaperWithRelatives) setPkpOffers('autoOffersDisabled:cheaperWithRelatives');

    setPkpOffers('');
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setTempDiscount(undefined);
    resetErrorModal();
    resetOfferModal();
    setOpenModal(false);
  };

  const handleDiscountChange = (discount: ShopDiscount) => {
    const discountToTicket = {discountCode: discount.kodZakupowy} as INewTicket;
    const canSetDiscount = verifyDiscountCodesWithOffers([discountToTicket]);

    if (!canSetDiscount && (pkpOffers.bigFamily || pkpOffers.cheaperWithRelatives || pkpOffers.familyTicket)) {
      setOpenDiscountsDetails(false);
      setTempDiscount(discount);
      setOpenModal(true);
      return;
    }

    onSelectedDiscountChange(
      activeTicket,
      adultCount,
      changeAdult,
      childrenCount,
      changeChildren,
      discount,
      isRailwayEmployee,
      setEmployeeData,
      setSelectedDiscount,
      setRailwayEmplIdStatus,
    );
  };

  const onEmployeeIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    const newValue = name === 'employeeId' ? value.toUpperCase() : value;
    setEmployeeData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const onEmployerCodeChange = (code: number | null) => {
    setEmployeeData((prev) => ({
      ...prev,
      employerCode: code,
    }));
  };

  useEffect(() => {
    setInputDate(`${inputYear}-${formatMonth(inputMonth)}-${inputDay}`);
  }, [inputDay, inputMonth, inputYear]);

  const checkOfferNumber = offersModalError && offersModalError.length > 1 ? 'ofert' : 'oferty';

  return (
    <div className={S.curtain}>
      <div style={{width: '100%'}}>
        <UniversalHeader
          title={t('13031')}
          goBack={() => handleCancelDiscounts(initialCount, changeAdult, changeChildren, () => setOpen(false))}
        />
        <IconHeader
          title={activeTicket.isAdult ? t('13015') : t('13016')}
          description=""
          icon={isContrastTheme ? <ProfileIconContrast /> : <Profile />}
        />
        <div>
          <MinorHeader title={t('13033')} />
          <p className={S.dateInstruction}>{t('13145')}</p>
        </div>
        <div className={S.agePickerBox}>
          <DiscountsAgePicker
            min={1}
            max={setDaysToDisplay(inputMonth, inputYear)}
            placeholder={t('13051')}
            value={inputDay}
            callback={(e) => setInputDay(e.target.value)}
            callback2={setInputDay}
            type="number"
            autoComplete="birthdayDay"
            id="birthdayDay"
            label={t('13052')}
            pattern="[0-9]*"
          />
          <DiscountsAgePicker
            min={1}
            max={12}
            placeholder={t('13050')}
            value={inputMonth}
            callback={(e) => setInputMonth(e.target.value)}
            callback2={setInputMonth}
            type="text"
            autoComplete="birthdayMonth"
            id="birthdayMonth"
            label={t('13054')}
          />
          <DiscountsAgePicker
            min={1900}
            max={new Date().getFullYear()}
            placeholder={t('13047')}
            value={inputYear}
            callback={(e) => setInputYear(e.target.value)}
            callback2={setInputYear}
            type="number"
            autoComplete="birthdayYear"
            id="birthdayYear"
            label={t('13056')}
          />
        </div>
        <div>
          <MinorHeader title={t('13058')} />
          <p className={S.dateInstruction}>{t('13062')}</p>
        </div>
        <Ticket
          isAdult={activeTicket.isAdult}
          isActive={true}
          discountCode={
            activeTicket.isAdult
              ? adultCount[activeTicket.index].discountCode
              : childrenCount[activeTicket.index].discountCode
          }
          employeeData={employeeData}
          onEmployeeIdChange={onEmployeeIdChange}
          onEmployerCodeChange={onEmployerCodeChange}
          onChangeClick={() => setOpenDiscountsDetails(true)}
          railwayEmplIdStatus={railwayEmplIdStatus}
          index={activeTicket.index + 1}
        />
        <div style={{margin: '24px 0 8px 0'}}>
          <BlueHeader title={t('13146')} />
        </div>
        <div className={S.recommendedList}>
          <DiscountSlider
            discounts={recommendedDisc}
            selectedDiscount={selectedDiscount}
            onDiscountChange={handleDiscountChange}
          />
        </div>
      </div>
      <div className={S.confirmBtnWrapper}>
        <ConfirmButton
          text={t('29177')}
          disabled={
            isRailwayEmployee && !employeeData.employerCode
            // Uncomment to enable employee id validation
            // || employeeData.employeeId.length < 13 ||
            // railwayEmplIdStatus !== EmployeeIdStatus.VALID
          }
          execute={() => setOpen(false)}
        />
      </div>
      {openDiscountsDetails && (
        <DiscountDetailsModal
          setOpen={setOpenDiscountsDetails}
          adultCount={adultCount}
          childrenCount={childrenCount}
          changeAdult={changeAdult}
          changeChildren={changeChildren}
          activeTicket={activeTicket}
          filteredDiscs={filteredDiscs}
          mostPopularDiscs={mostPopularDiscs}
          selectedDiscount={selectedDiscount}
          onSelectedDiscountChange={handleDiscountChange}
        />
      )}
      {openModal && (
        <div className={S.confirmModal}>
          <ConfirmationModal closeFn={handleCloseModal} confirmFn={handleConfirmModal} customCancelBtnTxt={t('29404')}>
            <p>{getConfirmationMsg()}</p>
            <p>
              {offersModalError && offersModalError.length > 0
                ? `${t('29403')} ${checkOfferNumber} ${getOffersNamesFromModalError(offersModalError)}?`
                : t('29410')}
            </p>
          </ConfirmationModal>
        </div>
      )}
    </div>
  );
};
export default DiscountsModal;
