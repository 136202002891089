import {CountryConfiguration, Station} from 'common/api/localData';

export const getPreSaleConnectionConfiguration = (
  departure: Station,
  destination: Station,
  countriesConfiguration: CountryConfiguration[],
) => {
  const departureCountryCode = parseInt(departure.kodEVA.toString().slice(0, 2));
  const destinationCountryCode = parseInt(destination.kodEVA.toString().slice(0, 2));
  const configuration = countriesConfiguration.find(({krajOd}) => krajOd === departureCountryCode);
  return configuration?.krajDo.find(({krajDo}) => krajDo === destinationCountryCode);
};
