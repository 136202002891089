/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {FC} from 'react';
import {useStyledThemeState} from 'common/theme';
import {useTranslation} from 'react-i18next';

import style from './MyTicketsSingleTicketOpenMobile.module.scss';

import {Text} from 'common/components/base/Text';
import {returnAdditionalTypeTicketData} from 'Utils/commonFunctions';
import {TicketKind} from 'common/api/myTickets';

interface Props {
  quantity: number;
  cost: number;
  ticketKind: TicketKind;
  isReturned: boolean;
  execute: () => void;
}

const MyTicketSingleTicketOpenAddTicket: FC<Props> = ({quantity, cost, ticketKind, isReturned, execute}) => {
  const {isContrastTheme} = useStyledThemeState();
  const {t} = useTranslation();

  return (
    <div
      className={`${style.addTicketContainer} ${isReturned && style.addTicketContainer__returned}`}
      onClick={execute}
    >
      <img
        src={returnAdditionalTypeTicketData(t, ticketKind, isContrastTheme, 'light')?.image}
        style={{height: '30px'}}
        alt=""
      />
      <div className={style.addticketTextContainer}>
        <p className={style.addticketText}>
          {quantity} x {returnAdditionalTypeTicketData(t, ticketKind, isContrastTheme, 'light')?.nazwa}
        </p>
        <p className={style.addticketText}>
          {(cost / 100).toFixed(2).replace('.', ',')}
          {t('24086').toString()}
        </p>
        {isReturned && (
          <div className={style.label}>
            <Text variant="P" palette="error.default" as="p">
              {t('29153')}
            </Text>
          </div>
        )}
      </div>
      <div className={style.dotsContainer}>
        <div className={style.whiteDot}></div>
        <div className={style.whiteDot}></div>
        <div className={style.whiteDot}></div>
      </div>
    </div>
  );
};

export default MyTicketSingleTicketOpenAddTicket;
