import {FC} from 'react';
import S from './BlueHeader.module.scss';
import {useWindowResize} from 'common/hooks/screen';

interface BlueHeaderDataProps {
  title: string;
}
const BlueHeader: FC<BlueHeaderDataProps> = ({title}) => {
  const {isMobile} = useWindowResize();

  return <h2 className={`${isMobile ? S.blueHeaderMobile : S.blueHeader}`}>{title}</h2>;
};

export default BlueHeader;
