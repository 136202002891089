/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {FC, useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import * as DOMPurify from 'dompurify';

import S from './PaymentErrorModal.module.scss';
import OrangeX from 'assets/orangeX.svg';
import {useTranslation} from 'react-i18next';
import {checkIfGuest} from 'Utils/commonFunctions';
import {myTicketsLocationStates} from 'common/pages/UserProfile/MyTickets';

interface Props {
  htmlMessage: string;
  isPayLaterTicket?: boolean;
  isMobile?: boolean;
  closeFn?: (e: boolean) => void;
  onMyTicketsClick?: () => void;
}

const PaymentErrorModal: FC<Props> = ({htmlMessage, isPayLaterTicket, closeFn, onMyTicketsClick}) => {
  const {t} = useTranslation();
  const isGuest = useMemo(() => checkIfGuest(), []);

  const navigate = useNavigate();

  const hadPayLaterSucceed = (): boolean => {
    return (
      htmlMessage !== `<b>${t('25014')}.</b> ${t('24016')}.` && htmlMessage !== `<b>${t('24015')}.</b> ${t('24016')}.`
    );
  };

  const handlePayLaterSuccess = (reload?: boolean) => {
    if (hadPayLaterSucceed()) {
      reload && window.location.reload();
    } else {
      closeFn && closeFn(false);
    }
  };

  const handlePaymentFailed = () => {
    if (isPayLaterTicket) handlePayLaterSuccess(true);
    else {
      const seasonalTicket = sessionStorage.getItem('seasonalTicketData');
      navigate(
        isGuest ? '/' : '/profil/mojebilety',
        seasonalTicket ? myTicketsLocationStates.SEASONAL : myTicketsLocationStates.SINGLE,
      );
      onMyTicketsClick && onMyTicketsClick();
    }
  };
  useEffect(() => {
    const handlePopstate = () => {
      navigate('/profil/mojebilety');
      window.removeEventListener('popstate', handlePopstate);
    };

    window.addEventListener('popstate', handlePopstate);
  }, []);
  return (
    <div className={S.modalBackground}>
      <div className={S.modalContainer}>
        <div className={S.topRow}>
          <p>{t('21045')}</p>
          <img
            className={S.closeButton}
            tabIndex={0}
            src={OrangeX}
            alt={t('11035')}
            onClick={(e) => {
              e.preventDefault();
              handlePaymentFailed();
            }}
            onKeyDown={() => {
              handlePaymentFailed();
            }}
          />
        </div>
        <div className={S.message} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(htmlMessage)}} />
        <div className={S.bottomRow}>
          {!isGuest && (
            <button
              className={S.actionButton}
              tabIndex={0}
              onClick={(e) => {
                e.preventDefault();
                handlePaymentFailed();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handlePaymentFailed();
                }
              }}
            >
              {t('10016')}
            </button>
          )}
          <button
            className={S.actionButton}
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              if (isPayLaterTicket) {
                handlePayLaterSuccess();
                navigate('/');
                window.scrollTo(0, 0);
                onMyTicketsClick && onMyTicketsClick();
              } else {
                navigate('/');
                window.scrollTo(0, 0);
                onMyTicketsClick && onMyTicketsClick();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (isPayLaterTicket) {
                  handlePayLaterSuccess();
                  navigate('/');
                  window.scrollTo(0, 0);
                  onMyTicketsClick && onMyTicketsClick();
                } else {
                  navigate('/');
                  window.scrollTo(0, 0);
                  onMyTicketsClick && onMyTicketsClick();
                }
              }
            }}
          >
            {t('10027')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentErrorModal;
