import {ReactNode} from 'react';
import S from './Card.module.scss';

export type CardVariant = 'narrow' | 'small' | 'input';

interface ICard {
  children: ReactNode;
  title?: string;
  variantClass?: CardVariant;
  backdrop?: boolean;
}

const Card = ({children, title, backdrop = false, variantClass}: ICard) => {
  const backDropClass = backdrop ? S['card--backdrop'] : '';

  return (
    <div
      className={`${S.card} ${title ? S.cardWithTitle : ''} ${variantClass ? S[variantClass] : ''} ${backDropClass}`}
    >
      {title && <span className={S.cardWithTitle__title}>{title}</span>}
      {children}
    </div>
  );
};

export default Card;
