import {SeasonalOffer} from 'common/api/localData';
import {SEASONAL_OFFER_CODES} from 'common/consts';
import {ReactComponent as EIC} from 'assets/trainEIC.svg';
import {ReactComponent as IC} from 'assets/trainIC.svg';
import {ReactComponent as EIP} from 'assets/trainEIP.svg';
import {ReactComponent as TLK} from 'assets/trainTLK.svg';
import {ReactComponent as EC} from 'assets/trainEC.svg';
import {ReactComponent as EICContrast} from 'assets/trainEICContrast.svg';
import {ReactComponent as ICContrast} from 'assets/trainICContrast.svg';
import {ReactComponent as EIPContrast} from 'assets/trainEIPContrast.svg';
import {ReactComponent as TLKContrast} from 'assets/trainTLKContrast.svg';
import {ReactComponent as ECContrast} from 'assets/trainECContrast.svg';

const getCarrierLogo = (carrierName: string, isContrast: boolean) => {
  switch (carrierName) {
    case 'EIC':
      return isContrast ? (
        <EICContrast style={{marginBottom: '4px'}} role="img" aria-label="Express InterCity" />
      ) : (
        <EIC style={{marginBottom: '4px'}} role="img" aria-label="Express InterCity" />
      );
    case 'IC':
      return isContrast ? <ICContrast role="img" aria-label="InterCity" /> : <IC role="img" aria-label="InterCity" />;
    case 'EIP':
      return isContrast ? (
        <EIPContrast role="img" aria-label="Express InterCity Premium" />
      ) : (
        <EIP role="img" aria-label="Express InterCity Premium" />
      );
    case 'TLK':
      return isContrast ? (
        <TLKContrast role="img" aria-label="Twoje Linie Kolejowe" />
      ) : (
        <TLK role="img" aria-label="Twoje Linie Kolejowe" />
      );
    case 'EC':
      return isContrast ? <ECContrast role="img" aria-label="EC" /> : <EC role="img" aria-label="EC" />;
    default:
      return;
  }
};
const availableCarriersIntercityCard = ['EIC', 'EIP', 'IC', 'TLK']; // Due to the fact that seasonalOffers in the library do not return information about IC card, we need to manually add this array

const getAvailableCarriers = (offerCode: number | undefined, offers: SeasonalOffer[] | []) =>
  offerCode === SEASONAL_OFFER_CODES.INTERCITY_CARD
    ? availableCarriersIntercityCard
    : offers?.find((offer) => offer.kod === offerCode)?.kategoriePociagow;

const checkPrefix = (name: string | undefined) => {
  if (!name) return '';
  return name.startsWith('Bilet') ? name : `bilet ${name}`;
};

const urlMap: {[key: number]: string} = {
  [SEASONAL_OFFER_CODES.MULTIRIDE]: 'https://www.intercity.pl/pl/multiprzejazd/',
  [SEASONAL_OFFER_CODES.MULTIRIDE_MAX]: 'https://www.intercity.pl/pl/multiprzejazd/',
  [SEASONAL_OFFER_CODES.MONTHLY_THERE]: 'https://www.intercity.pl/pl/bilet-okresowy.html',
  [SEASONAL_OFFER_CODES.MONTHLY_THERE_AND_BACK]: 'https://www.intercity.pl/pl/bilet-okresowy.html',
  [SEASONAL_OFFER_CODES.QUARTERLY_THERE]: 'https://www.intercity.pl/pl/bilet-okresowy.html',
  [SEASONAL_OFFER_CODES.QUARTERLY_THERE_AND_BACK]: 'https://www.intercity.pl/pl/bilet-okresowy.html',
  [SEASONAL_OFFER_CODES.WEAKLY_THERE]: 'https://www.intercity.pl/pl/bilet-okresowy.html',
  [SEASONAL_OFFER_CODES.WEAKLY_THERE_AND_BACK]: 'https://www.intercity.pl/pl/bilet-okresowy.html',
};

const generateUrlForOffer = (offerCode?: number) => {
  if (offerCode) return urlMap[offerCode] || '';
};
export {getCarrierLogo, getAvailableCarriers, checkPrefix, generateUrlForOffer};
