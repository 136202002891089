import {useContext, useEffect} from 'react';

import {SearchContext} from 'Utils/SearchContext';
import {getDiscountDataByCode, isDiscountActive} from './SetDiscountsLogic';
import {ShopDiscount} from 'common/api/localData';
import {useData} from 'Utils/DataContex';
import {TicketContext} from 'Utils/TicketContext';
import {INewTicket} from 'api/sprzedaz/interfaces';

export const useValidateInactiveDiscounts = (
  adultList: INewTicket[],
  setAdultList: (list: INewTicket[]) => void,
  childrenList: INewTicket[],
  setChildrenList: (list: INewTicket[]) => void,
) => {
  const {searchFormFrom} = useContext(SearchContext);
  const {shopDiscounts} = useData();

  useEffect(() => {
    const validateDiscounts = (passengerList: INewTicket[], setPassengerList: (list: INewTicket[]) => void) => {
      const updatedPassengerList = passengerList.map((passenger) =>
        !isDiscountActive(
          getDiscountDataByCode(passenger.discountCode, shopDiscounts),
          searchFormFrom?.date,
          searchFormFrom?.time,
        )
          ? {...passenger, discountCode: null}
          : passenger,
      );

      setPassengerList(updatedPassengerList);
    };
    adultList.length && validateDiscounts(adultList, setAdultList);
    childrenList.length && validateDiscounts(childrenList, setChildrenList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFormFrom?.date]);
};

export const useValidateInactiveContextDiscounts = () => {
  const {adultTicketsList, setAdultTicketsList, setChildrenTicketsList, childrenTicketsList} =
    useContext(TicketContext);

  const passengerList: INewTicket[] = [...adultTicketsList, ...childrenTicketsList];
  const isAnyDiscountNotSelected = passengerList.some(
    (passenger) => passenger.discountCode === 0 || passenger.discountCode === null,
  );

  useValidateInactiveDiscounts(adultTicketsList, setAdultTicketsList, childrenTicketsList, setChildrenTicketsList);

  return {isAnyDiscountNotSelected} as const;
};

export const useValidateInactiveDiscount = (
  discount: ShopDiscount | undefined | null,
  changeDiscount: (discount: ShopDiscount | null) => void,
) => {
  const {searchFormFrom} = useContext(SearchContext);

  useEffect(() => {
    if (!isDiscountActive(discount, searchFormFrom?.date, searchFormFrom?.time)) {
      changeDiscount(null);
    }
  }, [changeDiscount, discount, searchFormFrom?.date, searchFormFrom?.time]);
};
