import {z} from 'zod';

// ERRORS
const ErrorDescriptions = z.array(
  z.object({
    jezyk: z.string(),
    komunikat: z.string(),
  }),
);

const errorSchema = z.object({
  kod: z.number(),
  typ: z.number(),
  opisy: ErrorDescriptions,
});

export const ApiErrorSchema = z.array(errorSchema);
