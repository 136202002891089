import api from '../axios-middleware';
import {getToken} from 'Utils/auth';

import {IChangeUserAddressRequest, IChangeUserAddressResponse, IUserAddressResponse, IUserData} from './interfaces';
import {
  BuyerProfile,
  IProfileDeleteResponse,
} from 'components/pages/UserProfile/components/BuyerProfile/BuyerProfiles.interface';

const methods = {
  getUserData: async () => {
    const token = await getToken();
    return await api.getExt<IUserData>('/private/sso/user_resource/rpc/account', {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
  getUserAddress: async () => {
    const token = await getToken();
    return await api.getExt<IUserAddressResponse>('/private/sso/user_resource/detail/address', {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
  addUserAddress: async (obj: IChangeUserAddressRequest) => {
    const token = await getToken();
    return await api.postExt<IChangeUserAddressResponse>(
      '/private/sso/user_resource/detail/address',
      {
        ...obj,
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  editUserAddress: async (obj: IChangeUserAddressRequest) => {
    const token = await getToken();
    return await api.postExt<IChangeUserAddressResponse>(
      '/private/sso/user_resource/detail/edit_address',
      {
        ...obj,
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  postUserProfile: async (obj: object) => {
    const token = await getToken();
    return await api.postExt<IChangeUserAddressResponse>(
      '/private/sso/user_resource/detail/edit_address',
      {
        ...obj,
      },
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    );
  },
  getBuyerProfiles: async () => {
    const token = await getToken();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await api.getExt<any>('/private/sso/user_resource/travel/profiles', {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
  postBuyerProfile: async (profile: BuyerProfile) => {
    const token = await getToken();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await api.postExt<any>('/private/sso/user_resource/travel/profile', profile, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
  editBuyerProfile: async (profile: BuyerProfile) => {
    const token = await getToken();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await api.postExt<any>('/private/sso/user_resource/travel/profile_edit', profile, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
  deleteBuyerProfile: async (profileId: number) => {
    const token = await getToken();
    return await api.delExt<IProfileDeleteResponse>(`/private/sso/user_resource/travel/profile?id=${profileId}`, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    });
  },
};

export default methods;
