import {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {
  MyTicketIdentifier,
  MyTicketsReservationListParams,
  useMyTicketsPageData,
  useMyTicketsReservationListData,
} from 'common/api/myTickets';
import {isSeasonalOfferCode} from 'common/utils';
import {useMyTicketState} from '../../hooks/useTicket';
import {isReturnedVariant} from '../../MyTicketsList.utils';
import {useMyTicketsState} from '../../hooks/useTickets';
import {isReservationFromSeasonalTicket} from 'components/pages/UserProfile/common/utils';

export const useTicketModalTabs = (identifier: MyTicketIdentifier | undefined, initialTab: number | undefined) => {
  const [selectedTab, setSelectedTab] = useState(initialTab ?? 0);

  useEffect(() => {
    initialTab ? setSelectedTab(initialTab) : setSelectedTab(0);
  }, [identifier, initialTab]);

  const handleChangeTab = (event: SyntheticEvent, newTab: number) => {
    setSelectedTab(newTab);
  };

  return [selectedTab, handleChangeTab] as const;
};

export const useTicketDataHandler = () => {
  const {ticketVariant, isHistoryVariant, isMobileVersion} = useMyTicketsState();
  const {identifier, tickets} = useMyTicketState();
  const {biletSeria, biletNr, ofertaKod} = tickets?.[0] ?? {};
  const TICKETS_IN_REQUEST = 1000; // imposed by BE

  const isSeasonal = useMemo(() => isSeasonalOfferCode(ofertaKod), [ofertaKod]);
  const isReturned = useMemo(
    () => isReturnedVariant(ticketVariant) || !!identifier?.zwrocony, // Additional condition resulting from tickets returned incorrectly by the API.
    [ticketVariant, identifier],
  );
  const showSeasonalTicketTab = useMemo(
    () => (isReservationFromSeasonalTicket(identifier?.typBiletu) || isSeasonal) && !isHistoryVariant && !isReturned,
    [identifier?.typBiletu, isHistoryVariant, isReturned, isSeasonal],
  );

  const fetchReservationListIdentifiers =
    showSeasonalTicketTab && isSeasonalOfferCode(ofertaKod) && biletSeria && biletNr;
  const fetchReservationList = isSeasonalOfferCode(ofertaKod);
  const fetchSeasonalTicket = identifier && showSeasonalTicketTab && !isSeasonalOfferCode(ofertaKod);

  /* Reservation list identifiers fetcher */
  const {data: dataReservations, isFetching: isFetchingReservationListIdentifiers} = useMyTicketsReservationListData(
    fetchReservationListIdentifiers
      ? new MyTicketsReservationListParams(3, 5, 0, 0, TICKETS_IN_REQUEST, 1, biletSeria, biletNr)
      : undefined,
  );
  const {identyfikatory: reservationListIdentifier} = dataReservations ?? {};

  /* Reservation list single tickets fetcher */
  const {data: dataSingleTickets, isFetching: isFetchingSingleTicket} = useMyTicketsPageData(
    fetchReservationList ? reservationListIdentifier : undefined,
  );

  /* Seasonal Ticket in single ticket tab fetcher */
  const {data: dataSeasonalTicket} = useMyTicketsPageData(
    fetchSeasonalTicket
      ? [
          {
            biletSeria: identifier.biletPodstawowySeria,
            biletNr: identifier.biletPodstawowyNr,
            transakcjaNr: null,
          },
        ]
      : undefined,
  );

  return {
    identifier,
    tickets,
    reservationListIdentifier,
    dataSeasonalTicket,
    dataSingleTickets,
    isFetchingSingleTicket,
    isFetchingReservationListIdentifiers,
    isSeasonal,
    isReturned,
    isHistoryVariant,
    showSeasonalTicketTab,
    isMobileVersion,
  };
};
