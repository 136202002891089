import {umlautMap} from 'common/consts';
import {DiacriticsOptions} from './types';
import {removeDiacritics as removeDiacriticsChars} from './plugin';

export const replaceUmlauts = (string: string) =>
  string
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'), (a) => umlautMap[a]);

export const removeDiacritics = (
  string: string,
  options: DiacriticsOptions = {
    replaceUmlauts: false,
  },
): string => {
  let value = string;
  if (options.replaceUmlauts) value = replaceUmlauts(value);
  return removeDiacriticsChars(value);
};
