import {FC} from 'react';
import {useWindowResize} from 'common/hooks/screen';

import S from './OutlineButton.module.scss';

interface OutlineButtonProps {
  text: string;
  helperText?: string;
  disabled?: boolean;
  path?: () => void;
  testId?: string;
  variant?: 'smaller';
}

/**
 * Used for E2E tests.
 */
const TAG = 'OutlineButton';
const OutlineButton: FC<OutlineButtonProps> = ({text, helperText, disabled, path, testId, variant}) => {
  const {isMobile} = useWindowResize();
  return (
    <button
      data-testid={testId ?? TAG}
      disabled={disabled}
      className={`${S.outlineButton} ${isMobile ? S.outlineButton__mobile : ''} ${variant ? S[variant] : ''}`}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (path && !disabled) path();
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && path && !disabled) {
          event.preventDefault();
          event.stopPropagation();

          path();
        }
      }}
      aria-label={helperText}
    >
      <p className={`${S.outlineButton__text} ${isMobile ? S.outlineButton__textMobile : ''}`}>{text}</p>
      <span className={S.visuallyHidden}>{helperText}</span>
    </button>
  );
};

export default OutlineButton;
