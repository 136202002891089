import {FC} from 'react';
import {useWindowResize} from 'common/hooks/screen';

import S from './ConfirmButton.module.scss';

interface ConfirmButtonDataProps {
  text: string;
  helperText?: string;
  disabled?: boolean;
  execute?: () => void;
  testId?: string;
}

/**
 * Used for E2E tests.
 */
const TAG = 'ConfirmButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConfirmButton: FC<ConfirmButtonDataProps> = ({text, helperText, disabled, execute, testId, ...props}) => {
  const {isMobile} = useWindowResize();
  return (
    <button
      data-testid={testId ?? TAG}
      aria-disabled={disabled}
      disabled={disabled}
      type="submit"
      className={`${S.confirmButton} ${isMobile ? S.confirmButton__mobile : ''}`}
      tabIndex={0}
      {...(execute &&
        !disabled && {
          onClick: (event) => {
            event?.stopPropagation();
            event?.preventDefault();
            execute();
          },
          onKeyDown: (event) => {
            if (event.key === 'Enter') {
              event?.stopPropagation();
              event.preventDefault();
              execute();
            }
          },
        })}
      aria-label={text}
    >
      <span className={`${S.confirmButton__text} ${isMobile ? S.confirmButton__textMobile : ''}`}>{text}</span>
      {helperText && <span className={S.confirmButton__visuallyHidden}>{helperText}</span>}
    </button>
  );
};

export default ConfirmButton;
